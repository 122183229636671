import { reduxForm } from "redux-form";
import { registerLicense } from "@syncfusion/ej2-base";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import "./index.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
var navigate;
export var msalInstance;

// Registering Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NAaF1cVWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjWn9acHVWQ2RaU0x0Vw=="
);

let LoginForm = (props) => {
  navigate = useNavigate();
  const { instance } = useMsal();
  const { handleSubmit } = props;

  async function LoginAAD() {
    sessionStorage.setItem("redirect", "false");
    localStorage.setItem("authType", "MFiles");
    // sessionStorage.setItem("isMFilesConnect", "false");
    // sessionStorage.setItem("isConnectVault", "false");
    //const scope = "api://" + sessionStorage.getItem('apiClientId')! as string  + "/access_as_user";
    //loginRequest.scopes.push(scope! as never);
    console.log(loginRequest);
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  }

  const LoginMFiles = () => {
    localStorage.setItem("isError", "false");
    sessionStorage.setItem("isMFilesLogin", "true");
    window.location.reload();
  };

  //const data = sessionStorage.getItem('providers');
  //if (data != null) {
  //    providers = data!.split(',');
  //    console.log(providers);
  //}
  //const Onchange = () => {
  //    var e = (document.getElementById("providers")) as HTMLSelectElement;
  //    console.log(e.value);
  //    if (e.value == "none") {
  //        alert("Please Select an Identity Provider");
  //        return;
  //    }
  //    var provider = e.value;
  //    console.log(provider);
  //   // var provider = e.value;
  //    sessionStorage.setItem('provider', provider);
  //    sessionStorage.setItem('providerIndex', e.selectedIndex.toString());
  //    window.location.reload();
  //}
  var isAuthorized = sessionStorage.getItem("isAuthorized");
  var authType = localStorage.getItem("authType");
  return (
    <>
      <div className="login-container">
        <div id="loginForm">
          <div id="loginLogo"></div>

          <div id="basicForm" className="login">
            <div id="login" style={{ cursor: "auto", paddingTop: "30px" }}>
              <input
                id="btnlogin"
                type="button"
                value="Login to View Maven"
                onClick={LoginAAD}
              />
              <br />
              <br />
              {/* <input id="btnlogin" type="button" value="Login to M-Files" onClick={LoginMFiles} style={{ cursor: 'auto' }} /> */}
            </div>
          </div>

          <div id="loginFooter1">
            <div id="info">
              {authType === "AAD" && isAuthorized === "false" ? (
                <div
                  id="msg"
                  style={{ textAlign: "center", cursor: "auto", color: "red" }}
                >
                  Un-Authorized User
                </div>
              ) : (
                <div
                  id="msg"
                  style={{ textAlign: "center", cursor: "auto" }}
                ></div>
              )}
            </div>
          </div>

          {/*<div id="loginFooter" style={{ textAlign: 'center', cursor: 'auto' }}>*/}
          {/*    <div id="info" style={{ textAlign: 'center', cursor: 'auto' }}>*/}
          {/*        <div id="loginOptions" style={{ textAlign: 'center', cursor: 'auto' }}>*/}
          {/*            <div style={{ textAlign: 'center', cursor: 'auto' }}>*/}
          {/*                <input id="btnlogin1" type="submit" value="Login with Corporate Account" onClick={LoginB2C} style={{ cursor: 'auto' }} />*/}
          {/*                */}
          {/*<a href="" onClick={LoginB2C} > Log in with Corporate Account</a>*/}
          {/*            </div>*/}
          {/*        </div>*/}

          {/*        {isAuthenticated == 'false' ? (*/}
          {/*            <div id="msg" style={{ textAlign: 'center', cursor: 'auto', color: 'red', marginTop: '20px' }}>*/}
          {/*               Un-Authenticated User*/}
          {/*            </div>*/}
          {/*            ) : isAuthorized == 'false' ? (*/}
          {/*                <div id="msg" style={{ textAlign: 'center', cursor: 'auto', color: 'red', marginTop: '20px' }}>*/}
          {/*                    Un-Authorized User*/}
          {/*                </div>*/}
          {/*                ):(*/}
          {/*            <div id="msg" style={{ textAlign: 'center', cursor: 'auto' }}>*/}
          {/*            </div>)}*/}
          {/*    </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

// Add the required form input validation
const validate = (values) => {
  // Add the error message while validation fails
  const errors = {};
  if (!values.username) {
    //errors.username = 'Required';
  }
  if (!values.password) {
    //errors.password = 'Required';
  }

  return errors;
};
// Connect the form with redux-form to communicate with store
export default LoginForm = reduxForm({
  form: "login",
  validate,
})(LoginForm);
