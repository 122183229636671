import { MsalProvider, useMsal } from '@azure/msal-react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { EventType } from '@azure/msal-browser';
import { PageLayout } from './components/PageLayout';
import Home from './pages/Home';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import useConfig from "./useConfig";
import { PublicClientApplication,  AccountInfo, AuthenticationResult} from "@azure/msal-browser";
import 'react-toastify/dist/ReactToastify.css';


const Pages = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */


    return (
        <Routes>
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

const App = () => {
    console.log('pppp');
    const [config] = useConfig();
    var token = localStorage.getItem('token');
    //console.log(token);
    //console.log(config);
    if (!config) return <>Loading config...</>;

    //console.log('App called');
    const msalInstance = new PublicClientApplication(config);
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
console.log('sss');
msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload
        ) {
            console.log(event.eventType);

            let result = event.payload as AccountInfo;
            msalInstance.setActiveAccount(result);
            sessionStorage.setItem('isAuthenticated', 'true');  
            let res = event.payload as AuthenticationResult;
            localStorage.setItem('loggedInUser', res?.account!.username!); 
            console.log(res.accessToken) ; 
            localStorage.setItem('AADToken', res.accessToken);       
        }
    });

    const appReducer = combineReducers({ form: formReducer });
    const store = createStore(appReducer);
    return (
        <>                 
            <MsalProvider instance={msalInstance}>
                    <Provider store={store}>
                        <PageLayout>
                            <Pages />
                        </PageLayout>
                    </Provider>
                </MsalProvider>
            </>
    );
}

export default App;