import { useEffect, useState } from 'react';
import { LogLevel } from "@azure/msal-browser";
import { SERVER_URL } from './environment/env_dev';



const useConfig = () => {
    const [config, setConfig] = useState<any>();

    useEffect(() => {
        async function getProviders() {
            const sessionConfig = sessionStorage.getItem("config");
            if (sessionConfig) {
                //console.log('called from session variable');
                setConfig(JSON.parse(sessionConfig));
                return [config];
            }
            //var provider = sessionStorage.getItem("provider");
            const response = await fetch(SERVER_URL + 'provider');
            const data = await response.json();           
            sessionStorage.setItem('apiClientId', data.apiClientId);
            sessionStorage.setItem('appVersion', data.version);
            //sessionStorage.setItem('host', data.serverUrl);
            //config for View Editor App
            var msalconfig = {
                auth: {
                    clientId: (data.appClientId as string),
                    authority: (data.appAuthority as string),
                    knownAuthorities: [(data.appAuthorityDomain as string)],
                    redirectUri: '/',
                    postLogoutRedirectUri: '/',
                    navigateToLoginRequestUrl: false
                },
                cache: {
                    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    //console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                                default:
                                    return;
                            }
                        },
                    },
                }
            };
            setConfig(msalconfig);
            sessionStorage.setItem("config", JSON.stringify(msalconfig));         
        };
        getProviders();
    }, []);

    return [config];
};

export default useConfig;
