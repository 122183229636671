import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { NavigationBar } from "./NavigationBar";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../pages/Home";
import BlankReport from "../pages/BlankReport";
import DisplayVault from "../pages/DisplayVault";
// import ConnectServer from '../pages/ConnectServer';
import ConnectServer from "../pages/ServerConnection";
import LoginForm from "../LoginForm";
import MFilesLogin from "../pages/MFilesLogin";
import MFilesLoginForm from "../MFilesLoginForm";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { ToastContainer } from "react-toastify";

interface Props {
  children: any;
}

export const PageLayout = ({ children }: Props) => {
  var token;
  useEffect(() => {
    if (token == null) {
      token = localStorage.getItem("token")!;
    } else {
      localStorage.setItem("token", token);
    }
  });

  const navigate = useNavigate();
  token = localStorage.getItem("token");
  var isAuthorized = sessionStorage.getItem("isAuthorized");
  var isAuthenticated = sessionStorage.getItem("isAuthenticated");
  var isMFilesLogin = sessionStorage.getItem("isMFilesLogin");
  var docId = sessionStorage.getItem("docId");
  var fileId = sessionStorage.getItem("fileId");
  var server = sessionStorage.getItem("server");
  var server1 = sessionStorage.getItem("host");
  var vaultId = sessionStorage.getItem("vaultId");
  // let isMFilesConnect = sessionStorage.getItem('isMFilesConnect');
  // let isConnectVault = sessionStorage.getItem('isConnectVault');
  let isServerConnect = sessionStorage.getItem("isServerConnect");
  let isVaultConnect = sessionStorage.getItem("isVaultConnect");
  let isClientCall = sessionStorage.getItem("isClientCall");
  let loggedInUser = localStorage.getItem('loggedInUser');
  let AADToken = localStorage.getItem('AADToken');
  var fileName = sessionStorage.getItem("fileName");
 
  return (
    <>
      <NavigationBar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <UnauthenticatedTemplate>
        <Routes>
          {isMFilesLogin === "true" && (isAuthorized === "" || isAuthorized === "false") ? (
            <Route path="/" element={<MFilesLogin />} />
          ) : 
          (isAuthorized === "false" || isAuthorized === null) && loggedInUser === null ? (
            <Route path="/" element={<LoginForm />} />
          ) : 
          (isClientCall !== "true" && server === null && fileId === null && isServerConnect !== "true") ? (
            <Route path="/" element={<BlankReport />} />
          ):
          (isClientCall !== "true" && server === "null") ||
            (server === null && fileId === "null") ||
            (fileId === null && isServerConnect === "true")  ? (
            <Route path="/" element={<ConnectServer />} />
          ) :
          (isClientCall === "true" && server === "null") ? (
            <Route path="/" element={<ConnectServer />} />
          )
          :
          (isClientCall === "true" && vaultId === localStorage.getItem('vaultId') && server1 === localStorage.getItem('server')) ? (
            <Route path="/" element={<Home />} />
          )
          : (isClientCall === "true" && (vaultId !== localStorage.getItem('vaultId') || server1 !== localStorage.getItem('server') ||(fileName === "undefined") )) ? (
            <Route path="/" element={<ConnectServer />} />
          )
          :
          (
            <Route path="/" element={<Home />} />
          )}
        </Routes>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Routes>
          {isAuthenticated === "false" ? (
            <Route path="/" element={<LoginForm />} />
          ) : (isAuthorized === "false" ||
              isAuthorized === null ||
              isAuthorized === "null") &&
            isClientCall === "true" ? (
            <Route path="/" element={<ConnectServer />} />
          ) : (isAuthorized === "false" ||
              isAuthorized === null ||
              isAuthorized === "null") &&
            isServerConnect !== "true" &&
            isVaultConnect !== "true" ? (
            <Route path="/" element={<BlankReport />} />
          ) : server === "null" ||
            (server === null && fileId === "null") ||
            (fileId === null && isServerConnect === "true") || (fileName === "undefined") ? (
            <Route path="/" element={<ConnectServer />} />
          ) : (
            // : fileId === 'null' || fileId === null && isVaultConnect ==='true' ? (<Route path="/" element={<DisplayVault />} />)
            <Route path="/" element={<Home />} />
          )}
        </Routes>
      </AuthenticatedTemplate>
    </>
  );
};
