/* eslint-disable @typescript-eslint/no-unused-vars */
declare global {
    interface Window {
        _env_: {
            API_URL: string;
        };
    }
}
const apiUrl = window._env_.API_URL;

const getServerURL = () => {
    return `${apiUrl}/`;
}



export const SERVER_URL = getServerURL();

