import React from "react";
import ReactDOM from "react-dom";

import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const queryParameters = new URLSearchParams(window.location.search)
let docId = queryParameters.get("docId");
if (docId == null) {
    docId = sessionStorage.getItem('docId');
}
else
    sessionStorage.setItem('docId', docId!);
sessionStorage.setItem('docId', docId!);

let fileId = queryParameters.get("fileId")
if (fileId == null) {
    fileId = sessionStorage.getItem('fileId');
}
else
    sessionStorage.setItem('fileId', fileId!);

let fileName = queryParameters.get("fileName")
if (fileName == null) {
    fileName = sessionStorage.getItem('fileName');
}
else
    sessionStorage.setItem('fileName', fileName!);


let vaultId = queryParameters.get("vault");
if (vaultId == null) {
    vaultId = sessionStorage.getItem('vaultId');
}
else
    sessionStorage.setItem('vaultId', vaultId!);

let vaultName = queryParameters.get("vName");
if (vaultName == null) {
    vaultName = sessionStorage.getItem('vaultName');
}
else
    sessionStorage.setItem('vaultName', vaultName!);

let host = queryParameters.get("host");
if (host == null) {
    host = sessionStorage.getItem('host');
}
else
    sessionStorage.setItem('host', host!);

if (host != null) {
    let temp;
    if (host.includes('http://')) {
        temp = host!.replace('http://', '');
    }
    if (host.includes('https://')) {
        temp = host!.replace('https://', '');
    }
   
    let index = temp?.indexOf('.');
    let server = temp?.substring(0, index);
    
    if (server?.includes('-')) {
        let idx = server.indexOf('-');
        server = server?.substring(0, idx);
    }    
    sessionStorage.setItem('server', server);
    sessionStorage.setItem('isClientCall', 'true');
   // sessionStorage.setItem('isAuthorized', 'true');
    //sessionStorage.setItem("isServerConnect", "true");
    
}

var token = localStorage.getItem('token');
//console.log(token);

//sessionStorage.setItem('provider', 'AAD');
/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
ReactDOM.render(
<>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</>
 ,
 document.getElementById('root')
);



