/* eslint-disable no-loop-func */

import { useMsal } from "@azure/msal-react";
import { registerLicense, L10n, closest } from "@syncfusion/ej2-base";
import {
  DateTimePickerComponent,
  DatePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import {
  GridComponent,
  GroupSettingsModel,
  Group,
  Selection,
  RowDD,
  getObject,
  Reorder,
  load,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  CheckBoxSelection,
  DropDownListComponent,
  Inject,
  MultiSelectComponent,
  MultiSelectChangeEventArgs,
  ListBoxComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  DialogComponent,
  PositionDataModel,
} from "@syncfusion/ej2-react-popups";
import {
  BeforeSaveEventArgs,
  CellEditEventArgs,
  CellModel,
  getCell,
  getCellAddress,
  getRangeAddress,
  getRangeIndexes,
  SaveCompleteEventArgs,
  BeforeSortEventArgs,
  SpreadsheetComponent,
  getSheetName,
  getColumnHeaderText,
  UsedRangeModel,
  Column,
  Merge,
  getColumn,
  CellStyleModel,
  Save,
} from "@syncfusion/ej2-react-spreadsheet";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import "../App.css";
import { SERVER_URL } from "../environment/env_dev";
import { group } from "console";
import useIsMobile from "../hooks/ListenToResize";
import { useNavigate } from "react-router-dom";
import { DataUtil } from '@syncfusion/ej2-data';
import { Button } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

L10n.load({
  "en-US": {
    spreadsheet: {
      Home: "Format",
      View: "Arrange",
    },
  },
});

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5WdkJjW39fdHRVQWla"
);
var token = "";
var propIds: Map<string, CellModel> = new Map<string, CellModel>();
var rangeAddress: string;
var rowIndex: number;
var colIndex: number;
var objTypeIdCol: number;
var objIdCol: number;
var objClassCol: number;
var editLinkCol: number;
var modifiedCol: number;
var updatedCol: number;
var updatedLink: string;
var removedCol: number;
var dialog: any;
var dialog1: any;
var issheetRepeated: boolean;
var dropdownData: any;
var multiSelectData: any;
var booleandropdownData: any[] = [];
var vaultObjects: any[] = [];
var vaultClasses: any[] = [];
var transformedSheet: any[] = [];
var query: any;
var dropDownListObject: any;
var multiSelectListObject: any;
var dateTimePickerObject: any;
var datePickerObject: any;
var dropDownListBooleanObject: any;
var dropDownListVaultObject: any;
var dropDownListVaultClass: any;
var dropDownColHeaderObject: any;
var dropDownListUniqueProperty: any;
var dropDownListVaultObject1: any;
var dropDownListVaultClass1: any;
var dropDownListVaultObject2: any;
var dropDownListVaultClass2: any;
var isAdminSelected: boolean = true;
var isRearranging: boolean;
var currentRow: any;
var currentCol: any;
var propDataType: any;
var uploadId: any;
var uploadSize: any;
var propEditable: any;
var propId: any;
var propName: any;
var objTypeId: any;
var propobjTypeId: any;
var clsId: any;
var currentValue: any;
var prevValue: any;
var modifiedValue: any;
var usedRowIdx: any;
var usedColIdx: any;
var dialogInstance: any;
var dialogInstance1: any;
var dialogInstance2: any;
var dialogInstance3: any;
var dialogInstance4: any;
var dialogInstance5: any;
var dialogInstance6: any;
var dialogInstance7: any;
var dialogInstance8: any;
var dialogInstance9: any;
var dialogInstance10: any;
var dialogInstance11: any;
var dialogInstance12: any;
var dialogInstance13: any;
var dialogInstance14: any;
var dialogInstance15: any;
var dialogInstance16: any;
var modifiedRows: number[] = [];
var newRows: number[] = [];
var errorRows: any[] = [];
var newData: any[] = [];
var PropertyValue: any;
var PropertyHeaders: any[] = []; //properties of the object
var columnHeaders: any[] = []; //columns of the excel/spreadsheet
var reorderColumnHeaders: any[] = []; //columns of the excel/spreadsheet
var avladminColumnsSource: any[] = [];
var avladminColumns: any[] = [];
var requiredAdminColumns: any[] = [];
var position: PositionDataModel;
var headers: any[] = [];
var adminColumns: any[] = [];
var menuItems: any[] = [];
var headersValue: string;
var mulObj: any;
var mulObj1: any;
var listbox: any;
var existingpropIds: any[] = [];
var lookupIds: any[] = [];
var multiselectProperties: any[] = [];
var singleselectProperties: any[] = [];
var currentColumnOrder: any[] = [];
var ownerPDValue: any;
var ownerId: any;
var host: string;
var vaultId: string;
var docId: string;
var fileId: string;
var multiSelectDataItem: string;
var IsFileSave: boolean = false;
var IsLoaded: boolean = false;
var DisplayAdminCol: boolean = true;
var IsMultipleClass: boolean = false;
var vName: string;
var vaultId: string;
var version: string;
var mergeFileData: any;
var authType;
var inputOpenFileRef: any;
var inputOpenFileRef1: any;
var clsName: any;
var objTypeName: any;
let memoryData: any[] = [];
let memoryStyleData: any[] = [];
var colStyleWidth: any[] = [];
let tmpmemoryData: any[] = [];
let sheetMemoryData: any[] = [];
let adminColData: any[] = [];
var list1 = { text: "Name", value: "ID" };
var request: RequestInit = {
  method: "GET",
};
var g_obj_type_id: any;
var g_obj_type_name: any;
var g_cls_id: any;
var g_cls_name: any;
var tempDataSource: any[] = [];
let tempSelectedAdminText = "Select Admin";
let isSelectAllEvent: boolean = false;
let isSelectAdminEvent: boolean = false;
let isGridSorting: boolean = false;
let tempGridDataSource: any[] = [];

var selectedpropId: any;
var selectedclsId: any;
var selectedGUID: any;

function Home() {
  IsLoaded = false;
  const { instance } = useMsal();
  inputOpenFileRef = React.createRef();
  inputOpenFileRef1 = React.createRef();
  const navigate = useNavigate();

  const [selectedAllRows, setSelectedAllRows] = useState(true);
  const [selectedAdmin, setSelectedAdmin] = useState(true);
  // const [tempSelectedAdminText, setTempSelectedAdminText] = useState("Select Admin");
  const [hideUnHideAdmins, setHideUnHideAdmins] = useState(true);
  const { isMobile, isTablet, isIsLaptop } = useIsMobile();
  const [vlItem, SetVlItem] = useState("");

  const SSObj = React.useRef<SpreadsheetComponent>(null);
  authType = localStorage.getItem("authType");
  if (sessionStorage.getItem("vaultId") != null) {
    vaultId = sessionStorage.getItem("vaultId")!.toString();
  }
  if (sessionStorage.getItem("vaultName") != null) {
    vName = sessionStorage.getItem("vaultName")!.toString();
  }
  if (sessionStorage.getItem("appVersion") != null) {
    version = sessionStorage.getItem("appVersion")!.toString();
  }
  if (adminColumns.length === 0) {
    adminColumns.push({ Title: "WebView" });
    adminColumns.push({ Title: "vNextWebView" });
    adminColumns.push({ Title: "DesktopView" });
    adminColumns.push({ Title: "DesktopOpen" });
    adminColumns.push({ Title: "Size On Server" });
    adminColumns.push({ Title: "Last Modified" });
    adminColumns.push({ Title: "GUID" });
    adminColumns.push({ Title: "ID" });
    adminColumns.push({ Title: "Object Type" });
    adminColumns.push({ Title: "Object Name" });
    adminColumns.push({ Title: "Object Class" });
    adminColumns.push({ Title: "Class Name" });
    adminColumns.push({ Title: "Modified" });
    adminColumns.push({ Title: "Last Modified" });
    adminColumns.push({ Title: "Update Status" });
    adminColumns.push({ Title: "Updated Message" });
    adminColumns.push({ Title: "Created by" });
    adminColumns.push({ Title: "Last modified by" });
    adminColumns.push({ Title: "Version" });
    adminColumns.push({ Title: "Workflow" });
    adminColumns.push({ Title: "State" });
  }

  if (requiredAdminColumns.length === 0) {
    requiredAdminColumns.push("GUID");
    requiredAdminColumns.push("ID");
    requiredAdminColumns.push("Object Type");
    requiredAdminColumns.push("Object Name");
    requiredAdminColumns.push("Object Class");
    requiredAdminColumns.push("Class Name");
    requiredAdminColumns.push("Modified");
    requiredAdminColumns.push("Update Status");
    requiredAdminColumns.push("Updated Message");
  }
  //console.log(authType);
  const handleConnectServer = () => {
    sessionStorage.setItem("server", "null");
    // sessionStorage.setItem("host", "null");
    //sessionStorage.setItem("vaultId", "null");
    // sessionStorage.setItem("vaultName", "null");
    sessionStorage.setItem("fileId", "null");
    sessionStorage.setItem("isServerConnect", "true");
    navigate("/");
  };

  const handleConnectMFiles = () => {
    sessionStorage.setItem("fileId", "null");
    sessionStorage.setItem("isVaultConnect", "true");
    navigate("/");
  };

  const handleSaveFormats = () => {
    const spreadsheet: any = SSObj.current;
    let sheetIndex = spreadsheet!.activeSheetIndex;
    spreadsheet?.saveAsJson().then((response) => {
      // memoryStyleData = [];
      jsonData = response;
      let obj = JSON.parse(JSON.stringify(jsonData.jsonObject.Workbook));
      console.log(jsonData.jsonObject.Workbook);
      let rows = obj.sheets[sheetIndex].rows;
      let cols = obj.sheets[sheetIndex].columns;
      let cells = obj.sheets[sheetIndex].rows[0].cells;
      for (let i = 1; i < rows.length - 1; i++) {
        for (let j = 0; j < cells.length; j++) {
          var colHeader = rows && rows[0].cells[j].value;
          if (colHeader === undefined) continue;
          let item = memoryStyleData && memoryStyleData[i];
          if (item !== undefined) {
            item[colHeader] = rows && rows[i].cells[j]?.style;
          }
        }
      }
      for (let j = 0; j < cells.length; j++) {
        var colHeader = rows && rows[0].cells[j].value;
        let item = colStyleWidth && colStyleWidth[0];
        if (item !== undefined) {
          item[colHeader] = cols && cols[j]?.width;
        }
      }
      // console.log(memoryStyleData);
      // console.log(colStyleWidth);
    });
  }

  function getLastEmptyRow(){
    const spreadsheet: any = SSObj.current;
    const sheet = spreadsheet.getActiveSheet();
    const rowCount = sheet.usedRange.rowIndex;
    
    let lastEmptyRow = rowCount + 1; // Start with the next row after the last used row
    for (let i = rowCount; i >= 0; i--) {
      const row = sheet.rows[i];
      const isEmpty = row.cells.every(cell => !cell.value);
      if (isEmpty) {
        lastEmptyRow = i;
      } else {
        break; // Exit loop when a non-empty row is found
      }
    }
    return lastEmptyRow;
  }

  const handleCreateNewObject = () => {
    const spreadsheet: any = SSObj.current;
    spreadsheet?.freezePanes(1, 0, 0);
    const sheet = spreadsheet.getActiveSheet();
    const rowCount = sheet.usedRange.rowIndex;

    // Loop through the rows to find the first empty row
    let lastEmptyRow = rowCount + 1; // Start with the next row after the last used row
    for (let i = rowCount; i >= 0; i--) {
      const row = sheet.rows[i];
      const isEmpty = row.cells.every(cell => !cell.value);
      if (isEmpty) {
        lastEmptyRow = i;
      } else {
        break; // Exit loop when a non-empty row is found
      }
    }

    // Scroll to the last empty row
    spreadsheet.goTo(`${lastEmptyRow + 1}`);
    AddRow(lastEmptyRow + 1);
  }


  useEffect(() => {
    let spreadsheet = SSObj.current;
    spreadsheet?.addRibbonTabs([
      {
        header: { text: "M-Files" },
        content: [
          {
            text: "Update View Report",
            tooltipText: "Update View Report",
            prefixIcon: "e-save",
            click: () => {
              SaveInMFiles();
            },
          },
          {
            text: "Update M-Files Objects",
            tooltipText: "Update M-Files Objects",
            prefixIcon: "e-save",
            click: () => {
              SaveAsObject();
            },
          },
          {
            text: "Transform To View",
            tooltipText: "Transform To View",
            prefixIcon: "e-save",
            click: () => {
              TransformToView();
            },
          },
          {
            text: "Connect To Server",
            tooltipText: "Connect To Server",
            prefixIcon: "e-save",
            click: () => {
              handleConnectServer();
            },
          },
          // {
          //   text: "Connect To Vault",
          //   tooltipText: "Connect To Vault",
          //   prefixIcon: "e-save",
          //   click: () => {
          //     handleConnectMFiles();
          //   },
          // },
          {
            text: "Create New Object",
            tooltipText: "Create New Object",
            prefixIcon: "e-save",
            click: () => {
              handleCreateNewObject();
            },
          },
          // {
          //   text: "Save Format",
          //   tooltipText: "Save Format",
          //   prefixIcon: "e-save",
          //   click: () => {
          //     handleSaveFormats();
          //   },
          // },
        ],
      },
      {
        header: { text: "Help" },
        content: [
          {
            text: "About View Maven",
            tooltipText: "About View Maven",
            prefixIcon: "e-paste",
            showAlwaysInPopup: true,
            click: () => {
              Help();
            },
          },
        ],
      },
    ]);
    spreadsheet?.addToolbarItems("View", [
      {
        text: "Arrange Columns",
        tooltipText: "Arrange Columns",
        prefixIcon: "e-sorting-1",
        click: () => {
          ReorderColumns();
        },
      },
      //{
      //    text: 'Hide Admin Columns', tooltipText: 'Hide Admin Columns', prefixIcon: 'e-sorting-1', click: () => { HideAdminColumns(); }
      //}
    ]);
    spreadsheet?.hideToolbarItems("Arrange", [4, 5, 6]);
  }, []);

  let dateValue: Date = new Date();

  docId = sessionStorage.getItem("docId")!;
  fileId = sessionStorage.getItem("fileId")!;

  vaultId = sessionStorage.getItem("vaultId")!;

  host = sessionStorage.getItem("host")!;
  host = host + "/REST/";

  //pop-up dialog
  position = { X: "center", Y: "center" };
  const firstPosition = { X: "center", Y: "center" };
  let settings: any = { effect: "Zoom", duration: 400, delay: 0 };

  let alertDlgButtonswithNew: any = [
    {
      buttonModel: {
        content: 'New',
        cssClass: 'e-success',
        isPrimary: true,
      },
      'click': () => {
        CreateLookup();
      }
    },
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        let spreadsheet = SSObj.current;
        let sheetName = spreadsheet?.getActiveSheet().name;
        currentValue = getCell(
          currentRow,
          currentCol,
          spreadsheet?.getActiveSheet()!
        )?.value;
        if (propDataType == 9) {
          if (currentValue != dropDownListObject.value) {
            spreadsheet?.updateCell(
              {
                value: dropDownListObject.text,
                index: dropDownListObject.value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 10) {
          if (currentValue != multiSelectListObject.value) {
            var value = multiSelectDataItem?.substring(
              0,
              multiSelectDataItem?.length - 1
            );
            spreadsheet?.updateCell(
              {
                value: value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 7) {
          if (currentValue != dateTimePickerObject.previousElementValue) {
            spreadsheet?.updateCell(
              {
                value: dateTimePickerObject.previousElementValue,
                index: dateTimePickerObject.previousElementValue,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 5) {
          if (currentValue != datePickerObject.previousElementValue) {
            spreadsheet?.updateCell(
              {
                value: datePickerObject.previousElementValue,
                index: datePickerObject.previousElementValue,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 8) {
          if (currentValue != dropDownListBooleanObject.value) {
            spreadsheet?.updateCell(
              {
                value: dropDownListBooleanObject.text,
                index: dropDownListBooleanObject.value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (columnHeaders.indexOf("Modified") !== -1)
          spreadsheet?.updateCell(
            { value: "True" },
            getCellAddress(currentRow, columnHeaders.indexOf("Modified"))
          ); //  to specify the cell value, range

        //set Modified flag in In-Memory data
        memoryData = sheetMemoryData?.find(
          (x) => x.SheetName === sheetName
        )?.MemData;
        let item = memoryData && memoryData[currentRow - 1];
        if (item !== undefined) item["Modified"] = "True";

        dialogInstance.hide();
        dialogInstance1.hide();
        dialogInstance2.hide();
        dialogInstance11.hide();
        dialogInstance4.hide();
      },
    },
  ];

  let alertDlgButtons: any = [
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        let spreadsheet = SSObj.current;
        let sheetName = spreadsheet?.getActiveSheet().name;
        currentValue = getCell(
          currentRow,
          currentCol,
          spreadsheet?.getActiveSheet()!
        )?.value;
        if (propDataType == 9) {
          if (currentValue != dropDownListObject.value) {
            spreadsheet?.updateCell(
              {
                value: dropDownListObject.text,
                index: dropDownListObject.value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 10) {
          if (currentValue != multiSelectListObject.value) {
            var value = multiSelectDataItem?.substring(
              0,
              multiSelectDataItem?.length - 1
            );
            spreadsheet?.updateCell(
              {
                value: value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 7) {
          if (currentValue != dateTimePickerObject.previousElementValue) {
            spreadsheet?.updateCell(
              {
                value: dateTimePickerObject.previousElementValue,
                index: dateTimePickerObject.previousElementValue,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 5) {
          if (currentValue != datePickerObject.previousElementValue) {
            spreadsheet?.updateCell(
              {
                value: datePickerObject.previousElementValue,
                index: datePickerObject.previousElementValue,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 8) {
          if (currentValue != dropDownListBooleanObject.value) {
            spreadsheet?.updateCell(
              {
                value: dropDownListBooleanObject.text,
                index: dropDownListBooleanObject.value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (columnHeaders.indexOf("Modified") !== -1)
          spreadsheet?.updateCell(
            { value: "True" },
            getCellAddress(currentRow, columnHeaders.indexOf("Modified"))
          ); //  to specify the cell value, range

        //set Modified flag in In-Memory data
        memoryData = sheetMemoryData?.find(
          (x) => x.SheetName === sheetName
        )?.MemData;
        let item = memoryData && memoryData[currentRow - 1];
        if (item !== undefined) item["Modified"] = "True";

        dialogInstance.hide();
        dialogInstance1.hide();
        dialogInstance2.hide();
        dialogInstance11.hide();
        dialogInstance4.hide();
      },
    },
  ];

  let colHeaderButtons: any = [
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        let spreadsheet = SSObj.current;
        let sheetName = spreadsheet?.getActiveSheet().name;
        currentValue = getCell(
          currentRow,
          currentCol,
          spreadsheet?.getActiveSheet()!
        )?.value;
        if (currentValue !== dropDownColHeaderObject.value) {
          spreadsheet?.updateCell(
            {
              value: dropDownColHeaderObject.text,
              index: dropDownColHeaderObject.value,
            },
            getCellAddress(currentRow, currentCol)
          ); //  to specify the cell value, range
        }
        if (adminColData.find((x) => x.SheetName === sheetName) === undefined) {
          adminColData.push({
            Cls: dropDownListVaultClass1.text,
            ObjType: dropDownListVaultObject1.text,
            SheetName: spreadsheet?.getActiveSheet().name,
          });
        } else {
          var tmp = adminColData.find((x) => x.SheetName === sheetName);
          tmp.Cls = dropDownListVaultClass1.text;
          tmp.ObjType = dropDownListVaultObject1.text;
        }

        let newcolName = dropDownColHeaderObject.text;
        var tmpHeader = reorderColumnHeaders.find(
          (x) => x.SheetName === sheetName && x.Title === currentValue
        )!;

        var tmpHeader1 = reorderColumnHeaders.find(
          (x) => x.SheetName === sheetName && x.Title === newcolName
        )!;
        var tmpHeaderIndex1 = reorderColumnHeaders.findIndex(
          (x) => x.SheetName === sheetName && x.Title === newcolName
        )!;
        if (tmpHeader !== undefined) {
          tmpHeader.Title = newcolName;
        }
        if (tmpHeader1 !== undefined) {
          reorderColumnHeaders.splice(tmpHeaderIndex1, 1);
          //tmpHeader1.Title = currentValue;
        }
        var index = columnHeaders.indexOf(currentValue);

        if (index !== -1) {
          columnHeaders[index] = newcolName;
        }

        memoryData = sheetMemoryData.find(
          (x) => x.SheetName === sheetName
        )?.MemData;
        if (memoryData === undefined) {
          //sheet not yet transformed and class not yet defined
        }
        memoryData?.forEach(function (row, i) {
          row[newcolName] = row[currentValue];
          // console.log(row);
        });
        dialogInstance10.hide();
      },
    },
  ];

  let valueListButtons: any = [
    {
      buttonModel: {
        content: "Ok",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        CreateValueList(propobjTypeId, vlItem);
        dialogInstance13.hide();
      },
    },
  ];

  const onCellEditing = (args: CellEditEventArgs): void => {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    if (
      propDataType === 1 ||
      propDataType === 13 ||
      propDataType === 2 ||
      propDataType === 11
    ) {
      if (columnHeaders.indexOf("Modified") !== -1)
        spreadsheet?.updateCell(
          { value: "True" },
          getCellAddress(currentRow, columnHeaders.indexOf("Modified"))
        );
      let item = memoryData && memoryData[currentRow - 1];
      if (item !== undefined) item["Modified"] = "True";
    }
  };

  async function CreateLookup() {
    console.log(propobjTypeId);
    let tempvaultId = vaultId.replace("{", "").replace("}", "");
    if (selectedclsId == -1)//valuelist
    {
      dialogInstance13.show();
      // await CreateValueList(propobjTypeId);

    }
    else {
      var newLink = "m-files://newobject/" + tempvaultId + "/" + propobjTypeId + ""
      // var newLink = host + "/Default.aspx#" + tempvaultId + "/object/" + selectedGUID + "/latest"
      console.log(newLink);
      // window.location.href = newLink as string;
      window.open(
        newLink,
        '_blank'
      );
    }
  }

  async function CreateValueList(vlId: number, text: string) {
    const url = host + "valuelists/" + propobjTypeId + "/items"
    var newProp = {
      'Name': text
    };
    const request1: RequestInit = {
      method: "POST",
      body: JSON.stringify(newProp)
    };

    const response1 = await RunAPI(url, request1);
    const data1 = await response1.json();
    console.log(data1);

  }

  async function actionBegin(args: any) {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    if (args.action === "autofill") {
      let range: number[] = getRangeIndexes(args.args.eventArgs.fillRange);
      let startRow = range[0];
      let endRow = range[2];
      for (let i = startRow; i <= endRow; i++) {
        if (columnHeaders.indexOf("Modified") !== -1)
          spreadsheet?.updateCell(
            { value: "True" },
            getCellAddress(i, columnHeaders.indexOf("Modified"))
          );
        let item = memoryData && memoryData[i - 1];
        if (item !== undefined) item["Modified"] = "True";
      }
    }
    if (args.action === "beforeOpen") {
      spreadsheet?.enableToolbarItems("M-Files", [0], true);
      spreadsheet?.enableToolbarItems("M-Files", [2], false);
    }
    if (args.action === "gotoSheet") {
      listbox.dataSource = [];
      if (args.args.eventArgs.currentSheetIndex === 0) {
        //ViewReport
        spreadsheet?.enableToolbarItems("M-Files", [0], true);
        spreadsheet?.enableToolbarItems("M-Files", [1], true);
        spreadsheet?.enableToolbarItems("M-Files", [2], false);
      } else if (args.args.eventArgs.currentSheetIndex !== 0) {
        //ViewReport
        spreadsheet?.enableToolbarItems("M-Files", [0], false);
        spreadsheet?.enableToolbarItems("M-Files", [2], true);
      } else {
        spreadsheet?.enableToolbarItems("M-Files", [0], true);
        spreadsheet?.enableToolbarItems("M-Files", [2], false);
      }
      let sheetName =
        spreadsheet?.sheets[args.args.eventArgs.currentSheetIndex].name;
      await GetObjectProperties(1, args.args.eventArgs.currentSheetIndex);
      if (transformedSheet.indexOf(sheetName) !== -1) {
        spreadsheet?.enableToolbarItems("M-Files", [2], false);
        LoadMemoryDataFromSheet(
          args.args.eventArgs.currentSheetIndex,
          false,
          false
        );
        let item: any = memoryData && memoryData[0];
        g_obj_type_id = item && item["Object Type"];
        g_obj_type_name = item && item["Object Name"];
        g_cls_id = item && item["Object Class"];
        g_cls_name = item && item["Class Name"];
      }
      dropDownListVaultClass!.value = null;
      dropDownListVaultClass1!.value = null;
      dropDownListVaultObject!.value = null;
      dropDownListVaultObject1!.value = null;
    }
  }

  async function RunAPI(url: string, request: RequestInit) {
    var response;
    try {
      token = localStorage.getItem("token")!;
      if (authType === "MFiles") {
        if (url.includes("?")) {
          url = url + "&auth=" + token;
        } else {
          url = url + "?auth=" + token;
        }
        request.headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      } else {
        //console.log(token);
        sessionStorage.setItem("isAuthenticated", "true");
        request.headers = {
          Authorization: "Bearer " + token,
          "X-Vault": vaultId,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      }
      return response;
    } catch (error) {
      console.log("Error : " + error);
      return response;
    }
  }

  async function onCreated() {
    if (IsLoaded) return;
    reorderColumnHeaders = [];
    docId = sessionStorage.getItem("docId")!;
    //console.log(docId);
    fileId = sessionStorage.getItem("fileId")!;
    let spreadsheet = SSObj.current;
    var request: RequestInit = {
      method: "GET",
    };
    const response1 = await RunAPI(
      host + "objects/0/" + docId + "/latest/files/" + fileId + "/content",
      request
    );
    //console.log(response1);

    if (response1 === undefined || response1 === null) return;
    //console.log(response1.status);
    if (response1.status === 403) {
      //sessionStorage.setItem("isAuthenticated", "true");
      sessionStorage.setItem("isAuthorized", "false");
      localStorage.removeItem("token");
      localStorage.removeItem("loggedInUser");
      instance.setActiveAccount(null);
      window.location.reload();
    } else if (response1.status !== 200) {
      sessionStorage.setItem("isAuthenticated", "false");
      localStorage.removeItem("token");
      localStorage.removeItem("loggedInUser");
    } else {
      sessionStorage.setItem("isAuthenticated", "true");
      sessionStorage.setItem("isAuthorized", "true");
    }

    const fileBlob = await response1.blob();
    try {
      var file = new File([fileBlob], "Sample.xlsx"); //convert the blob into file
      if (spreadsheet) {
        spreadsheet.open({ file: file }); // open the file into Spreadsheet
      }
    } catch (error) {
      console.log("Error in opening the View report : " + error);
    }
    spreadsheet?.element
      .querySelector(".e-sheet-panel")
      ?.addEventListener("click", function (event) {
        let target: HTMLElement = event.target as HTMLElement;
        if (target && target.classList.contains("e-cell")) {
          currentRow = target.parentElement!.getAttribute("aria-rowindex");
          currentCol = target.getAttribute("aria-colindex");
          onSelect(currentRow - 1, currentCol - 1);
        }
      });
  }
  const onBeforeSort = (args): void => {
    let spreadsheet = SSObj.current;
    let actCellIdxs = getRangeIndexes(args.range);
    args.range =
      spreadsheet?.getActiveSheet().name +
      "!" +
      getRangeAddress([actCellIdxs[0], actCellIdxs[1]]);

  };

  const onSortComplete = (args) => {
    let sortField = args.sortOptions.sortDescriptors.field;
    let sortOrder = args.sortOptions.sortDescriptors.order;
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet!.getActiveSheet().name;
    let fieldName = getCell(
      0,
      LetterToNumber(sortField) - 1,
      spreadsheet!.getActiveSheet()
    )?.value!;
    let dataType = existingpropIds.find((x) => x.Name === fieldName)?.DataType;
    if (sheetMemoryData.find((x) => x.SheetName === sheetName) !== undefined) {
      var tmpMemory = sheetMemoryData.find((x) => x.SheetName === sheetName)!;
      if (tmpMemory !== undefined) {
        var tmpMemData = tmpMemory.MemData;
        console.log('Input Data:', tmpMemData);
        if (sortOrder === "Ascending") {
          if (dataType === 5) {
            tmpMemData.sort(function (a, b) {
              return compareDates(a[fieldName], b[fieldName]);
            });
          }
          else {
            if (fieldName === "ID" || dataType === 2) {
              tmpMemData.sort(function (a, b) {
                return compareNumbers(a[fieldName], b[fieldName]);
              });
            } else {
              const sortedArray = tmpMemData.filter(x => x[fieldName] !== "").sort(function (a, b) {
                return compareStrings(a[fieldName], b[fieldName]);
              }).concat(tmpMemData.filter(x => x[fieldName] === ""));
              tmpMemData = sortedArray;
            }
          }
        } else {
          if (dataType === 5) {
            tmpMemData.sort(function (a, b) {
              return compareDatesDesc(a[fieldName], b[fieldName]);
            });
          }
          else {
            if (fieldName === "ID" || dataType === 2) {
              tmpMemData.sort(function (a, b) {
                return compareNumbersDesc(a[fieldName], b[fieldName]);
              });
            } else {
              const sortedArrayDesc = tmpMemData.filter(x => x[fieldName] !== "").sort(function (a, b) {
                return compareStringsDesc(a[fieldName], b[fieldName]);
              }).concat(tmpMemData.filter(x => x[fieldName] === ""));
              tmpMemData = sortedArrayDesc;
            }
          }
        }
        //tmpMemData.reverse();
        console.log('Output Data:', tmpMemData);
        tmpMemory.MemData = tmpMemData;
      }
    }
  };

  const onFileMenuBeforeOpen = (args: any) => {
    let spreadsheet = SSObj.current;
    if (args.items.length === 3) {
      spreadsheet?.addFileMenuItems(
        [{ text: "Load New File", iconCss: "e-open e-icons" }],
        "Open",
        false
      );

      // spreadsheet?.addFileMenuItems([{ text: 'Exit', iconCss: 'e-save e-icons'}], 'Save As', true);
    }
    spreadsheet?.hideFileMenuItems(["Open"]);
  };

  //add context menu
  const onContextMenuBeforeOpen = (args: any) => {
    dialogInstance?.hide();
    dialogInstance1?.hide();
    dialogInstance2?.hide();
    dialogInstance3?.hide();
    dialogInstance4?.hide();
    dialogInstance10?.hide();
    let spreadsheet = SSObj.current;
    if (
      args.element.id === spreadsheet?.element.id + "_contextmenu" &&
      closest(args.event.target as Node, ".e-rowhdr-table")
    ) {
      spreadsheet?.addContextMenuItems(
        [{ text: "Edit In M-Files" }],
        "Insert Row",
        false
      );
      spreadsheet?.addContextMenuItems(
        [{ text: "Reload Record" }],
        "Insert Row",
        false
      );
      spreadsheet?.addContextMenuItems(
        [{ text: "Insert Record" }],
        "Insert Row",
        false
      );
      spreadsheet?.removeContextMenuItems(["Hide Row"]);
      //spreadsheet?.removeContextMenuItems(['Hide Rows']);
      //spreadsheet?.removeContextMenuItems(['Hide Columns']);
    }
    spreadsheet?.removeContextMenuItems(["Hide Column"], false);
    // GetProperties();
    GetColumnHeaders(spreadsheet?.activeSheetIndex);
  };

  async function onFileMenuItemSelect(args: any) {
    let spreadsheet = SSObj.current;
    if (args.item.text === "Load New File") {
      inputOpenFileRef.current.click();
    }
    if (args.item.text === "Upload File") {
      inputOpenFileRef1.current.click();
    }
    if (
      args.item.text !== "New" ||
      "Open" ||
      "Load New File" ||
      "Save As" ||
      "Merge Sheet" ||
      "File"
    ) {
      //merge sheet is clicked
      spreadsheet?.showSpinner();
      var sheetName: any;
      //console.log(mergeFileData);
      if (mergeFileData != null) {
        mergeFileData.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            return;
          }
          var data = JSON.parse(temp);
          if (sheetName === args.item.text) {
            let row = spreadsheet?.getActiveSheet().usedRange?.rowIndex! + 1;
            data.forEach(function (item, i) {
              for (let j = 0; j < columnHeaders.length; j++) {
                spreadsheet!.updateCell(
                  { value: item[columnHeaders[j]] },
                  getSheetName(spreadsheet!, 0) +
                  "!" +
                  getCellAddress(i + row - 1, j)
                );
              }
              AddRow(row + i);
            });
          }
        });
      }
      spreadsheet?.hideSpinner();
    }
  }

  const onContextMenuItemSelect = (args: any) => {
    let objId, guid;
    let spreadsheet = SSObj.current;
    let sheet = spreadsheet?.getActiveSheet();
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    rangeAddress = sheet?.activeCell as string;
    rowIndex = Number(rangeAddress.replace(/[^0-9]/gi, "")); //row
    let objTypeId = g_obj_type_id;
    let clsId = g_cls_id;

    let item = memoryData && memoryData[rowIndex - 2];
    if (item !== undefined) {
      objId = item["ID"];
      guid = item["GUID"];
    }
    if (args.item.text === "Edit In M-Files") {
      let tempvaultId = vaultId.replace("{", "").replace("}", "");
      guid = guid!.replace("{", "").replace("}", "");
      var editLink =
        "m-files://edit/" + tempvaultId + "/" + objTypeId + "-" + objId;
      window.location.href = editLink as string;
    }
    if (args.item.text === "Reload Record") {
      ReloadData(rowIndex - 1, objId, guid, sheetName);
    }
    if (args.item.text === "Insert Record") {
      AddRow(rowIndex);
    }
  };

  function AddRow(rowIndex: any) {
    let spreadsheet = SSObj.current;
    //spreadsheet?.insertRow(rowIndex - 1);
    if (columnHeaders.indexOf("Object Type") !== -1)
      spreadsheet?.updateCell(
        { value: g_obj_type_id?.toString() },
        getSheetName(spreadsheet!, 0) +
        "!" +
        getCellAddress(rowIndex - 1, columnHeaders.indexOf("Object Type"))
      );

    if (columnHeaders.indexOf("Object Name") !== -1)
      spreadsheet?.updateCell(
        { value: g_obj_type_name.toString() },
        getCellAddress(rowIndex - 1, columnHeaders.indexOf("Object Name"))
      );

    if (columnHeaders.indexOf("Object Class") !== -1)
      spreadsheet?.updateCell(
        { value: g_cls_id.toString() },
        getCellAddress(rowIndex - 1, columnHeaders.indexOf("Object Class"))
      );

    if (columnHeaders.indexOf("Class Name") !== -1)
      spreadsheet?.updateCell(
        { value: g_cls_name.toString() },
        getCellAddress(rowIndex - 1, columnHeaders.indexOf("Class Name"))
      );

    if (columnHeaders.indexOf("Modified") !== -1)
      spreadsheet?.updateCell(
        { value: "FALSE" },
        getCellAddress(rowIndex - 1, columnHeaders.indexOf("Modified"))
      );
    if (columnHeaders.indexOf("Update Status") !== -1)
      spreadsheet?.updateCell(
        { value: "FALSE" },
        getCellAddress(rowIndex - 1, columnHeaders.indexOf("Update Status"))
      );
    if (columnHeaders.indexOf("ID") !== -1)
      spreadsheet?.updateCell(
        { value: "-1" },
        getCellAddress(rowIndex - 1, columnHeaders.indexOf("ID"))
      );

    let colHeaderText = getColumnHeaderText(columnHeaders.length);
    spreadsheet?.cellFormat(
      { fontFamily: "Calibri", fontSize: "9pt", textAlign: "right" },
      "B" + rowIndex + ":" + colHeaderText + rowIndex
    );
    spreadsheet?.cellFormat(
      { fontFamily: "Calibri", fontSize: "9pt", textAlign: "left" },
      "A" + rowIndex
    );
    MarkObligatoryProperties(true);
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;

    let obj1 = {};
    obj1["ID"] = "-1";
    obj1["Object Class"] = g_cls_id;
    obj1["Class Name"] = g_cls_name;
    obj1["Object Type"] = g_obj_type_id;
    obj1["Object Name"] = g_obj_type_name;
    if (columnHeaders.indexOf("Lookup ID") !== -1 && lookupIds.length > 0) {
      var lkupIdPropDefID = lookupIds[0].lkupId;
      //has property lookup Id
      //fetch lookup Id and see whether the objects exists already
      var lkupId = getCell(
        rowIndex - 1,
        columnHeaders.indexOf("Lookup ID"),
        spreadsheet?.getActiveSheet()!
      )?.value;
      const url =
        host +
        "objects/" +
        g_obj_type_id +
        "?p100=" +
        g_cls_id +
        "&p" +
        lkupIdPropDefID +
        "=" +
        lkupId;
      RunAPI(url, request).then((response) => {
        response?.json()?.then((data) => {
          //console.log(data.Items.length);
          if (data?.Items?.length > 0) {
            //data exists already in M-Files..so update it
            var id = data.Items[0].ObjVer.ID;
            var index = memoryData?.find((x) => x.ID === id);
            if (index === undefined) {
              obj1["ID"] = id;
              obj1["Modified"] = "True";
            } else {
              var item = memoryData && memoryData[index];
              item["Modified"] = "True";
            }
          } else {
            obj1["ID"] = "-1";
          }
        });
      });
    } else {
      //add a new row to In-Memory Data
      let obj1 = {};
      obj1["ID"] = "-1";
      obj1["Object Class"] = g_cls_id;
      obj1["Class Name"] = g_cls_name;
      obj1["Object Type"] = g_obj_type_id;
      obj1["Object Name"] = g_obj_type_name;
    }

    //memoryData.push(obj1);
    memoryData?.splice(rowIndex - 2, 0, obj1);
  }

  //const GetProperties = () => {
  //    let spreadsheet = SSObj.current;
  //    let usedRowIdx = spreadsheet?.sheets[1].usedRange?.rowIndex!;
  //    spreadsheet?.getData("Properties!A2:D" + usedRowIdx).then(data => {
  //        propIds = data;
  //    });
  //}

  async function GetColumnHeaders(sheetIndex) {
    let spreadsheet = SSObj.current;
    columnHeaders = [];
    let usedColIdx = spreadsheet?.sheets[sheetIndex].usedRange?.colIndex!;
    let sheetName = spreadsheet?.sheets[sheetIndex].name;
    let colHeaderText = getColumnHeaderText(usedColIdx + 1);
    let propLabel = '-';
    await spreadsheet
      ?.getData(
        spreadsheet?.sheets[sheetIndex].name + "!A1:" + colHeaderText + "1"
      )
      .then((data) => {
        //console.log(data);
        data.forEach((value: CellModel, key: string) => {
          if (value.value == undefined) return;
          columnHeaders.push(value.value);

          if (adminColumns.find((x) => x.Title == value.value) !== undefined) {

            if (
              reorderColumnHeaders.find(
                (x) => x.Title === value.value && x.SheetName === sheetName
              ) === undefined
            ) {
              if (columnHeaders.indexOf(value.value) !== -1) {
                reorderColumnHeaders.push({
                  Title: value.value,
                  IsAdmin: true,
                  Visible: false,
                  SheetName: sheetName,
                  PropertyLabel: propLabel
                });
              } else {
                reorderColumnHeaders.push({
                  Title: value.value,
                  IsAdmin: true,
                  Visible: false,
                  SheetName: sheetName,
                  PropertyLabel: propLabel
                });
              }
              //hide admin columns by default
              // let tmpIndex = reorderColumnHeaders.findIndex((x) => x.IsAdmin === true);
              // console.log(tmpIndex);
              // spreadsheet?.delete(tmpIndex, tmpIndex, "Column");
            }
            if (avladminColumnsSource.indexOf(value.value) == -1) {
              avladminColumnsSource.push(value.value);
              avladminColumns.push(value.value);
            }
          }
          if (
            reorderColumnHeaders.find(
              (x) => x.Title === value.value && x.SheetName === sheetName
            ) === undefined
          ) {
            if (columnHeaders.indexOf(value.value) !== -1) {
              reorderColumnHeaders.push({
                Title: value.value,
                IsAdmin: false,
                Visible: true,
                SheetName: sheetName,
                PropertyLabel: propLabel
              });
            } else {
              reorderColumnHeaders.push({
                Title: value.value,
                IsAdmin: false,
                Visible: false,
                SheetName: sheetName,
                PropertyLabel: propLabel
              });
            }
          }
        });
      });
  }

  async function CreateData() {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    let newProp: any;
    let text: any;
    let clsId: any;
    let objType: any;
    let rIndex: any;
    spreadsheet?.showSpinner();
    if (columnHeaders.indexOf("Updated Message") !== -1)
      spreadsheet?.selectRange(
        getCellAddress(0, columnHeaders.indexOf("Updated Message"))
      );
    for (let i = 0; i <= newRows.length; i++) {
      text = '{ "PropertyValues" : [';
      rIndex = newRows[i];
      console.log(rIndex);
      clsId = g_cls_id;
      objType = g_obj_type_id;
      try {
        existingpropIds.forEach((item1: any) => {
          if (item1.SheetName === sheetName) {
            if (columnHeaders.indexOf(item1.Name) != -1) {
              propId = item1.Id;
              propDataType = item1.DataType;
              let cell = getCell(
                rIndex,
                columnHeaders.indexOf(item1.Name),
                spreadsheet?.getActiveSheet()!
              );
              let cellValue = spreadsheet!.getDisplayText(cell);
              if (item1.Required && (cellValue == null || cellValue == "" || cellValue == undefined)) {
                errorRows.push(item1.Name + ' is mandatory. Please enter a value.');
              }
              newProp = "";
              if (cellValue != null && cellValue != undefined) {
                if (propDataType == 13) {
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Value: cellValue,
                    },
                  };
                }
                if (propDataType == 1) {
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Value: cellValue,
                    },
                  };
                }
                if (propDataType == 2) {
                  //integer
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Value: cellValue,
                    },
                  };
                }
                if (propDataType == 8) {
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Value: cellValue,
                    },
                  };
                }
                if (propDataType === 5) {
                  if (propName == "Last Modified") {
                    cellValue = new Date().toISOString();
                  }
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Value: cellValue,
                    },
                  };
                }

                if (propDataType === 7) {
                  if (propName == "Last Modified") {
                    cellValue = new Date().toISOString();
                  }
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Value: cellValue,
                    },
                  };
                }
                if (propDataType == 9) {
                  var items = singleselectProperties.find(
                    (x) => x.Id == propId
                  )?.DataArray;
                  var clsId = singleselectProperties.find(
                    (x) => x.Id == propId
                  )?.ClsId;
                  //var hasLookup = singleselectProperties.find(x => x.Id == propId)?.HasLookup;
                  var lkupId = lookupIds.find((x) => x.clsId == clsId)?.lkupId;

                  if (items == null) {
                    return;
                  }
                  if (items != null) {
                    if (clsId == -1) {
                      item = items.find((x) => x.Name == cellValue)?.ID;
                    } else {
                      if (lkupId !== undefined)
                        item = items.find((x) => x.LookupId == cellValue)
                          ?.ObjVer.ID;
                      else
                        item = items.find((x) => x.Title == cellValue)?.ObjVer
                          .ID;
                    }
                  }
                  if (item === undefined) return;
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Lookup: {
                        Item: item,
                      },
                    },
                  };
                }
                if (propDataType == 10) {
                  var item;
                  var lookupArray: any[] = [];
                  var items = multiselectProperties.find(
                    (x) => x.Id == propId
                  )?.DataArray;
                  var clsId = multiselectProperties.find(
                    (x) => x.Id == propId
                  )?.ClsId;
                  var ismultipleClass = multiselectProperties.find(
                    (x) => x.Id == propId
                  )?.IsMultipleClass;

                  var tmpclsId;
                  if (ismultipleClass && (!Number.isInteger(clsId))) {
                    var temp = clsId?.split(",");
                    tmpclsId = temp[0];
                  }
                  else
                    tmpclsId = clsId;
                  var lkupId = lookupIds.find((x) => x.clsId === Number(tmpclsId))?.lkupId;


                  if (items == null) {
                    return;
                  }
                  var array = cellValue!.split(";");
                  for (var j = 0; j < array!.length; j++) {
                    if (items != null) {
                      if (clsId == -1) {
                        item = items.find((x) => x.Name == array[j].trim())?.ID;
                      } else {
                        if (lkupId !== undefined) {
                          item = items.find(
                            (x) => x.LookupId === array[j].trim()
                          )?.ObjVer.ID;
                        } else {
                          item = items.find((x) => x.Title == array[j].trim())
                            ?.ObjVer.ID;
                        }
                      }
                    }
                    if (item === undefined) continue;
                    let Lookup = {
                      Item: item,
                      Version: -1,
                    };
                    lookupArray.push(Lookup);
                  }
                  newProp = {
                    PropertyDef: propId,
                    TypedValue: {
                      DataType: propDataType,
                      Lookups: lookupArray,
                    },
                  };
                }
                text = text + JSON.stringify(newProp) + ",";
                //console.log(newProp);
              }
            }
          }
        });
      } catch (error) {
        console.log("Error : " + error);
      }
      newProp = {
        PropertyDef: 100,
        TypedValue: {
          DataType: 9,
          Lookup: {
            Item: Number(clsId),
          },
        },
      };

      text = text + JSON.stringify(newProp) + "]";
      // if (objType == 0) {
      //   text = text + ',"Files" : [';
      //   let newProp1 = {
      //     UploadID: uploadId,
      //     Title: null,
      //     Extension: "txt",
      //     Size: uploadSize,
      //   };
      //   text = text + JSON.stringify(newProp1) + "]";
      // }
      text = text + "}";
      const url = host + "objects/" + objType;

      const request: RequestInit = {
        method: "POST",
        body: text,
      };
      const response1 = await RunAPI(url, request);
      const data1 = await response1.json();
      // console.log(data1);
      // console.log(data1?.ObjVer?.ID);

      if (data1 != null) {
        if (data1.Status !== undefined) {
          //error occured
          if (columnHeaders.indexOf("Updated Message") !== -1)
            spreadsheet?.updateCell(
              {
                value: data1.Message,
                style: { fontFamily: "Calibri", fontSize: "9pt" },
              },
              getCellAddress(rIndex, columnHeaders.indexOf("Updated Message"))
            );
        } else {
          if (data1?.ObjVer?.ID > 0) {
            if (columnHeaders.indexOf("Modified") !== -1)
              spreadsheet?.updateCell(
                {
                  value: "False",
                  style: { fontFamily: "Calibri", fontSize: "9pt" },
                },
                getCellAddress(rIndex, columnHeaders.indexOf("Modified"))
              );
            if (columnHeaders.indexOf("Update Status") !== -1)
              spreadsheet?.updateCell(
                {
                  value: "True",
                  style: { fontFamily: "Calibri", fontSize: "9pt" },
                },
                getCellAddress(rIndex, columnHeaders.indexOf("Update Status"))
              );
            if (columnHeaders.indexOf("GUID") !== -1)
              spreadsheet?.updateCell(
                {
                  value: data1.ObjectGUID,
                  style: { fontFamily: "Calibri", fontSize: "9pt" },
                },
                getCellAddress(rIndex, columnHeaders.indexOf("GUID"))
              );
            if (columnHeaders.indexOf("Updated Message") !== -1)
              spreadsheet?.updateCell(
                {
                  value: "Added Successfully",
                  style: { fontFamily: "Calibri", fontSize: "9pt" },
                },
                getCellAddress(rIndex, columnHeaders.indexOf("Updated Message"))
              );
            ReloadData(rIndex, data1.ObjVer.ID, data1.ObjectGUID, sheetName);
            spreadsheet?.goTo(
              getCellAddress(0, columnHeaders.indexOf("Updated Message"))
            );
          }
        }
      }
    }
    spreadsheet?.hideSpinner();
    if (errorRows.length > 0) {
      dialogInstance14?.show();
    }
  }

  async function GetObjectProperties(rowIndex, sheetIndex) {
    //console.log('GetObjectProperties called');
    let spreadsheet = SSObj.current;
    await GetColumnHeaders(sheetIndex);
    let clsId = getCell(
      rowIndex,
      columnHeaders.indexOf("Object Class"),
      spreadsheet!.getActiveSheet()
    )?.value;
    let sheetName = spreadsheet!.getActiveSheet().name;
    usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex;
    let lastEmptyRow = getLastEmptyRow();
    if (clsId === undefined) return;
    await IsLookupIdDefined(clsId);
    if (clsId === "-1") return;
    let url = host + "/structure/classes/" + clsId;
    const response = await RunAPI(url, request);
    const data = await response?.json();
    //console.log(data);
    data?.AssociatedPropertyDefs?.forEach((element: any) => {
      let url1 = host + "/structure/properties/" + element.PropertyDef;
      RunAPI(url1, request).then((response1) => {
        response1?.json().then((prop) => {
          //console.log(prop);
          if (existingpropIds.find((x) => x.Id == prop.ID && x.SheetName == sheetName) == null) {
            existingpropIds.push({
              Name: prop.Name,
              Id: prop.ID,
              Required: element.Required,
              AutomaticValueType: prop.AutomaticValueType,
              DataType: prop.DataType,
              DepPD: prop.DependencyPD,
              SheetName: sheetName,
            });
          }

          if (element.Required && columnHeaders.indexOf(prop.Name) !== -1) {
            var index = columnHeaders.indexOf(prop.Name);
            let colHeaderText = getColumnHeaderText(index + 1);
            spreadsheet?.cellFormat(
              {
                fontSize: "9pt",
                fontFamily: "Calibri",
                // textAlign: "center",            
                backgroundColor: "Gray",
              },
              sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + lastEmptyRow
            );
          }
          if (prop.AutomaticValueType != 0 && columnHeaders.indexOf(prop.Name) !== -1) {
            var index = columnHeaders.indexOf(prop.Name);
            let colHeaderText = getColumnHeaderText(index + 1);
            spreadsheet?.cellFormat(
              {
                fontSize: "9pt",
                fontFamily: "Calibri",
                // textAlign: "center",            
                backgroundColor: "#f0f0f0",
                color: "#808080"
              },
              sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + lastEmptyRow
            );
          }
          propId = prop.ID;
          propDataType = prop.DataType;
          ownerId = -1;
          if (propDataType === 9 || propDataType === 10) {
            if (prop.DepPD > 0) {
              let ownerPD = existingpropIds.find(
                (x) => x.Id == prop.DepPD
              ).Name;
              let ownerPDCol = columnHeaders.indexOf(ownerPD);
              if (ownerPDCol !== -1) {
                ownerPDValue = getCell(
                  currentRow,
                  ownerPDCol,
                  spreadsheet!.getActiveSheet()
                ).value;
                GetOwnerId(prop.DepPD, ownerPDValue);
              }
            }
            GetDataList(prop.Name, propId, propDataType, ownerId, prop.AutomaticValueType, true, 0);
          }
          if (PropertyHeaders.find((x) => x.Title == prop.Name) == null) {
            PropertyHeaders.push({ Title: prop.Name });
          }
          if (
            reorderColumnHeaders.find(
              (x) => x.Title === prop.Name && x.SheetName === sheetName
            ) == null
          ) {
            if (columnHeaders.indexOf(prop.Name) !== -1) {
              reorderColumnHeaders.push({
                Title: prop.Name,
                IsAdmin: false,
                Visible: true,
                SheetName: sheetName,
                PropertyLabel: '-'
              });
            } else {
              reorderColumnHeaders.push({
                Title: prop.Name,
                IsAdmin: false,
                Visible: false,
                SheetName: sheetName,
                PropertyLabel: '-'
              });
            }
          }
        });
      });
    });
    // MarkObligatoryProperties(false);
  }

  function CheckForMandatoryValues(data: any, sheetName: any) {
    var isDirty: boolean = false;
    //check for value in mandatory property
    data?.forEach((element: any) => {
      var item;
      var tmpProp = existingpropIds.find((x) => x.Id == element.PropertyDef && x.SheetName == sheetName);
      if (tmpProp === undefined || tmpProp === null) {
        return;
      }
      else {
        item = existingpropIds.find((x) => x.Id == element.PropertyDef && x.SheetName == sheetName);
      }
      console.log('ssss');
      console.log(item.Id);
      console.log(item.Required);
      console.log(element.TypedValue.DisplayValue);
      if (item.Required && (element.TypedValue.DisplayValue == "" || element.TypedValue.DisplayValue == null || element.TypedValue.DisplayValue == undefined)) {
        isDirty = true;
        errorRows.push(item.Name + ' is mandatory. Please enter a value.');
      }
    });
    return isDirty;
  }

  async function UpdateData() {
    let spreadsheet = SSObj.current;
    const request1: RequestInit = {
      headers: {
        Authorization: "Bearer " + token,
        "X-Vault": vaultId,
      },
    };
    spreadsheet?.showSpinner();

    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    for (let i = 0; i < modifiedRows.length; i++) {
      rowIndex = modifiedRows[i];
      //console.log(rowIndex);
      let item = memoryData && memoryData[rowIndex - 1];
      let objTypeId = item && item["Object Type"];
      let objId = item["ID"];
      let url =
        host +
        "objects/" +
        objTypeId +
        "/" +
        objId +
        "/latest/properties?forDisplay=true";
      const response = await RunAPI(url, request1);
      if (response === undefined) {
        spreadsheet?.hideSpinner();
        return;
      }
      const data = await response.json();
      //RunAPI(url, request1).then((response) => {
      //response.json().then((data) => {
      //console.log(data);

      if (data.Status !== 404) {
        //existingpropIds?.forEach((item1: any) => {
        if (CheckForMandatoryValues(data, sheetName)) {
          spreadsheet?.hideSpinner();
          console.log('error');
          continue;
        }
        //if (item1?.SheetName === sheetName) {
        data?.forEach((element: any) => {
          console.log(element.PropertyDef);
          var item1;
          var tmpProp = existingpropIds.find((x) => x.Id == element.PropertyDef && x.SheetName == sheetName);
          if (tmpProp === undefined || tmpProp === null) {
            return;
          }
          else {
            item1 = existingpropIds.find((x) => x.Id == element.PropertyDef && x.SheetName == sheetName);
          }

          if (columnHeaders.indexOf(item1.Name) == -1) {
            return;
          }

          let cell = getCell(
            rowIndex,
            columnHeaders.indexOf(item1.Name),
            spreadsheet?.getActiveSheet()!
          );
          let cellValue = spreadsheet!.getDisplayText(cell);
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 1
          ) {
            if (cellValue == null || cellValue == undefined) {
              element.TypedValue.DisplayValue = undefined;
            } else {
              if (cellValue != element.TypedValue.DisplayValue) {
                element.TypedValue.DisplayValue = cellValue;
                element.TypedValue.Value = cellValue;
              }
            }
          }
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 2
          ) {
            if (cellValue == null || cellValue == undefined) {
              element.TypedValue.DisplayValue = undefined;
            } else {
              if (cellValue != element.TypedValue.DisplayValue) {
                element.TypedValue.DisplayValue = cellValue;
                element.TypedValue.Value = cellValue;
              }
            }
          }
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 5
          ) {
            if (cellValue == null || cellValue == undefined) {
              element.TypedValue.DisplayValue = undefined;
            } else {
              if (cellValue != element.TypedValue.DisplayValue) {
                element.TypedValue.DisplayValue = cellValue;
                element.TypedValue.Value = cellValue;
              }
            }
          }
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 7
          ) {
            if (cellValue == null || cellValue == undefined) {
              element.TypedValue.DisplayValue = undefined;
            } else {
              if (cellValue != element.TypedValue.DisplayValue) {
                element.TypedValue.DisplayValue = cellValue;
                element.TypedValue.Value = cellValue;
              }
            }
          }
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 13
          ) {
            if (cellValue == null || cellValue == undefined) {
              element.TypedValue.DisplayValue = undefined;
            } else {
              if (cellValue != element.TypedValue.DisplayValue) {
                element.TypedValue.DisplayValue = cellValue;
                element.TypedValue.Value = cellValue;
              }
            }
          }
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 9
          ) {
            var items = singleselectProperties.find(
              (x) => x.Id == item1.Id
            )?.DataArray;
            var clsId = singleselectProperties.find(
              (x) => x.Id == item1.Id
            )?.ClsId;
            var ismultipleClass = singleselectProperties.find(
              (x) => x.Id == item1.Id
            )?.IsMultipleClass;
            if (ismultipleClass && (!Number.isInteger(clsId))) {
              var temp = clsId?.split(",");
              tmpclsId = temp[0];
            }
            else
              tmpclsId = clsId;
            var lkupId = lookupIds.find((x) => x.clsId === Number(tmpclsId))?.lkupId;

            if (items == null) {
              return;
            }

            if (items != null) {
              if (clsId == -1) {
                item = items.find((x) => x.Name == cellValue)?.ID;
              } else {
                if (lkupId !== undefined)
                  item = items.find((x) => x.LookupId == cellValue)?.ObjVer
                    .ID;
                else
                  item = items.find((x) => x.Title == cellValue)?.ObjVer.ID;
              }
            }

            if (item == null || item == undefined) {
              element.TypedValue.Lookup = undefined;
            } else {
              if (element.TypedValue.DisplayValue == "") {
                element.TypedValue.Lookup = {
                  Item: item,
                };
              } else {
                if (item != element.TypedValue.Lookup?.Item) {
                  element.TypedValue.Lookup.Item = item;
                }
              }
            }
          }
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 10
          ) {
            var item;
            var items = multiselectProperties.find(
              (x) => x.Id == item1.Id
            )?.DataArray;
            var clsId = multiselectProperties.find(
              (x) => x.Id == item1.Id
            )?.ClsId;
            var ismultipleClass = multiselectProperties.find(
              (x) => x.Id == item1.Id
            )?.IsMultipleClass;

            var tmpclsId;
            console.log(clsId);
            console.log(multiselectProperties);
            if (ismultipleClass && (!Number.isInteger(clsId))) {
              var temp = clsId?.split(",");
              tmpclsId = temp[0];
            }
            else
              tmpclsId = clsId;
            var lkupId = lookupIds.find((x) => x.clsId === Number(tmpclsId))?.lkupId;

            if (items == null) {
              return;
            }
            if (cellValue == element.TypedValue.DisplayValue) {
              return;
            }
            if (
              cellValue == null ||
              cellValue == undefined ||
              cellValue == ""
            ) {
              element.TypedValue.Lookups = undefined;
            } else {
              element.TypedValue.Lookups = [];
              var array = cellValue!.split(";");
              for (var i = 0; i < array!.length; i++) {
                //console.log(array[i]);
                // console.log(items);
                if (items != null) {
                  if (clsId == -1) {
                    item = items.find((x) => x.Name == array[i])?.ID;
                  } else {
                    if (lkupId !== undefined)
                      item = items.find(
                        (x) => x.LookupId == array[i].trim()
                      )?.ObjVer.ID;
                    else
                      item = items.find((x) => x.Title == array[i].trim())
                        ?.ObjVer.ID;
                  }
                }
                if (item === undefined) continue;
                let Lookup = {
                  Item: item,
                  Version: -1,
                };
                element.TypedValue.Lookups.unshift(Lookup);
              }
            }
          }
          if (
            element.PropertyDef == item1.Id &&
            element.TypedValue.DataType == 8
          ) {
            if (cellValue == null || cellValue == undefined) {
              element.TypedValue.DisplayValue = undefined;
            } else {
              if (cellValue != element.TypedValue.DisplayValue) {
                element.TypedValue.DisplayValue = cellValue;
                let boolOutput = cellValue === "True"; //returns true
                element.TypedValue.Value = boolOutput;
              }
            }
          }
        });

        //}
        // });

        console.log(data);
        //remove class
        data?.forEach((element: any) => {
          if (element.PropertyDef == 100) {
            const index = data.indexOf(element);
            data.splice(index, 1);
            return;
          }
        });

        //completed updating the data
        //post it back to m-files api to update the object
        url =
          host + "objects/" + objTypeId + "/" + objId + "/latest/properties";
        const request: RequestInit = {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "X-Vault": vaultId,
          },
        };
        const response1 = await RunAPI(url, request);
        const data1 = await response1.json();

        if (data1.Status == undefined) {
          //update the modified column back to FALSE and Updated column to True
          if (columnHeaders.indexOf("Modified") !== -1)
            spreadsheet?.updateCell(
              {
                value: "False",
                style: { fontFamily: "Calibri", fontSize: "9pt" },
              },
              getCellAddress(rowIndex, columnHeaders.indexOf("Modified"))
            );
          if (columnHeaders.indexOf("Update Status") !== -1)
            spreadsheet?.updateCell(
              {
                value: "True",
                style: { fontFamily: "Calibri", fontSize: "9pt" },
              },
              getCellAddress(rowIndex, columnHeaders.indexOf("Update Status"))
            );
          if (columnHeaders.indexOf("Updated Message") !== -1)
            spreadsheet?.updateCell(
              {
                value: "Updated Successfully",
                style: { fontFamily: "Calibri", fontSize: "9pt" },
              },
              getCellAddress(rowIndex, columnHeaders.indexOf("Updated Message"))
            );
          if (columnHeaders.indexOf("GUID") !== -1)
            spreadsheet?.updateCell(
              { value: data1.ObjectGUID },
              getCellAddress(rowIndex, columnHeaders.indexOf("GUID"))
            );
          if (columnHeaders.indexOf("ID") !== -1)
            spreadsheet?.updateCell(
              { value: data1.ObjVer.ID },
              getCellAddress(rowIndex, columnHeaders.indexOf("ID"))
            );
          ReloadDataInMemory(rowIndex);
          //console.log(memoryData);
        } else {
          if (columnHeaders.indexOf("Updated Message") !== -1)
            spreadsheet?.updateCell(
              {
                value: data1.Message,
                style: { fontFamily: "Calibri", fontSize: "9pt" },
              },
              getCellAddress(rowIndex, columnHeaders.indexOf("Updated Message"))
            );
        }
      } else {
        if (columnHeaders.indexOf("Updated Message") !== -1)
          spreadsheet?.updateCell(
            {
              value: "Object Not Found",
              style: { fontFamily: "Calibri", fontSize: "9pt" },
            },
            getCellAddress(rowIndex, columnHeaders.indexOf("Updated Message"))
          );
      }
    }
    spreadsheet?.goTo(
      getCellAddress(0, columnHeaders.indexOf("Updated Message"))
    );
    spreadsheet?.hideSpinner();
    if (errorRows.length > 0) {
      dialogInstance14?.show();
    }
  }

  function ReloadDataInMemory(rIndex: any) {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    let row = memoryData && memoryData[rIndex - 1];
    row["Modified"] = "FALSE";
    if (row !== undefined) {
      let url =
        host +
        "objects/" +
        row["Object Type"] +
        "/" +
        row["ID"] +
        "/latest/properties?forDisplay=true";
      RunAPI(url, request).then((response) => {
        response?.json().then((data) => {
          data?.forEach((element: any) => {
            let propName = existingpropIds.find(
              (t) => t.Id === element.PropertyDef
            )?.Name;
            let propValue = element.TypedValue?.DisplayValue;
            if (propName !== undefined) row[propName] = propValue;
          });
        });
      });
    }
  }

  function ReloadData(rIndex: any, objectId: any, guid: any, sheetName: any) {
    let spreadsheet = SSObj.current;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    let rowIndex = rIndex;
    let objId = objectId;
    let obj1 = memoryData && memoryData[rIndex - 1];
    const url =
      host +
      "objects/" +
      g_obj_type_id +
      "/" +
      objId +
      "/latest/properties?forDisplay=true";
    RunAPI(url, request).then((response) => {
      response?.json().then((data) => {
        data.forEach(function (element, i) {
          let propName = existingpropIds.find(
            (t) => t.Id === element.PropertyDef
          )?.Name;
          let propDataType = existingpropIds.find(
            (t) => t.Id === element.PropertyDef
          )?.DataType;
          let propValue = element.TypedValue?.DisplayValue;
          let colIndex = columnHeaders.indexOf(propName);
          if (colIndex !== -1 && propName !== undefined) {
            spreadsheet!.updateCell(
              {
                value: propValue,
                style: { fontFamily: "Calibri", fontSize: "9pt" },
              },
              sheetName +
              "!" +
              getCellAddress(rowIndex, columnHeaders.indexOf(propName))
            );
            obj1[propName] = propValue;
          }
        });
      });
    });
    obj1["ID"] = objId?.toString();
    obj1["Object Type"] = g_obj_type_id?.toString();
    obj1["Object Class"] = g_cls_id?.toString();
    obj1["Modified"] = "FALSE";
    obj1["Update Status"] = "FALSE";
    obj1["Updated Message"] = "";
    obj1["GUID"] = guid;
    obj1["Class Name"] = g_cls_name;
    obj1["Object Name"] = g_obj_type_name;
    // memoryData.push(obj1);
    //console.log(memoryData);
    if (columnHeaders.indexOf("ID") !== -1)
      spreadsheet?.updateCell(
        { value: objId, style: { fontFamily: "Calibri", fontSize: "9pt" } },
        getCellAddress(rowIndex, columnHeaders.indexOf("ID"))
      );
    if (columnHeaders.indexOf("DesktopView") == -1) {
      return;
    }
    //update the edit link
    const url1 = host + "objects/" + g_obj_type_id + "/" + objId;
    RunAPI(url1, request).then((response) => {
      response?.json().then((data) => {
        let latestVersion = data.ObjVer.Version;
        if (columnHeaders.indexOf("DesktopView") !== -1) {
          let editLink = getCell(
            rowIndex,
            columnHeaders.indexOf("DesktopView"),
            spreadsheet!.getActiveSheet()
          ).value as string;
          if (editLink != undefined) {
            let result = editLink.indexOf("?");
            let string1 = editLink.substr(result - 1, 2);
            let updatedLink = editLink.replace(
              string1,
              latestVersion.toString() + "?"
            );
            //console.log(updatedLink);
            spreadsheet?.updateCell(
              { value: updatedLink },
              getCellAddress(rowIndex, columnHeaders.indexOf("DesktopView"))
            );
          }
        }
      });
    });
  }

  function compareStrings(a, b) {
    a = a?.toLowerCase();
    b = b?.toLowerCase();
    if (a === undefined || a === "") {
      a = "";
    }
    if (b === undefined || b === "") {
      b = "";
    }
    return a < b ? -1 : a > b ? 1 : 0;


  }

  function compareStringsDesc(a, b) {
    a = a?.toLowerCase();
    b = b?.toLowerCase();
    if (a === undefined || a === "") {
      a = "";
    }
    if (b === undefined || b === "") {
      b = "";
    }
    return a < b ? 1 : a > b ? -1 : 0;
  }

  function compareNumbers(a, b) {
    return a - b;
  }

  function compareNumbersDesc(a, b) {
    return b - a;
  }

  function compareDates(a, b) {
    return a - b;
  }

  function compareDatesDesc(a, b) {
    return b - a;
  }

  async function TransformToView() {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    if (transformedSheet.indexOf(sheetName) !== -1){
      spreadsheet?.enableToolbarItems("M-Files", [2], false);
      return;
    } 
    spreadsheet?.showSpinner();
    const url = host + "/structure/objecttypes?type=real";
    const response = await RunAPI(url, request);
    const data = await response.json();
    data.sort(function (a, b) {
      return compareStrings(a.Name, b.Name);
    });
    vaultObjects = data;
    dropDownListVaultObject.fields = list1;
    dropDownListVaultObject.dataSource = data;
    if (adminColData.find((x) => x.SheetName === sheetName) !== undefined) {
      var tmp = adminColData.find((x) => x.SheetName === sheetName);
      dropDownListVaultObject.text = tmp.ObjType;
    }
    //Code to identify unique identifier column for particular sheet..
    //If LookupId column is not present in the sheet
    var currentColHeaders = reorderColumnHeaders.filter(
      (x) => x.SheetName === sheetName
    );

    if (currentColHeaders.find((x) => x.Title === "Lookup ID") === undefined) {
      dropDownListUniqueProperty.enabled = true;
    } else dropDownListUniqueProperty.enabled = false;

    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    spreadsheet?.hideSpinner();
    dialogInstance9.show();
  }

  const onObjectChange = (args: any) => {
    if (args.itemData === null) return;
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    var objTypeId = args.itemData.ID;
    const url1 = host + "structure/classes?objtype=" + objTypeId;
    RunAPI(url1, request).then((response) => {
      response?.json().then((data) => {
        data.sort(function (a, b) {
          return compareStrings(a.Name, b.Name);
        });
        vaultClasses = data;
        dropDownListVaultClass.fields = list1;
        dropDownListVaultClass.dataSource = data;
        if (adminColData.find((x) => x.SheetName === sheetName) !== undefined) {
          var tmp = adminColData.find((x) => x.SheetName === sheetName);
          dropDownListVaultClass.text = tmp.Cls;
        }
      });
    });
  };

  const onObjectChange1 = (args: any) => {
    if (args.itemData === null) return;
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    var objTypeId = args.itemData.ID;
    const url1 = host + "structure/classes?objtype=" + objTypeId;
    RunAPI(url1, request).then((response) => {
      response?.json().then((data) => {
        data.sort(function (a, b) {
          return compareStrings(a.Name, b.Name);
        });
        vaultClasses = data;
        dropDownListVaultClass1.fields = list1;
        dropDownListVaultClass1.dataSource = data;
        if (adminColData.find((x) => x.SheetName === sheetName) !== undefined) {
          var tmp = adminColData.find((x) => x.SheetName === sheetName);
          dropDownListVaultClass1.text = tmp.Cls;
        }
      });
    });
  };

  async function onClassChange(args: any) {
    var properties: any[] = [];
    var list1 = { text: "Name", value: "Id" };
    var clsId = args.itemData?.ID;
    if (clsId === undefined) return;
    IsLookupIdDefined(clsId);
    let url = host + "/structure/classes/" + clsId;
    const response = await RunAPI(url, request);
    const data = await response.json();
    let totalPropCount = data?.AssociatedPropertyDefs?.length;
    let ind = 0;
    data?.AssociatedPropertyDefs.forEach(async (element: any) => {
      ind++;
      let url1 = host + "/structure/properties/" + element.PropertyDef;
      const response1 = await RunAPI(url1, request);
      const prop = await response1.json();
      if (properties.find((x) => x.Id === prop.ID) == null) {
        properties.push({ Name: prop.Name, Id: prop.ID });
      }
      if (ind === totalPropCount) {
        properties.sort(function (a, b) {
          return compareStrings(a.Name, b.Name);
        });
      }
    });

    dropDownListUniqueProperty.dataSource = properties;
    dropDownListUniqueProperty.fields = list1;
    dropDownColHeaderObject.dataSource = properties;
    dropDownColHeaderObject.fields = list1;
  }

  async function SaveAsObject() {
    let spreadsheet = SSObj.current;
    errorRows = [];
    modifiedRows = [];
    newRows = [];
    usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex;
    usedColIdx = spreadsheet?.getActiveSheet().usedRange?.colIndex;
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    console.log(memoryData);

    memoryData?.forEach(function (row, index) {
      if (row["ID"] == "-1") {
        newRows.push(index + 1);
      } else {
        if (row["Modified"] === "True") {
          modifiedRows.push(index + 1);
        }
      }
    });
    if (newRows.length > 0) {
      await CreateData();
    }
    if (modifiedRows.length > 0) {
      await UpdateData();
    }
  }

  const onBeforeSave = (args: BeforeSaveEventArgs) => {
    args.isFullPost = false;
    if (IsFileSave) return;
  };

  function onSelect(row: any, col: any) {
    let spreadsheet = SSObj.current;
    let currentSheetName = spreadsheet?.getActiveSheet().name;
    dropDownListObject!.value = null;
    multiSelectListObject!.value = null;

    currentRow = row;
    currentCol = col;
    if (currentRow === 0) {
      const url = host + "/structure/objecttypes?type=real";
      RunAPI(url, request).then((response) => {
        response?.json().then((data) => {
          data.sort(function (a, b) {
            return compareStrings(a.Name, b.Name);
          });
          dropDownListVaultObject1.fields = list1;
          dropDownListVaultObject1.dataSource = data;
          if (
            adminColData.find((x) => x.SheetName === currentSheetName) !==
            undefined
          ) {
            var tmp1 = adminColData.find(
              (x) => x.SheetName === currentSheetName
            );
            dropDownListVaultObject1.text = tmp1?.ObjType;
          }
        });
      });

      memoryData = sheetMemoryData.find(
        (x) => x.SheetName === currentSheetName
      )?.MemData;
      if (memoryData === undefined) {
        //sheet not yet transformed and class not yet defined
      } else {
        var properties: any[] = [];
        var item = memoryData && memoryData[0];
        g_cls_id = item && item["Object Class"];
        if (g_cls_id == -1) return;
        let url = host + "/structure/classes/" + g_cls_id;
        RunAPI(url, request).then((response) => {
          response?.json().then((data) => {
            let totalPropCount = data?.AssociatedPropertyDefs?.length;
            let ind = 0;
            data?.AssociatedPropertyDefs.forEach((element: any) => {
              ind++;
              let url1 = host + "/structure/properties/" + element.PropertyDef;
              RunAPI(url1, request).then((response1) => {
                response1?.json().then((prop) => {
                  if (properties.find((x) => x.Id === prop.ID) == null) {
                    properties.push({ Name: prop.Name, Id: prop.ID });
                  }
                  if (ind === totalPropCount) {
                    properties.sort(function (a, b) {
                      return compareStrings(a.Name, b.Name);
                    });
                  }
                });
              });
            });
          });
        });

        dropDownColHeaderObject.dataSource = properties;
        dropDownColHeaderObject.fields = { text: "Name", value: "Id" };
      }
      dialogInstance10.show();
      return;
    }
    //GetObjectProperties(range[0]);

    let cell1: CellModel = getCell(
      0,
      currentCol,
      spreadsheet!.getActiveSheet()
    ); // to get the column name
    let colName = cell1?.value;
    let cell2: CellModel = getCell(
      currentRow,
      currentCol,
      spreadsheet!.getActiveSheet()
    );
    let cellValue = spreadsheet!.getDisplayText(cell2);
    //console.log(cell2);
    //let cellValue = cell2?.value;
    // console.log(existingpropIds.length);
    let prop = existingpropIds.find((x) => x.Name === colName);
    if ((prop == undefined && currentCol != 0) || prop == undefined) {
      //if the column is not a property column
      //spreadsheet!.updateCell({ value: "Property " + colName + " does not belong to the object class" }, getCellAddress(range[0], columnHeaders.indexOf("UpdatedMessage")));
      return;
    }
    propId = prop.Id;
    propDataType = prop.DataType;
    var item = memoryData && memoryData[currentRow - 1];
    selectedGUID = item && item["GUID"];
    if (propDataType === 9 || propDataType === 10) {
      LoadDataInDropDown(prop.Name, propId, propDataType, true);
    } else if (propDataType === 7) {//Timestamp
      dateTimePickerObject.value = cellValue;
      dialogInstance2.show();
    } else if (propDataType === 5) {//date
      datePickerObject.value = cellValue;
      dialogInstance11.show();
    } else if (propDataType === 8) {
      booleandropdownData = [];
      booleandropdownData.push({ Title: "False", Id: 1 });
      booleandropdownData.push({ Title: "True", Id: 2 });
      dropDownListBooleanObject.fields = { text: "Title", value: "Id" };
      dropDownListBooleanObject.dataSource = booleandropdownData;
      dialogInstance4.header = colName + " List";
      dialogInstance4.show();
    }
  }

  const GetDataList = (
    propName: string,
    propId: number,
    propDataType: number,
    ownerId: any,
    isAutomatic: boolean,
    loadAll: boolean, clsId: any = 0
  ) => {
    const url = host + "/structure/properties/" + propId;
    RunAPI(url, request).then((response) => {
      response?.json().then((data) => {
        objTypeId = data.ValueList;
        if (data.HasStaticFilter === true) {
          data.StaticFilters.forEach((item: any) => {
            if (item.Expression.DataPropertyValuePropertyDef === 100) {
              if (
                item.TypedValue.DataType === 9 &&
                item.TypedValue.Lookup !== undefined
              ) {
                IsMultipleClass = false;
                clsId = item.TypedValue.Lookup.Item;
              } else if (item.TypedValue.DataType === 10) {
                if (item.TypedValue.Lookups !== undefined) {
                  clsId = "";
                  IsMultipleClass = true;
                  item.TypedValue.Lookups.forEach((lkup: any) => {
                    clsId += lkup.Item + ",";
                  });
                  clsId = clsId.slice(0, -1);
                } else if (item.TypedValue.Lookup !== undefined) {
                  IsMultipleClass = false;
                  clsId = item.TypedValue.Lookup.Item;
                }
              }
            }
          });
        }
        var tmpclsId;
        if (IsMultipleClass && clsId !== undefined && !(Number.isInteger(clsId))) {
          var temp = clsId!.split(",");
          tmpclsId = temp[0];
        } else tmpclsId = clsId;
        if (tmpclsId !== undefined && tmpclsId !== -1)
          IsLookupIdDefined(Number(tmpclsId));
        //to find if its a class object or valuelist
        GetObjType(
          propName,
          propId,
          clsId,
          data,
          propDataType,
          ownerId,
          loadAll,
          IsMultipleClass,
          isAutomatic
        );
      });
    });
  };

  const GetObjType = (
    propName: string,
    propId: number,
    clsId: any,
    data: any,
    propDataType: number,
    ownerId: any,
    loadAll: boolean,
    IsMultipleClass: boolean,
    isAutomatic: boolean
  ) => {

    const url = host + "/structure/objecttypes/" + data.ValueList;
    RunAPI(url, request).then((response) => {
      response.json().then(async (data1) => {
        if (data1.RealObjectType === true) {
          objTypeId = data.ValueList;
          GetClassId(
            propName,
            propId,
            clsId,
            data.ValueList,
            propDataType,
            ownerId,
            loadAll,
            IsMultipleClass,
            isAutomatic
          );
        } else {
          //console.log("Its a valuelist");
          objTypeId = data.ValueList;
          clsId = -1;
          GetData(
            propName,
            propId,
            clsId,
            objTypeId,
            propDataType,
            ownerId,
            loadAll,
            IsMultipleClass,
            false,
            isAutomatic
          );
        }
      });
    });
  };

  async function GetOwnerId(propId: any, propValue: any) {
    const url = host + "/structure/properties/" + propId;
    RunAPI(url, request).then((response) => {
      response?.json().then((data) => {
        const url2 = host + "valuelists/" + data.ValueList + "/items";
        RunAPI(url2, request).then((response1) => {
          response1.json().then((data1) => {
            ownerId = data1.Items.find((x) => x.Name == propValue).ID;
            //console.log(ownerId);
          });
        });
      });
    });
  }

  const GetClassId = (
    propName: string,
    propId: number,
    clsId: any,
    objTypeId: any,
    propDataType: number,
    ownerId: any,
    loadAll: boolean,
    IsMultipleClass: boolean,
    isAutomatic: boolean
  ) => {
    const url1 = host + "structure/classes?objtype=" + objTypeId;
    RunAPI(url1, request).then((response) => {
      response?.json().then((classes) => {
        if (classes.length > 1) {
        } else {
          clsId = classes[0].ID;
          if (clsId !== undefined) IsLookupIdDefined(clsId);
        }
        GetData(
          propName,
          propId,
          clsId,
          objTypeId,
          propDataType,
          ownerId,
          loadAll,
          IsMultipleClass, false,
          isAutomatic

        );
      });
    });
  };

  async function GetData(
    propName: string,
    propId: number,
    clsId: any,
    objTypeId: any,
    propDataType: number,
    ownerId: any,
    loadAll: boolean,
    IsMultipleClass: boolean, IsRefresh: boolean,
    isAutomatic: boolean
  ) {
    var url2, list, haslkup, tmpclsId;
    if (clsId == -1) {
      //valuelist
      tmpclsId = clsId;
      url2 = host + "valuelists/" + objTypeId + "/items";
      if (propDataType == 9) {
        list = { text: "Name", value: "ID" };
      }
      if (propDataType == 10) {
        list = { text: "Name", value: "Name" };
      }
    } else {
      if (clsId == 0)//list but class not defined
      {
        url2 = host + "objects/" + objTypeId;
      }
      else {
        url2 = host + "objects/" + objTypeId + "?p100=" + clsId;
      }
      if (propDataType == 9) {
        list = { text: "Title", value: "ObjVer.ID" };
      }
      if (propDataType == 10) {
        list = { text: "Title", value: "Title" };
      }
      //find out whether the class has Lookup ID property
      //get the property id
      //console.log(IsMultipleClass);
      if (IsMultipleClass && !Number.isInteger(clsId)) {
        var temp = clsId?.split(",");
        tmpclsId = temp[0];
      } else tmpclsId = clsId;
      var lkupId = lookupIds.find((x) => x.clsId === Number(tmpclsId))?.lkupId;
      if (lkupId == undefined) haslkup = false;
      else haslkup = true;
    }
    RunAPI(url2, request).then((response) => {
      response?.json().then((data) => {
        //console.log(data);
        data?.Items?.sort(function (a, b) {
          return compareStrings(a.Title, b.Title);
        });
        if (clsId !== -1 && lkupId !== undefined) {
          data.Items.forEach((element: any) => {
            let url =
              host +
              "objects/" +
              objTypeId +
              "/" +
              element.ObjVer.ID +
              "/latest/properties/" +
              lkupId +
              "?forDisplay=true";
            RunAPI(url, request)
              .then((res) => res?.json())
              .then((data1) => {
                if (data1?.Status !== 404) {
                  element["LookupId"] = data1?.TypedValue?.DisplayValue;
                }
              });
          });
          if (singleselectProperties.find((x) => x.Id == propId)) {
            singleselectProperties.find((x) => x.Id == propId).DataArray =
              data.Items;
          }
          if (multiselectProperties.find((x) => x.Id == propId)) {
            multiselectProperties.find((x) => x.Id == propId).DataArray =
              data.Items;
          }
        }
        //console.log(data.Items);
        if (propDataType == 9) {
          if (ownerId != -1) {
            dropdownData = data.Items.filter((x) => x.OwnerID == ownerId);
          } else {
            dropdownData = data.Items;
          }
          if (singleselectProperties.find((x) => x.Id == propId)) {
            singleselectProperties.find((x) => x.Id == propId).DataArray =
              dropdownData;
          } else {
            singleselectProperties.push({
              Id: propId,
              Name: propName,
              ClsId: tmpclsId,
              ObjTypeId: objTypeId,
              DataArray: dropdownData,
              HasLookup: haslkup,
              IsMultipleClass: IsMultipleClass,
              IsAutomatic: isAutomatic
            });
          }
          if (!loadAll) {
            dropDownListObject.fields = list;
            dropDownListObject.dataSource = dropdownData;
            dialogInstance.header = propName + " List";
            if (!isAutomatic)
              dialogInstance.show();
          }
          if (IsRefresh) {
            dropDownListObject.fields = list;
            dropDownListObject.dataSource = dropdownData;
            dropDownListObject.refresh();
          }
        } else if (propDataType == 10) {
          if (multiselectProperties.find((x) => x.Id == propId)) {
            multiselectProperties.find((x) => x.Id == propId).DataArray =
              data.Items;
          } else {
            multiselectProperties.push({
              Id: propId,
              Name: propName,
              ClsId: clsId,
              ObjTypeId: objTypeId,
              DataArray: data.Items,
              HasLookup: haslkup,
              IsMultipleClass: IsMultipleClass,
              IsAutomatic: isAutomatic
            });
          }
          if (!loadAll) {
            multiSelectData = data.Items;
            multiSelectListObject.fields = list;
            multiSelectListObject.dataSource = multiSelectData;
            dialogInstance1.header = propName + " List";
            if (!isAutomatic)
              dialogInstance1.show();
          }
          if (IsRefresh) {
            multiSelectListObject.fields = list;
            multiSelectListObject.dataSource = multiSelectData;
            multiSelectListObject.refresh();
          }
        }
      });
    });
  }

  function LoadDataInDropDown(propName: any, propId: any, propDataType: any, loadAll: boolean) {
    var list, items, clsId, ismultipleClass, tmpclsId;
    if (propDataType == 9) {
      items = singleselectProperties.find((x) => x.Id == propId)?.DataArray;
      clsId = singleselectProperties.find((x) => x.Id == propId)?.ClsId;
      ismultipleClass = singleselectProperties.find((x) => x.Id == propId)?.IsMultipleClass;
    } else if (propDataType == 10) {
      items = multiselectProperties.find((x) => x.Id == propId)?.DataArray;
      clsId = multiselectProperties.find((x) => x.Id == propId)?.ClsId;
      ismultipleClass = multiselectProperties.find((x) => x.Id == propId)?.IsMultipleClass;
    }

    if (ismultipleClass && clsId !== undefined && !(Number.isInteger(clsId))) {
      var temp = clsId?.split(",");
      tmpclsId = temp[0];
    }
    else
      tmpclsId = clsId;
    var lkupId = lookupIds.find((x) => x.clsId === Number(tmpclsId))?.lkupId;

    if (clsId == -1) {
      //valuelist
      if (propDataType == 9) {
        list = { text: "Name", value: "ID" };
      }
      if (propDataType == 10) {
        list = { text: "Name", value: "Name" };
      }
    } else {
      if (lkupId == undefined) {
        if (propDataType == 9) {
          list = { text: "Title", value: "ObjVer.ID" };
        }
        if (propDataType == 10) {
          list = { text: "Title", value: "Title" };
        }
      } else {
        if (propDataType == 9) {
          list = { text: "Title", value: "LookupId" };
        }
        if (propDataType == 10) {
          list = { text: "Title", value: "LookupId" };
        }
      }
    }

    if (propDataType === 9) {
      propobjTypeId = singleselectProperties.find((x) => x.Id == propId)?.ObjTypeId;
      selectedpropId = propId;
      selectedclsId = singleselectProperties.find((x) => x.Id == propId)?.ClsId;
      dropDownListObject.fields = list;
      dropDownListObject.dataSource = items;
      dialogInstance.header = propName + " List";
      if (loadAll) {
        dialogInstance.show();
      }
    } else if (propDataType === 10) {
      propobjTypeId = multiselectProperties.find((x) => x.Id == propId)?.ObjTypeId;
      selectedclsId = multiselectProperties.find((x) => x.Id == propId)?.ClsId;
      selectedpropId = propId;
      multiSelectData = items;
      multiSelectListObject.fields = list;
      multiSelectListObject.dataSource = multiSelectData;
      dialogInstance1.header = propName + " List";
      if (loadAll) {
        dialogInstance1.show();
      }
    }
  }

  async function IsLookupIdDefined(clsId: any) {
    if (clsId === undefined) return;
    if (clsId === "-1") return;
    let url = host + "/structure/classes/" + clsId;
    RunAPI(url, request)
      .then((res) => res?.json())
      .then((data) => {
        if (data) {
          for (var element of data?.AssociatedPropertyDefs!) {
            let url1 = host + "/structure/properties/" + element.PropertyDef;
            RunAPI(url1, request)
              .then((res1) => res1?.json())
              .then((prop) => {
                if (prop?.Name === "Lookup ID") {
                  if (lookupIds?.find((x) => x.clsId == clsId) === undefined) {
                    lookupIds?.push({ clsId: clsId, lkupId: prop?.ID });
                  }
                  //break;
                }
              });
          }
        }
      });
    // const res = await RunAPI(url, request);
    // const data = await res.json();

    // const response = await RunAPI(url, request);
    // const data = await response?.json();

    //return 2;
  }

  const LetterToNumber = (args: any) => {
    var base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      i,
      j,
      result = 0;
    for (i = 0, j = args?.length - 1; i < args?.length; i += 1, j -= 1) {
      result += Math.pow(base.length, j) * (base.indexOf(args[i]) + 1);
    }
    return result;
  };

  const onBeforeOpen = (args: any) => {
    // IsLoaded = false;
  };

  async function onCheckSelect(args: any) {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex;
    let colNumber = columnHeaders.indexOf(args.itemData.Title);
    if (colNumber === -1) {
      spreadsheet?.showSpinner();
      spreadsheet?.insertColumn(0, 0, 0);
      headers.push(args.itemData.Title);
      mulObj.hidePopup();
      /// dialogInstance3?.hide();
      spreadsheet?.updateCell(
        {
          value: args.itemData.Title,
          style: {
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Calibri",
            fontSize: "10pt",
            backgroundColor: "#D3D3D3",
          },
        },
        getCellAddress(0, 0)
      ); //add column header
      columnHeaders.unshift(args.itemData.Title);
      let propId = existingpropIds.find(
        (t) => t.Name === args.itemData.Title
      )?.Id;
      for (let i = 1; i < usedRowIdx; i++) {
        rowIndex = i;
        let item = memoryData && memoryData[rowIndex - 1];
        let objTypeId = item && item["Object Type"];
        let objId = item["ID"];
        if (objTypeId === undefined || objId === undefined) {
          return;
        }
        let url =
          host +
          "objects/" +
          objTypeId +
          "/" +
          objId +
          "/latest/properties/" +
          propId +
          "?forDisplay=true";
        await RunAPI(url, request)
          .then((res) => res.json())
          .then((data) => {
            spreadsheet?.updateCell(
              {
                value: data.TypedValue.DisplayValue,
                style: { fontFamily: "Calibri", fontSize: "9pt" },
              },
              getCellAddress(i, 0)
            );
            spreadsheet?.wrap(getCellAddress(i, 0), true);
            //console.log("row index: " + i);
          });
      }
      spreadsheet?.hideSpinner();
      // spreadsheet?.refresh();
    } else {
      spreadsheet?.hideColumn(colNumber, colNumber, false);
      headers.unshift(args.itemData.Title);
      mulObj.hidePopup();
    }
  }
  async function onCheckRemove(args: any) {
    let spreadsheet = SSObj.current;
    let colNumber = columnHeaders.indexOf(args.itemData.Title);
    if (spreadsheet?.getActiveSheet().name === "View Report") {
      spreadsheet?.hideColumn(colNumber, colNumber, true);
      let index = headers.indexOf(args.itemData.Title);
      headers.splice(index, 1);
      mulObj.hidePopup();
    }
  }

  async function onOpenComplete(args: any) {
    if (IsLoaded) return;
    IsLoaded = true;
    let spreadsheet = SSObj.current;
    sheetMemoryData = [];
    // existingpropIds = [];
    await GetObjectProperties(1, spreadsheet?.getActiveSheet().index);

    let sheetName = spreadsheet?.getActiveSheet().name;
    let rowIndex = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
    let colIndex = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
    let colHeaderText = getColumnHeaderText(colIndex + 1);

    spreadsheet?.cellFormat(
      {
        fontSize: "10pt",
        fontFamily: "Calibri",
        textAlign: "center",
        fontWeight: "bold",
        backgroundColor: "#D3D3D3",
      },
      sheetName + "!A1:" + colHeaderText + "1"
    );
    spreadsheet?.cellFormat(
      { fontSize: "9pt", fontFamily: "Calibri" },
      sheetName + "!A2:" + colHeaderText + rowIndex
    );
    spreadsheet?.cellFormat(
      { textAlign: "right" },
      sheetName + "!B2:" + colHeaderText + rowIndex
    );

    spreadsheet!.activeSheetIndex = 0;
    // GetProperties();
    let columnRange: string = getColumnHeaderText(1) + ":" + colHeaderText;
    spreadsheet?.setColumnsWidth(120, [columnRange]);

    //   spreadsheet?.applyFilter(undefined, 'A1:' + colHeaderText + 1);
    //create an In-Memory variable for the data
    LoadMemoryDataFromSheet(0, false, true);
    transformedSheet.push(spreadsheet?.getActiveSheet().name);
    spreadsheet?.freezePanes(1, 0, 0);

  }

  function LoadMemoryDataFromSheet(sheetIndex: any, isSort: boolean, isLoad: boolean) {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.sheets[sheetIndex].name;
    if (isSort === true) {
      if (sheetMemoryData.find((x) => x.SheetName === sheetName) !== undefined) {
        memoryData = sheetMemoryData.find(
          (x) => x.SheetName === sheetName
        )?.MemData;
        memoryData.sort(function (a, b) {
          return compareStrings(a.Name, b.Name);
        });
      }
    }
    if (sheetMemoryData.find((x) => x.SheetName === sheetName) !== undefined) {
      memoryData = sheetMemoryData.find(
        (x) => x.SheetName === sheetName
      )?.MemData;
      return;
    }
    spreadsheet?.saveAsJson().then((response) => {
      memoryData = [];
      memoryStyleData = [];
      colStyleWidth = [];
      let temp: any[] = []
      let tmpOrder: any[] = [];
      jsonData = response;
      let obj = JSON.parse(JSON.stringify(jsonData.jsonObject.Workbook));
      let rows = obj.sheets[sheetIndex].rows;
      let cols = obj.sheets[sheetIndex].columns;
      let cells = obj.sheets[sheetIndex].rows[0].cells;
      var obj1, styleObj, widthObj;
      for (let i = 1; i < rows.length; i++) {
        obj1 = {};
        styleObj = {};
        for (let j = 0; j < cells.length; j++) {
          var colHeader = rows && rows[0].cells[j].value;
          if (colHeader === undefined) continue;
          obj1[colHeader] = rows && rows[i].cells[j]?.value;
          styleObj[colHeader] = rows && rows[i].cells[j]?.style;
        }

        memoryData.push(obj1);
        memoryStyleData.push(styleObj);
        temp.push(obj1);
      }
      widthObj = {};
      for (let j = 0; j < cells.length; j++) {
        var colHeader = rows && rows[0].cells[j].value;
        if (colHeader === undefined) continue;
        widthObj[colHeader] = cols && cols[j]?.width;
      }
      colStyleWidth.push(widthObj);

      //  hide admin columns when opening for the first time
      let rowIndex = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
      let colIndex = spreadsheet?.getActiveSheet().usedRange?.colIndex!;

      //store object type Id, Class Id globally for the application
      let item = memoryData && memoryData[0];
      g_obj_type_id = item && item["Object Type"];
      g_obj_type_name = item && item["Object Name"];
      g_cls_id = item && item["Object Class"];
      g_cls_name = item && item["Class Name"];
      if (sheetIndex === 0) {
        if (adminColData.find((x) => x.SheetName === sheetName) === undefined) {
          adminColData.push({
            Cls: g_cls_name,
            ObjType: g_obj_type_name,
            SheetName: spreadsheet?.getActiveSheet().name,
          });
        }
      }
      memoryData.forEach(function (row, i) {
        if (row["ID"] !== -1 && row["ID"] !== undefined) {
          let url =
            host +
            "objects/" +
            row["Object Type"] +
            "/" +
            row["ID"] +
            "/latest/properties?forDisplay=true";
          RunAPI(url, request).then((response) => {
            response?.json().then((data) => {
              if (data.Status !== 404 && data.Status !== 500 && data.Status !== 400) {
                data?.forEach((element: any) => {
                  let propName = existingpropIds.find(
                    (t) => t.Id === element.PropertyDef
                  )?.Name;
                  let propValue = element.TypedValue?.DisplayValue;
                  if (propName !== undefined) row[propName] = propValue;
                });
              }
            });
          });
        }
      });
      sheetMemoryData.push({ SheetName: sheetName, MemData: memoryData });
      let item1 = memoryData && memoryData[0];
      if (item1 !== undefined && sheetName === "View Report") {
        let clsId = item1["Object Class"];
        let objTypeId = item1["Object Type"];
        if (clsId == -1) {
          const url1 = host + "structure/classes";
          RunAPI(url1, request).then((response) => {
            response?.json().then((data) => {
              data.sort(function (a, b) {
                return compareStrings(a.Name, b.Name);
              });
              //console.log(data);
              dropDownListVaultClass2.fields = list1;
              dropDownListVaultClass2.dataSource = data;
            });
          });
          dialogInstance12.show();
        }
      }

      if (isLoad == true) {
        HideAdminColumns(isLoad);//hide admin columns for the first time
      }
    });
  }

  function HideAdminColumns(isLoad: boolean) {
    let spreadsheet = SSObj.current;
    spreadsheet?.showSpinner();
    if (isLoad) {
      let tmpIndex = reorderColumnHeaders.findIndex((x) => x.IsAdmin === true);
      reorderColumnHeaders.forEach((element) => {
        if (element.IsAdmin === true && columnHeaders.indexOf(element.Title) !== -1) {
          let index = columnHeaders.indexOf(element.Title);
          spreadsheet?.delete(index, index, "Column");
          columnHeaders.splice(index, 1);
        }
        if (element.IsAdmin !== true && columnHeaders.indexOf(element.Title) !== -1) {
          currentColumnOrder.push(element.Title);
        }
      });
    }
    else {
      reorderColumnHeaders.forEach((element) => {
        if (element.IsAdmin === true && columnHeaders.indexOf(element.Title) !== -1) {
          let tmpIndex = columnHeaders.indexOf(element.Title);
          spreadsheet?.delete(tmpIndex, tmpIndex, "Column");
          columnHeaders.splice(tmpIndex, 1);
        }
        if (element.IsAdmin !== true && columnHeaders.indexOf(element.Title) !== -1) {
          currentColumnOrder.push(element.Title);
        }
      });
    }
    columnHeaders = currentColumnOrder;
    spreadsheet?.hideSpinner();

  }

  // Triggers before going to the editing mode.
  const onCellEdit = (args) => {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex;
    existingpropIds.forEach((prop) => {
      if (prop.AutomaticValueType != 0 && columnHeaders.indexOf(prop.Name) !== -1) {
        var index = columnHeaders.indexOf(prop.Name);
        let colHeaderText = getColumnHeaderText(index + 1);
        if (args.address.includes(colHeaderText)) {
          args.cancel = true;
          dialogInstance15?.show();
        }
      }
    });

    //admin columns
    reorderColumnHeaders.forEach((element) => {
      if (element.IsAdmin == true && columnHeaders.indexOf(element.Title) !== -1) {
        var index = columnHeaders.indexOf(element.Title);
        let colHeaderText = getColumnHeaderText(index + 1);
        if (args.address.includes(colHeaderText)) {
          args.cancel = true;
          dialogInstance15?.show();
        }
      }
    });
  };

  function MarkObligatoryProperties(isNewRow: boolean = false) {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex;
    if (isNewRow) {
      usedRowIdx = usedRowIdx + 1;
    }
    existingpropIds.forEach((prop) => {
      if (prop.Required && columnHeaders.indexOf(prop.Name) !== -1) {
        var index = columnHeaders.indexOf(prop.Name);
        let colHeaderText = getColumnHeaderText(index + 1);
        spreadsheet?.cellFormat(
          {
            fontSize: "9pt",
            fontFamily: "Calibri",
            textAlign: "center",
            backgroundColor: "Gray",
          },
          sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx
        );
      }
      if (prop.AutomaticValueType != 0 && columnHeaders.indexOf(prop.Name) !== -1) {
        var index = columnHeaders.indexOf(prop.Name);
        let colHeaderText = getColumnHeaderText(index + 1);
        spreadsheet?.cellFormat(
          {
            fontSize: "9pt",
            fontFamily: "Calibri",
            // textAlign: "center",            
            backgroundColor: "#f0f0f0",
            color: "#808080"
          },
          sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx
        );
      }
    });
    reorderColumnHeaders.forEach((element) => {
      if (element.IsAdmin == true && columnHeaders.indexOf(element.Title) !== -1) {
        var index = columnHeaders.indexOf(element.Title);
        let colHeaderText = getColumnHeaderText(index + 1);
        spreadsheet?.cellFormat(
          {
            fontSize: "9pt",
            fontFamily: "Calibri",
            // textAlign: "center",            
            backgroundColor: "#cae1f8"
          },
          sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx
        );
      }
    });

  }

  const onMultiSelect = (args: MultiSelectChangeEventArgs) => {
    multiSelectDataItem = "";
    args.value.forEach((data: any) => {
      multiSelectDataItem += data + ";";
    });
  };

  const onCheckSelectAdminCol = (args: any) => {
    let spreadsheet = SSObj.current;
    if (spreadsheet?.getActiveSheet().name !== "View Report") {
      return;
    }
    let colNumber = columnHeaders.indexOf(args.itemData);
    spreadsheet?.hideColumn(colNumber, colNumber, false);
    avladminColumns.unshift(args.itemData);
    mulObj1.hidePopup();
    // dialogInstance3?.hide();
  };

  const onCheckRemoveAdminCol = (args: any) => {
    let spreadsheet = SSObj.current;
    if (spreadsheet?.getActiveSheet().name !== "View Report") {
      return;
    }
    if (avladminColumns.indexOf(args.itemData) !== -1) {
      let colNumber = columnHeaders.indexOf(args.itemData);
      spreadsheet?.hideColumn(colNumber, colNumber, true);
      let index = avladminColumns.indexOf(args.itemData);
      avladminColumns.splice(index, 1);
      mulObj1.hidePopup();
      //dialogInstance3?.hide();
    }
  };

  function IsModified() {
    let spreadsheet = SSObj.current;
    usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex;
    for (let i = 1; i < usedRowIdx; i++) {
      let modified = getCell(
        i,
        columnHeaders.indexOf("Modified"),
        spreadsheet!.getActiveSheet()
      ).value;
      if (modified === "True") {
        return true;
      }
    }
    return false;
  }



  const selectUnSelectButton = useMemo(() => {
    if (selectedAllRows) {
      return "Select All";
    } else {
      return "Unselect All";
    }
  }, [selectedAllRows]);

  const selectUnSelectAdminButton = useMemo(() => {
    if (!selectedAdmin) {
      return "Unselect Admin";
    } else {
      return "Select Admin";
    }
  }, [selectedAdmin]);

  const hideUnHideAdminButton = useMemo(() => {
    if (hideUnHideAdmins) {
      return "Hide Admin";
    } else {
      return "Unhide Admin";
    }
  }, [hideUnHideAdmins]);

  function ReorderColumns() {
    let spreadsheet = SSObj.current;
    let currentSheetName = spreadsheet?.getActiveSheet().name;
    handleSaveFormats();
    tempDataSource = [];
    console.log('reorderColumnHeaders');
    console.log(listbox.dataSource);
    reorderColumnHeaders.forEach((element) => {
      let required = existingpropIds.find((x) => x.Name === element.Title)?.Required;
      let automaticValueType = existingpropIds.find((x) => x.Name === element.Title)?.AutomaticValueType;
      if (element.IsAdmin == true) {
        element.PropertyLabel = "R";
      }
      if (required === true) {
        element.PropertyLabel = "O";
      }
      if (automaticValueType !== 0) {
        element.PropertyLabel = "R";
      }

    });

    if (listbox.dataSource.length == 0) {
      listbox.dataSource = reorderColumnHeaders.filter(
        (x) => x.SheetName === currentSheetName
      );
    }
    (listbox.dataSource as object[]).forEach((sdata, index) => {
      tempDataSource.push({
        Title: sdata["Title"],
        Visible: sdata["Visible"],
        IsAdmin: sdata["IsAdmin"],
        SheetName: sdata["SheetName"],
      });
      // if (sdata["IsAdmin"] === true) {
      //   sdata["Visible"] =
      //     tempSelectedAdminText === "Select Admin" ? false : true;
      // }
    });

    listbox.fields = { text: "Title", value: "Title" };
    listbox.columns = [
      {
        headerText: "Visibility",
        field: "Visible",
        width: 40,
        allowSorting: false,
        template:
          '<div class="template_checkbox">${ if(Visible) }<input type = "checkbox" checked > ${ else}<input type = "checkbox" > ${/if}</div>',
      },
      {
        headerText: "Property Name",
        field: "Title",
        isPrimaryKey: true,
        width: 100,
        allowSorting: true
      },
      {
        headerText: "",
        field: "PropertyLabel",
        width: 100,
        allowSorting: false
      },
      {
        field: "IsAdmin",
        width: 20,
        visible: false,
        allowEditing: false,
        isIdentity: true,
        template:
          '<div class="template_checkbox">${ if(IsAdmin) }<input type = "checkbox" checked > ${ else}<input type = "checkbox" > ${/if}</div>',
      },
    ];
    dialogInstance7?.show();
  }

  async function OnSaveComplete(args: SaveCompleteEventArgs) {
    await LoadPropertyValues();
  }

  const Help = () => {
    dialogInstance5?.show();
  };

  async function SaveInMFiles() {
    IsFileSave = true;
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    spreadsheet?.showSpinner();
    let jsonData: any;
    var data = {
      value: 2,
    };
    var data2 = {
      value: 0, //check-in
    };
    var pdf = {
      fitSheetOnOnePage: false,
      orientation: "Portrait",
    };
    let docId = sessionStorage.getItem("docId");
    var url = host + "objects/0/" + docId + "/latest/checkedout";
    var request: RequestInit = {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const response = await RunAPI(url, request);
    const data1 = await response.json();
    if (data1 === undefined) {
      spreadsheet?.hideSpinner();
      return;
    }
    if (data1.Status == 500) {
      spreadsheet?.hideSpinner();
      dialogInstance16.show();
      return;
    }

    /*to show admin columns and hide it after saving back in M-Files
    memoryData = sheetMemoryData.find((x) => x.SheetName === sheetName)?.MemData;
    columnHeaders = currentColumnOrder;
  
    updateCellValue(memoryData, currentColumnOrder);
    */
    // console.log(data1);
    await spreadsheet?.saveAsJson().then((response) => {
      jsonData = response;
      let arr = jsonData.jsonObject.Workbook.sheets[0].rows;
      requiredAdminColumns.forEach((col) => {
        if (columnHeaders.indexOf(col) === -1) {
          arr[0].cells.push({
            value: col,
            style: {
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "Calibri",
              fontSize: "10pt",
              backgroundColor: "#D3D3D3",
            }
          });
        }
      });
      memoryData = sheetMemoryData.find(
        (x) => x.SheetName === sheetName
      )?.MemData;
      //console.log(memoryData);
      memoryData.forEach((element, index) => {
        requiredAdminColumns.forEach((col) => {
          if (columnHeaders.indexOf(col) === -1) {
            arr[index + 1].cells.push({
              value: element[col],
              style: {
                fontSize: "9pt",
                fontFamily: "Calibri",
                backgroundColor: "#cae1f8"
              }
            });
          }
        });
      });
      //console.log(arr);
      let formData: FormData = new FormData();
      formData.append("JSONData", JSON.stringify(jsonData.jsonObject.Workbook));
      formData.append("fileName", "Sample1");
      formData.append("saveType", "Xlsx");
      formData.append("versionType", "Xlsx");
      formData.append("fileContentType", "Xlsx");
      formData.append("pdfLayoutSettings", JSON.stringify(pdf));
      IsFileSave = false;
      fetch(SERVER_URL + "provider/saveJson", {
        method: "POST",
        body: formData,
      }).then((response) => {
        response.blob().then((data) => {
          // console.log(data);
          request = {
            method: "PUT",
            body: data,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
          var url1 =
            host +
            "objects/0/" +
            data1?.ObjVer?.ID +
            "/files/" +
            data1?.Files[0]?.ID +
            "/content";
          RunAPI(url1, request).then((response1) => {
            request = {
              method: "PUT",
              body: JSON.stringify(data2),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            };
            RunAPI(url, request).then((response3) => {
              response3.json().then((data3) => { });
            });
          });
        });
      });
    });
    spreadsheet?.hideSpinner();
  }

  function dialogClose() {
    dialogInstance5?.hide();
    dialogInstance8?.hide();
    dialogInstance15?.hide();
    dialogInstance16?.hide();
  }

  let fields = { text: "Name", value: "Name" };

  const zoomsettings = { effect: "Zoom", duration: 400, delay: 0 };
  let buttons = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        dialogInstance5?.hide();
        dialogInstance8?.hide();
      },
    },
  ];

  let errorbuttons = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        dialogInstance14?.hide();
      },
    },
  ];

  let alertErrorbuttons = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        dialogInstance15?.hide();
        dialogInstance16?.hide();
      },
    },
  ];



  let jsonData: any;
  var pdf = {
    fitSheetOnOnePage: false,
    orientation: "Portrait",
  };

  let transformbuttons = [
    {
      buttonModel: {
        content: "Transform",
        cssClass: "e-success",
        isPrimary: true,
      },
      click: () => {
        transformSheet();
      },
    },
  ];

  let selectclassbuttons = [
    {
      buttonModel: {
        content: "Ok",
        cssClass: "e-success",
        isPrimary: true,
      },
      click: () => {
        selectClass();
      },
    },
  ];

  let reorderbuttons = [
    //     {
    //     buttonModel: {
    //         content: 'Select All',
    //         cssClass: 'e-success',
    //         isPrimary: true,
    //     },
    //     'click': () => {
    //         selectAllRows();
    //     }
    // },
    // {
    //     buttonModel: {
    //         content: 'Unselect All',
    //         cssClass: 'e-success',
    //         isPrimary: true,
    //     },
    //     'click': () => {
    //         unselectAllRows();
    //     }
    //     },
    //     {
    //         buttonModel: {
    //             content: 'Select Admin',
    //             cssClass: 'e-success',
    //             isPrimary: true,
    //         },
    //         'click': () => {
    //             selectAllAdminRows();
    //         }
    //     },
    //     {
    //         buttonModel: {
    //             content: 'Unselect Admin',
    //             cssClass: 'e-success',
    //             isPrimary: true,
    //         },
    //         'click': () => {
    //             unselectAllAdminRows();
    //         }
    //     },
    {
      buttonModel: {
        content: "Apply",
        cssClass: "e-success",
        isPrimary: true,
      },
      click: () => {
        rearrangeColumns();
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        // cssClass: "e-success",
        cssClass: "btn-cancel",
        isPrimary: true,
      },
      click: () => {
        cancelRearrangeColumns();
      },
    },
  ];

  async function unselectAllRows() {
    setSelectedAllRows((prevState) => !prevState);
    setSelectedAdmin(true);
    isSelectAllEvent = true;
    (listbox.dataSource as object[]).forEach((sdata, index) => {
      sdata["Visible"] = selectedAllRows;
    });
    listbox.refresh();
  }

  async function selectClass() {
    let spreadsheet = SSObj.current;
    //get object type for the selected class
    const url1 = host + "structure/classes/" + dropDownListVaultClass2.value;
    await RunAPI(url1, request).then((response) => {
      response?.json().then((data) => {
        //console.log(data);
        const url2 = host + "structure/objecttypes/" + data.ObjType;
        RunAPI(url2, request).then((response) => {
          response.json().then((data1) => {
            //console.log(data1);
            spreadsheet?.updateCell(
              {
                value: data1.ID,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(1, columnHeaders.indexOf("Object Type"))
            ); //  to specify the cell value, range
            spreadsheet?.updateCell(
              {
                value: data1.Name,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(1, columnHeaders.indexOf("Object Name"))
            );
          });
        });
      });
    });
    spreadsheet?.updateCell(
      {
        value: dropDownListVaultClass2.value,
        style: { fontFamily: "Calibri", fontSize: "9pt", textAlign: "right" },
      },
      getCellAddress(1, columnHeaders.indexOf("Object Class"))
    ); //  to specify the cell value, range
    spreadsheet?.updateCell(
      {
        value: dropDownListVaultClass2.text,
        style: { fontFamily: "Calibri", fontSize: "9pt", textAlign: "right" },
      },
      getCellAddress(1, columnHeaders.indexOf("Class Name"))
    ); //  to specify the cell value, range
    dialogInstance12.hide();
    await GetObjectProperties(1, 0);
  }

  async function transformSheet() {
    dialogInstance9.hide();
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    if (transformedSheet.indexOf(sheetName) !== -1) return;

    spreadsheet?.showSpinner();
    var temp_obj_type_id = dropDownListVaultObject.value;
    var temp_obj_type_name = dropDownListVaultObject.text;
    var temp_cls_id = dropDownListVaultClass.value;
    var temp_cls_name = dropDownListVaultClass.text;
    var temp_unique_property = dropDownListUniqueProperty.text;
    var temp_unique_property_id = dropDownListUniqueProperty.value;

    if (adminColData.find((x) => x.SheetName === sheetName) === undefined) {
      adminColData.push({
        Cls: dropDownListVaultClass.text,
        ObjType: dropDownListVaultObject.text,
        SheetName: spreadsheet?.getActiveSheet().name,
      });
    } else {
      var tmp1 = adminColData.find((x) => x.SheetName === sheetName);
      tmp1.Cls = dropDownListVaultClass.text;
      tmp1.ObjType = dropDownListVaultObject.text;
    }

    var currentColHeaders = reorderColumnHeaders.find(
      (x) => x.SheetName === sheetName
    );
    //if (currentColHeaders.find(x => x.Title === 'Lookup ID') === undefined && columnHeaders.indexOf(temp_unique_property) === -1) {
    //    alert('The unique property has not been defined.');
    //    spreadsheet?.hideSpinner();
    //    return;
    //}
    listbox.dataSource = [];

    let usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
    let usedColIdx = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
    console.log('usedRowIdx is ' + usedRowIdx);
    //await IsLookupIdDefined(temp_cls_id);

    let colHeaderText = getColumnHeaderText(usedColIdx + 1);
    let tmp = usedRowIdx + 1;
    spreadsheet?.cellFormat(
      { fontSize: "9pt", fontFamily: "Calibri" },
      sheetName + "!A2:" + colHeaderText + tmp
    );
    spreadsheet?.cellFormat(
      { textAlign: "right" },
      sheetName + "!B2:" + colHeaderText + tmp
    );

    spreadsheet?.cellFormat(
      {
        fontSize: "10pt",
        fontFamily: "Calibri",
        textAlign: "center",
        fontWeight: "bold",
        backgroundColor: "#D3D3D3",
      },
      sheetName + "!A1:" + colHeaderText + "1"
    );

    // add column GUID
    spreadsheet?.updateCell(
      {
        value: "GUID",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 1)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "ID",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 2)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "Object Type",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 3)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "Object Name",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 4)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "Object Class",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 5)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "Class Name",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 6)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "Modified",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 7)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "Update Status",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 8)
    ); //add column header
    spreadsheet?.updateCell(
      {
        value: "Updated Message",
        style: {
          fontWeight: "bold",
          textAlign: "center",
          fontFamily: "Calibri",
          fontSize: "10pt",
          backgroundColor: "#D3D3D3",
        },
      },
      getCellAddress(0, usedColIdx + 9)
    ); //add column header
    const sheet = spreadsheet?.getActiveSheet();
    let lastEmptyRow = getLastEmptyRow() - 1;
    console.log('lastEmptyRow is ' + lastEmptyRow);
    for (let i = 1; i <= lastEmptyRow; i++) {
      spreadsheet?.updateCell(
        {
          value: temp_obj_type_id,
          style: {
            fontWeight: "normal",
            textAlign: "right",
            fontFamily: "Calibri",
            fontSize: "9pt",
          },
        },
        getCellAddress(i, usedColIdx + 3)
      );
      spreadsheet?.updateCell(
        {
          value: temp_obj_type_name,
          style: {
            fontWeight: "normal",
            textAlign: "right",
            fontFamily: "Calibri",
            fontSize: "9pt",
          },
        },
        getCellAddress(i, usedColIdx + 4)
      );
      spreadsheet?.updateCell(
        {
          value: temp_cls_id,
          style: {
            fontWeight: "normal",
            textAlign: "right",
            fontFamily: "Calibri",
            fontSize: "9pt",
          },
        },
        getCellAddress(i, usedColIdx + 5)
      );
      spreadsheet?.updateCell(
        {
          value: temp_cls_name,
          style: {
            fontWeight: "normal",
            textAlign: "right",
            fontFamily: "Calibri",
            fontSize: "9pt",
          },
        },
        getCellAddress(i, usedColIdx + 6)
      );
      spreadsheet?.updateCell(
        {
          value: "FALSE",
          style: {
            fontWeight: "normal",
            textAlign: "right",
            fontFamily: "Calibri",
            fontSize: "9pt",
          },
        },
        getCellAddress(i, usedColIdx + 7)
      );
      spreadsheet?.updateCell(
        {
          value: "FALSE",
          style: {
            fontWeight: "normal",
            textAlign: "right",
            fontFamily: "Calibri",
            fontSize: "9pt",
          },
        },
        getCellAddress(i, usedColIdx + 8)
      );
      spreadsheet?.updateCell(
        {
          value: "",
          style: {
            fontWeight: "normal",
            textAlign: "right",
            fontFamily: "Calibri",
            fontSize: "9pt",
          },
        },
        getCellAddress(i, usedColIdx + 9)
      );
    }
    usedColIdx = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
    colHeaderText = getColumnHeaderText(usedColIdx + 1);
    let columnRange: string = getColumnHeaderText(1) + ":" + colHeaderText;
    spreadsheet?.setColumnsWidth(120, [columnRange]);
    spreadsheet?.enableToolbarItems("View", [8], true);
    //reset columnHeaders after you transform the sheet
    //reorderColumnHeaders = [];
    let sheetIndex = spreadsheet?.sheets.find((x) => x.name === sheetName)?.id;
    if (sheetIndex !== undefined) sheetIndex = sheetIndex - 1;
    await GetObjectProperties(1, sheetIndex);

    var url, lkupId;

    for (let i = 1; i <= lastEmptyRow; i++) {
      if (reorderColumnHeaders.find((x) => x.Title === "Lookup ID" && x.SheetName === sheetName) === undefined) {
        lkupId = getCell(
          i,
          columnHeaders.indexOf(temp_unique_property),
          spreadsheet?.getActiveSheet()!
        )?.value;
        url =
          host +
          "objects/" +
          temp_obj_type_id +
          "?p100=" +
          temp_cls_id +
          "&p" +
          temp_unique_property_id +
          "=" +
          lkupId;
      } else {
        if (lookupIds.length > 0) {
          var lkupIdPropDefID = lookupIds[0].lkupId; //fetch lookup Id and see whether the objects exists already
          lkupId = getCell(
            i,
            columnHeaders.indexOf("Lookup ID"),
            spreadsheet?.getActiveSheet()!
          )?.value;
          url =
            host +
            "objects/" +
            temp_obj_type_id +
            "?p100=" +
            temp_cls_id +
            "&p" +
            lkupIdPropDefID +
            "=" +
            lkupId;
        }
      }

      const response = await RunAPI(url, request);
      const data = await response.json();
      if (data.Items?.length > 0) {
        //data exists already in M-Files..so update it
        var id = data.Items[0].ObjVer.ID;
        var guid = data.Items[0].ObjectGUID;
        spreadsheet?.updateCell(
          {
            value: guid,
            style: {
              fontWeight: "normal",
              textAlign: "right",
              fontFamily: "Calibri",
              fontSize: "9pt",
            },
          },
          getCellAddress(i, columnHeaders.indexOf("GUID"))
        );
        spreadsheet?.updateCell(
          {
            value: id,
            style: {
              fontWeight: "normal",
              textAlign: "right",
              fontFamily: "Calibri",
              fontSize: "9pt",
            },
          },
          getCellAddress(i, columnHeaders.indexOf("ID"))
        );
        spreadsheet?.updateCell(
          {
            value: "True",
            style: {
              fontWeight: "normal",
              textAlign: "right",
              fontFamily: "Calibri",
              fontSize: "9pt",
            },
          },
          getCellAddress(i, columnHeaders.indexOf("Modified"))
        );
      } else {
        spreadsheet?.updateCell(
          {
            value: "-1",
            style: {
              fontWeight: "normal",
              textAlign: "right",
              fontFamily: "Calibri",
              fontSize: "9pt",
            },
          },
          getCellAddress(i, columnHeaders.indexOf("ID"))
        );
      }
    }
    LoadMemoryDataFromSheet(sheetIndex, false, false);
    transformedSheet.push(sheetName);
    spreadsheet?.enableToolbarItems("M-Files", [2], false);
    spreadsheet?.hideSpinner();
  }

  async function selectAllAdminRows() {
    setSelectedAdmin((prevState) => !prevState);
    isSelectAdminEvent = true;
    (listbox.dataSource as object[]).forEach((sdata, index) => {
      if (sdata["IsAdmin"] === true) {
        sdata["Visible"] = selectedAdmin;
      }
    });
    listbox.refresh();
  }

  async function unselectAllAdminRows() {
    setSelectedAdmin((prevState) => !prevState);
    isSelectAdminEvent = true;
    (listbox.dataSource as object[]).forEach((sdata, index) => {
      if (sdata["IsAdmin"] === true) {
        sdata["Visible"] = selectedAdmin;
      }
    });
    listbox.refresh();
  }

  async function cancelRearrangeColumns() {
    if (isSelectAllEvent === true) {
      if (selectUnSelectButton === "Unselect All") {
        setSelectedAllRows(true);
        if (selectUnSelectAdminButton === "Unselect Admin") {
          setSelectedAdmin(true);
        }
      }
      if (selectUnSelectButton === "Select All") {
        setSelectedAllRows(false);
      }
    }

    setHideUnHideAdmins(true);
    if (isSelectAdminEvent === true) {
      if (selectUnSelectAdminButton === "Select Admin") {
        setSelectedAdmin(false);
      }
      if (selectUnSelectAdminButton === "Unselect Admin") {
        setSelectedAdmin(true);
      }
      if (isSelectAllEvent === true && selectUnSelectAdminButton === "Select Admin") {
        setSelectedAdmin(true);
      }
    }

    dialogInstance7.hide();
    //restore datasource of the listbox when closed
    (tempDataSource as object[]).forEach((sdata, index) => {
      (listbox.dataSource as object[]).forEach((sdata1, index1) => {
        if (sdata["Title"] === sdata1["Title"]) {
          sdata1["Visible"] = sdata["Visible"];
        }
      });
    });
    listbox.refresh();
    isSelectAdminEvent = false
    isSelectAllEvent = false
  }

  async function rearrangeColumns() {
    setHideUnHideAdmins(true);
    if (selectUnSelectAdminButton === "Select Admin") {
      setSelectedAdmin(true);
      tempSelectedAdminText = "Select Admin";
    } else {
      tempSelectedAdminText = "Unselect Admin";
    }
    let visibilityCount = 0;
    (listbox.dataSource as object[]).forEach((sdata, index) => {
      if (sdata["Visible"] == true) visibilityCount++;
    });
    if (visibilityCount < 1) {
      dialogInstance8.show();
      return;
    }
    currentColumnOrder = [];
    let spreadsheet = SSObj.current;
    let sheetIndex = spreadsheet!.activeSheetIndex;
    let sheetName = spreadsheet?.getActiveSheet().name;

    dialogInstance7.hide();
    spreadsheet?.showSpinner();
    (listbox.dataSource as object[]).forEach(async (sdata, index) => {
      let colNumber = columnHeaders.indexOf(sdata["Title"]);
      if (sdata["Visible"] === true) {
        currentColumnOrder.push(sdata["Title"]);
      }
    });
    spreadsheet?.saveAsJson().then((response) => {
      jsonData = response;
      let obj = JSON.parse(JSON.stringify(jsonData.jsonObject.Workbook));
      let rows = obj.sheets[sheetIndex!].rows;
      let cells = obj.sheets[sheetIndex!].rows[0].cells;
      let temp: any[] = [];
      var obj1;
      for (let i = 1; i < rows.length - 1; i++) {
        obj1 = {};
        for (let j = 0; j < cells.length; j++) {
          var colHeader = rows[0].cells[j].value;

          if (colHeader === undefined) continue;
          obj1[colHeader] = rows[i]?.cells[j]?.value;
        }
        //console.log(obj1);
        temp.push(obj1);
      }
      memoryData = sheetMemoryData.find(
        (x) => x.SheetName === sheetName
      )?.MemData;
      let rowIndex = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
      let colIndex = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
      let colHeaderText = getColumnHeaderText(colIndex + 1);
      let tmprowIndex = rowIndex + 1;
      let newcolHeaderText = getColumnHeaderText(currentColumnOrder.length + 1);
      spreadsheet?.clear({
        type: "Clear All",
        range: 'A1' + ":" + colHeaderText + tmprowIndex,
      });
      if (memoryData == undefined) {
        updateCellValue(temp, currentColumnOrder);
      } else updateCellValue(memoryData, currentColumnOrder);

      columnHeaders = currentColumnOrder;
      // reorderColumnHeaders = currentColumnOrder;
      spreadsheet?.hideSpinner();
      let columnRange: string =
        getColumnHeaderText(1) +
        ":" +
        getColumnHeaderText(currentColumnOrder.length);
      //spreadsheet?.setColumnsWidth(120, [columnRange]);
      spreadsheet?.selectRange(getCellAddress(0, 0));
      MarkObligatoryProperties(false);
    });
    isSelectAdminEvent = false;
    isSelectAllEvent = false;
  }

  function FillRows(propId, coladded, usedRowIdx, propName) {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    if (propId === undefined) {
      var tmp;
      if (propName === "Object Name") {
        tmp = g_obj_type_name;
      } else if (propName === "Class Name") {
        tmp = g_cls_name;
      } else if (propName === "Object Type") {
        tmp = g_obj_type_id;
      } else if (propName === "Object Class") {
        tmp = g_cls_id;
      } else if (propName === "Update Status" || "Modified") {
        tmp = "FALSE";
      }

      for (let i = 1; i < usedRowIdx; i++) {
        if (propName === "GUID") {
          let item = memoryData && memoryData[i - 1];
          tmp = item["GUID"];
        }
        if (propName === "ID") {
          let item = memoryData && memoryData[i - 1];
          tmp = item["ID"];
        }
        // console.log(tmp);
        spreadsheet?.updateCell(
          {
            value: tmp,
            style: {
              fontFamily: "Calibri",
              fontSize: "9pt",
              textAlign: "right",
            },
          },
          getCellAddress(i, coladded)
        );
        spreadsheet?.wrap(getCellAddress(i, coladded), true);
      }
    }

    if (propId === undefined) return;

    for (let i = 1; i < usedRowIdx; i++) {
      rowIndex = i;
      let item = memoryData && memoryData[rowIndex - 1];
      let objTypeId = item && item["Object Type"];
      let objId = item["ID"];
      if (
        objTypeId === undefined ||
        objId === undefined ||
        propId === undefined
      ) {
        return;
      }
      if (objId === -1) continue;
      let url =
        host +
        "objects/" +
        objTypeId +
        "/" +
        objId +
        "/latest/properties/" +
        propId +
        "?forDisplay=true";

      RunAPI(url, request)
        .then((res) => res.json())
        .then((data) => {
          spreadsheet?.updateCell(
            {
              value: data.TypedValue?.DisplayValue,
              style: {
                fontFamily: "Calibri",
                fontSize: "9pt",
                textAlign: "right",
              },
            },
            getCellAddress(i, coladded)
          );
          //spreadsheet?.wrap(getCellAddress(i, coladded), true);
        });
    }
    let columnRange: string =
      getColumnHeaderText(1) +
      ":" +
      getColumnHeaderText(currentColumnOrder.length);
    spreadsheet?.setColumnsWidth(120, [columnRange]);
  }

  async function onRowSelected(args) {
    // console.log(args);
    // console.log(args.target.nodeName);
    //if (args.data.Title === 'ID') {
    //    args.cancel = true;
    //    return;
    //}

    if (args.target === null) {
      return;
    }
    if (args.target.nodeName !== "INPUT") return;
    if (args.target != null && args.target.nodeName !== "INPUT") return;
    let temp = listbox.dataSource[args.rowIndex].Visible;
    if (temp === false) temp = true;
    else {
      temp = false;
    }
    listbox.dataSource[args.rowIndex].Visible = temp;
    console.log(listbox.dataSource);
  }

  function updateCellValue(data, field) {
    let spreadsheet = SSObj.current;
    let sheetIdx = spreadsheet!.activeSheetIndex;
    let rowIndex = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
    let colIndex = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
    let colHeaderText = getColumnHeaderText(colIndex + 1);
    let tmprowIndex = rowIndex + 1;

    // Set the column header using specified field.
    field.forEach(function (field, i) {
      // updateCell method is used to update a cell properties.
      let item = colStyleWidth && colStyleWidth[0];
      spreadsheet!.updateCell(
        {
          value: field,
          style: {
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Calibri",
            fontSize: "10pt",
            backgroundColor: "#D3D3D3"
          },
        },
        getSheetName(spreadsheet!, sheetIdx) + "!" + getCellAddress(0, i)
      );
      spreadsheet?.setColWidth(item && item[field], i, sheetIdx!);
    });
    // Loop the data source and get and set the proper cell value based on specified field mapping.
    data.forEach(function (item, i) {
      for (let j = 0; j < field.length; j++) {
        // updateCell method is used to update a cell properties.
        let propDataType = existingpropIds.find(
          (t) => t.Name === field[j]
        )?.DataType;
        let item1 = memoryStyleData && memoryStyleData[i + 1];
        if (propDataType === 5) {
          var date = item[field[j]];
          spreadsheet!.updateCell(
            {
              value: date,
              format: "yyyy-mm-dd",
              style: {
                fontFamily: "Calibri",
                fontSize: "9pt",
                textAlign: "right",
              },
            },
            getSheetName(spreadsheet!, sheetIdx) +
            "!" +
            getCellAddress(i + 1, j)
          );
        } else {
          spreadsheet!.updateCell(
            {
              value: item[field[j]],
              style: item1 && item1[field[j]]
            },
            getSheetName(spreadsheet!, sheetIdx) +
            "!" +
            getCellAddress(i + 1, j)
          );
        }
      }
    });
  }

  async function LoadPropertyValues() {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    //console.log(existingpropIds);
    for (const item1 of existingpropIds) {
      //existingpropIds.forEach((item1: any) => {
      if (item1.SheetName == sheetName) {
        let prop = item1;
        propId = prop.Id;
        propDataType = prop.DataType;
        ownerId = -1;
        if (propDataType === 9 || propDataType === 10) {
          if (prop.DepPD > 0) {
            let ownerPD = existingpropIds.find((x) => x.Id == prop.DepPD).Name;
            let ownerPDCol = columnHeaders.indexOf(ownerPD);
            if (ownerPDCol !== -1) {
              ownerPDValue = getCell(
                currentRow,
                ownerPDCol,
                spreadsheet!.getActiveSheet()
              ).value;
              GetOwnerId(prop.DepPD, ownerPDValue);
            }
          }
          GetDataList(prop.Name, propId, propDataType, ownerId, item1.Required, true, 0);
        }
      }
    }
  }

  async function RefreshObjects() {
    var clsId = -1;
    var isMultiplClass = false;
    var isAutomatic = false;
    let propName = existingpropIds.find(
      (t) => t.Id === selectedpropId
    )?.Name;
    let propDataType = existingpropIds.find(
      (t) => t.Id === selectedpropId
    )?.DataType;
    console.log(singleselectProperties);
    console.log(multiselectProperties);
    if (propDataType === 9) {
      clsId = singleselectProperties.find(
        (x) => x.Id === selectedpropId
      )?.ClsId;
      isMultiplClass = singleselectProperties.find(
        (x) => x.Id === selectedpropId
      )?.IsMultipleClass;
      isAutomatic = singleselectProperties.find(
        (x) => x.Id === selectedpropId
      )?.IsAutomatic;
    }
    else if (propDataType === 10) {
      clsId = multiselectProperties.find(
        (x) => x.Id === selectedpropId
      )?.ClsId;
      isMultiplClass = multiselectProperties.find(
        (x) => x.Id === selectedpropId
      )?.IsMultipleClass;
      isAutomatic = multiselectProperties.find(
        (x) => x.Id === selectedpropId
      )?.IsAutomatic;
    }

    GetData(
      propName,
      selectedpropId,
      clsId,
      propobjTypeId,
      propDataType,
      -1,
      false,//loadall
      isMultiplClass,
      true,//refresh
      isAutomatic
    );
  }

  async function fileSelect() {
    var file = inputOpenFileRef1.current.files[0];
    var formData = new FormData();
    formData.append("file", file);

    const url1 = host + "files";
    const request1: RequestInit = {
      method: "POST",
      body: formData,
    };
    const response1 = await RunAPI(url1, request1);
    //console.log(response1);
    const data1 = await response1.json();
    //console.log(data1);
    uploadId = data1.UploadID;
    uploadSize = data1.Size;
  }

  async function fileMerge() {
    issheetRepeated = false;
    menuItems = [];
    let spreadsheet = SSObj.current;
    let sheetCount = spreadsheet?.sheets.length!;
    await LoadPropertyValues();
    var file = inputOpenFileRef.current.files[0];
    spreadsheet?.showSpinner();
    var formData = new FormData();
    formData.append("file", file);
    fetch(SERVER_URL + "provider/insertRecord", {
      method: "POST",
      body: formData,
    }).then((response) => {
      response?.json().then((data) => {
        mergeFileData = data;
        var sheetName;
        var j = sheetCount;
        data.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            issheetRepeated = false;
            if (spreadsheet?.sheets.find((x) => x.name === sheetName)) {
              issheetRepeated = true;
            }
            menuItems.push({
              text: sheetName,
              iconCss: "e-xlsx e-icons",
              repeated: issheetRepeated,
            });
          }
        });
        if (menuItems.find((x) => x.repeated === true)) {
          var index = menuItems.find((x) => x.repeated === true);
          if (index !== undefined) {
            alert(
              "The application already contains sheet with the name " +
              index.text +
              ". Please rename and then insert again."
            );
            spreadsheet?.hideSpinner();
            return;
          }
        }
        data.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            return;
          }
          var data = JSON.parse(temp);
          spreadsheet?.insertSheet([
            {
              index: j,
              name: sheetName,
              ranges: [{ dataSource: data }],
            },
          ]);
          if (data.length > 0) {
            var rowIndex = data.length + 1;
            var keyCount = Object.keys(data[0]).length;
            let colHeaderText = getColumnHeaderText(keyCount);
            let columnRange: string =
              getColumnHeaderText(1) + ":" + colHeaderText;
            var tmp = sheetName + "!" + columnRange;
            spreadsheet?.setColumnsWidth(120, [tmp]);
            //set font for header
            let columnRange1: string =
              getColumnHeaderText(1) + "1:" + colHeaderText + "1";
            var tmp1 = sheetName + "!" + columnRange1;

            let columnRange2: string =
              getColumnHeaderText(1) + "2:" + colHeaderText + rowIndex;
            var tmp2 = sheetName + "!" + columnRange2;

            spreadsheet?.cellFormat(
              {
                fontSize: "10pt",
                fontFamily: "Calibri",
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: "#D3D3D3",
              },
              tmp1
            );
            spreadsheet?.cellFormat(
              { fontSize: "9pt", fontFamily: "Calibri" },
              tmp2
            );
            spreadsheet?.freezePanes(1, 0, j);
          }
          j++;
        });

        //spreadsheet?.addFileMenuItems(
        //    [{
        //        text: 'Exit', iconCss: 'e-save e-icons', items: menuItems
        //    }],
        //    'Save As', true);
        spreadsheet?.hideSpinner();
        // spreadsheet?.refresh();
      });
    });
  }
  function dataBound(args) {
    //listbox.clearSelection();
  }

  async function selectAllRows() {
    setSelectedAllRows((prevState) => !prevState);
    setSelectedAdmin(false);
    isSelectAllEvent = true;
    (listbox.dataSource as object[]).forEach((sdata, index) => {
      sdata["Visible"] = selectedAllRows;
    });
    listbox.refresh();
  }

  let hiddenRows: any[] = [];
  async function hideAndUnHideAllAdminRows() {
    // hideUnHideAdmins = type === "Hide Admin" ? true : false;
    setHideUnHideAdmins((prevState) => !prevState);
    if (hideUnHideAdmins === true) {
      (listbox.dataSource as object[]).forEach((sdata: any, index) => {
        if (sdata["IsAdmin"] === true) {
          listbox.getRowByIndex(index).style.display = "none"; //hide row
          hiddenRows.push(index); // add row index to hiddenRows array
          // listbox.dataSource = listbox.dataSource.filter(
          //   (item) => item?.IsAdmin !== sdata?.IsAdmin
          // );
        }
      });
    }


    if (hideUnHideAdmins === false) {
      console.log("show admin");
      console.log(hiddenRows);
      hiddenRows.forEach((rowIndex) => {
        listbox.getRowByIndex(rowIndex).style.display = "";
      });
      hiddenRows = [];
      listbox.refresh();
    }
    //listbox.refresh();
  }

  function onRowDataBound(args): void {
    if (args.data["IsAdmin"] == true) {
      args.row.classList.add("rowcolor");
      return;
    }
  }

  const cellStyle: CellStyleModel = {
    fontFamily: "Calibri",
    fontSize: "9pt",
    textAlign: "right",
  };

  const changeHandler = (event) => {
    SetVlItem(event.value);
  };

  const sortactionBegin = (args) => {
    if (args.requestType !== 'sorting')
      return;
    tempGridDataSource = [];
  }


  const sortactionComplete = (args) => {
    //console.log(args);
    if (args.requestType !== 'sorting')
      return;
    isGridSorting = true;
    if (args.direction == undefined) {//default
      args.cancel = true;
      return;
    }
    else {
      args.rows.forEach((row) => {
        // console.log(row.data);
        tempGridDataSource.push(row.data);
      });
    }
    listbox.dataSource = tempGridDataSource;
  }


  return (
    <div className="App spinner-target" id="target">
      <DialogComponent
        ref={(dialog) => (dialogInstance = dialog)}
        width="340px"
        header={"Data List"}
        id="dialog"
        allowDragging={true}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
        animationSettings={settings}
        buttons={alertDlgButtonswithNew}
        target={"#target"}
      >
        <div className="policies-list-content">
          <DropDownListComponent
            id="ddlelement"
            ref={(scope) => {
              dropDownListObject = scope;
            }}
            dataSource={dropdownData}
            placeholder="Select any value"
            fields={fields}
          />

          <ButtonComponent className="refresh-icon-btn"
            cssClass="e-primary" iconCss="e-icons e-refresh" onClick={() => RefreshObjects()} ></ButtonComponent>
        </div>

      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance1 = dialog)}
        width="340px"
        id="dialog1"
        isModal={true}
        header="Data List"
        buttons={alertDlgButtonswithNew}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        animationSettings={settings} allowDragging={true}
        beforeOpen={onBeforeOpen}
        target={"#target"}
      >
        <div className="policies-list-content">
          <MultiSelectComponent
            id="ddlelement1"
            onChange={onMultiSelect}
            ref={(scope) => {
              multiSelectListObject = scope;
            }}
            dataSource={multiSelectData}
            placeholder="Select any value"
            mode="Delimiter"
            delimiterChar=";"
          />
          <ButtonComponent className="refresh-icon-btn"
            cssClass="e-primary" iconCss="e-icons e-refresh" onClick={() => RefreshObjects()} ></ButtonComponent>
        </div>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance2 = dialog)}
        width="340px"
        header={"Data List"}
        animationSettings={settings}
        id="dialog2"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
        buttons={alertDlgButtons}
      >
        <DateTimePickerComponent
          id="datetimepicker"
          value={dateValue}
          ref={(scope) => {
            dateTimePickerObject = scope;
          }}
          placeholder="Select a date and time"
          format={"dd/MM/yyyy HH:MM a"}
        ></DateTimePickerComponent>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance3 = dialog)}
        width="340px"
        header={"Column Headers"}
        animationSettings={settings}
        id="dialog3"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
      >
        <MultiSelectComponent
          ref={(scope) => {
            mulObj = scope;
          }}
          dataSource={PropertyHeaders}
          width="250px"
          select={onCheckSelect}
          removed={onCheckRemove}
          showClearButton={false}
          showDropDownIcon={true}
          mode="CheckBox"
          popupHeight="350px"
          popupWidth="340px"
          value={headers}
          closePopupOnSelect={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance4 = dialog)}
        width="340px"
        header={"Data List"}
        id="dialog"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
        animationSettings={settings}
        buttons={alertDlgButtons}
      >
        <DropDownListComponent
          id="ddlelement"
          ref={(scope) => {
            dropDownListBooleanObject = scope;
          }}
          dataSource={booleandropdownData}
          placeholder="Select any value"
          fields={fields}
        />
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance6 = dialog)}
        width="340px"
        header={"Admin Columns"}
        animationSettings={settings}
        id="dialog6"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
      >
        <MultiSelectComponent
          ref={(scope) => {
            mulObj1 = scope;
          }}
          dataSource={adminColumns}
          width="250px"
          select={onCheckSelectAdminCol}
          removed={onCheckRemoveAdminCol}
          showClearButton={false}
          showDropDownIcon={true}
          mode="CheckBox"
          popupHeight="350px"
          popupWidth="340px"
          value={headers}
          closePopupOnSelect={true}
        >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance7 = dialog)}
        width="600px"
        allowDragging={true}
        animationSettings={settings}
        id="dialog7"
        position={firstPosition}
        buttons={reorderbuttons}
        visible={false}
        // showCloseIcon={true}
        // close={dialogClose}
        header={"Arrange Columns"}
        target={"#target"}
        minHeight={isTablet ? 650 : isIsLaptop ? 670 : 660}
      >
        <div className="arrange-top-button">
          <ButtonComponent
            type="submit"
            cssClass="e-primary"
            onClick={() =>
              selectedAllRows ? selectAllRows() : unselectAllRows()
            }
            content={selectUnSelectButton}
          />
          {/* <ButtonComponent
            type="submit"
            cssClass="e-primary"
            onClick={() => unselectAllRows()}
          >
            Unselect All
          </ButtonComponent> */}
          <ButtonComponent
            type="submit"
            cssClass="e-primary"
            onClick={() =>
              selectedAdmin ? selectAllAdminRows() : unselectAllAdminRows()
            }
            // content={selectUnSelectAdminButton}
            content={"Select / Unselect Admin"}
          />
          {/* <ButtonComponent
            type="submit"
            cssClass="e-primary"
            onClick={() => unselectAllAdminRows()}
            >
            Unselect Admin
          </ButtonComponent> */}
          <ButtonComponent
            type="submit"
            cssClass="e-primary"
            onClick={() => hideAndUnHideAllAdminRows()}
            // content={hideUnHideAdminButton}
            content={"Hide / Unhide Admin"}
          />
        </div>
        <div id="reorder">
          <GridComponent
            ref={(scope) => {
              listbox = scope;
            }}
            rowDataBound={onRowDataBound}
            allowRowDragAndDrop={true}
            height={isTablet ? 350 : isIsLaptop ? 400 : 380}
            rowSelected={onRowSelected}
            dataBound={dataBound}
            selectionSettings={{ type: "Multiple", checkboxMode: "Default" }}
            allowSorting={true} actionBegin={sortactionBegin} actionComplete={sortactionComplete}
          >
            <Inject services={[RowDD, Selection, Sort]} />
          </GridComponent>
        </div>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance5 = dialog)}
        position={firstPosition}
        width="400px"
        header={"About View Maven"}
        id="dialog5"
        animationSettings={settings}
        buttons={buttons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="helpdialog">
          Version : {version}
          <br />
          Vault : {vName}
          <br />
          Vault GUID : {vaultId}
          <br />
          Copyright 2023, Baystream Corporation.
        </div>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance8 = dialog)}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={buttons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="alertdialog">
          <p>Please select atleast one column to display the view !!</p>
        </div>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance9 = dialog)}
        width="340px"
        header={"Transform To View"}
        id="dialog9"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
        animationSettings={settings}
        buttons={transformbuttons}
      >
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultObject = scope;
          }}
          change={onObjectChange}
          dataSource={vaultObjects}
          placeholder="Select any object type"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultClass = scope;
          }}
          dataSource={vaultClasses}
          change={onClassChange}
          placeholder="Select any class"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownListUniqueProperty = scope;
          }}
          enabled={false}
          placeholder="Select any unique property"
          fields={list1}
        />
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance10 = dialog)}
        width="340px"
        header={"Select Column Header"}
        id="dialog10"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        animationSettings={settings}
        buttons={colHeaderButtons}
      >
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultObject1 = scope;
          }}
          cssClass="e-custom"
          change={onObjectChange1}
          dataSource={vaultObjects}
          id="object1"
          placeholder="Select any object type"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultClass1 = scope;
          }}
          cssClass="e-custom1"
          dataSource={vaultClasses}
          change={onClassChange}
          id="class1"
          placeholder="Select any class"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownColHeaderObject = scope;
          }}
          placeholder="Select any property"
          fields={list1}
        />
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance12 = dialog)}
        width="340px"
        header={"Select Class"}
        id="dialog12"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
        animationSettings={settings}
        buttons={selectclassbuttons}
      >
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultClass2 = scope;
          }}
          placeholder="Select any class"
          fields={list1}
        />
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance11 = dialog)}
        width="340px"
        animationSettings={settings}
        id="dialog11"
        header={"Select a date"}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
        buttons={alertDlgButtons}
      >
        <DatePickerComponent
          id="datepicker"
          value={dateValue}
          ref={(scope) => {
            datePickerObject = scope;
          }}
          placeholder="Select a date"
          format={"dd/MM/yyyy"}
        ></DatePickerComponent>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance13 = dialog)}
        width="340px" allowDragging={true}
        animationSettings={settings}
        id="dialog11"
        header={"Add Valuelist Item"}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        beforeOpen={onBeforeOpen}
        buttons={valueListButtons}
      >
        <TextBoxComponent
          value={vlItem}
          onChange={(e) => changeHandler(e)}
          className="e-input"
        />
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (dialogInstance14 = dialog)}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={errorbuttons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="alertdialog">
          <p>Obligatory values missing in one or more objects !!</p>
        </div>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance15 = dialog)}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={alertErrorbuttons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="alertdialog-big-font">
          <p>You cannot edit this column !!</p>
        </div>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance16 = dialog)}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={alertErrorbuttons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="alertdialog-big-font">
          <p>Select all admin columns to save the ViewReport !!</p>
        </div>
      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (dialogInstance16 = dialog)}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={alertErrorbuttons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="alertdialog-big-font">
          <p>The object has already been checked out !!</p>
        </div>
      </DialogComponent>

      <input
        ref={inputOpenFileRef}
        type="file"
        onChange={fileMerge}
        style={{ display: "none" }}
      />
      <input
        ref={inputOpenFileRef1}
        type="file"
        onChange={fileSelect}
        style={{ display: "none" }}
      />

      <SpreadsheetComponent
        id="spreadsheet"
        cellStyle={cellStyle}
        className="spreadsheet-component"
        ref={SSObj}
        allowOpen={true}
        openUrl={SERVER_URL + "provider/open"}
        created={onCreated}
        allowSave={true}
        saveUrl={SERVER_URL + "provider/save"}
        beforeSave={onBeforeSave}
        contextMenuBeforeOpen={onContextMenuBeforeOpen}
        contextMenuItemSelect={onContextMenuItemSelect}
        fileMenuItemSelect={onFileMenuItemSelect}
        fileMenuBeforeOpen={onFileMenuBeforeOpen}
        selectionSettings={{ mode: "Multiple" }}
        openComplete={onOpenComplete}
        // showFormulaBar={false}
        // showAggregate={false}
        // allowHyperlink={true}
        cellEditing={onCellEditing}
        saveComplete={OnSaveComplete}
        actionBegin={actionBegin}
        beforeSort={onBeforeSort}
        sortComplete={onSortComplete}
        beforeOpen={onBeforeOpen}
        // allowNumberFormatting={false}
        //allowDelete={false}
        cellEdit={onCellEdit}
      ></SpreadsheetComponent>
    </div>
  );
}
export default Home;
