import React from "react";

const LoadingComponents = ({ loading }) => {
  return (
    <div>
      {loading && (
        <div className="loading-overlay">
          <div className="spinner-border text-primary" role="status">
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingComponents;
