/* eslint-disable no-loop-func */

import { useMsal } from "@azure/msal-react";
import { registerLicense, L10n, closest } from "@syncfusion/ej2-base";
import {
  DateTimePickerComponent,
  DatePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import {
  GridComponent,
  GroupSettingsModel,
  Group,
  Selection,
  RowDD,
  getObject,
  Reorder,
} from "@syncfusion/ej2-react-grids";
import {
  CheckBoxSelection,
  DropDownListComponent,
  Inject,
  MultiSelectComponent,
  MultiSelectChangeEventArgs,
  ListBoxComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  DialogComponent,
  PositionDataModel,
} from "@syncfusion/ej2-react-popups";
import {
  BeforeSaveEventArgs,
  CellEditEventArgs,
  CellModel,
  getCell,
  getCellAddress,
  getRangeAddress,
  getRangeIndexes,
  SaveCompleteEventArgs,
  BeforeSortEventArgs,
  SpreadsheetComponent,
  getSheetName,
  getColumnHeaderText,
  UsedRangeModel,
  Column,
  Merge,
  getColumn,
} from "@syncfusion/ej2-react-spreadsheet";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import "../App.css";
import { SERVER_URL } from "../environment/env_dev";
import { group } from "console";
import useIsMobile from "../hooks/ListenToResize";
import { useNavigate } from "react-router-dom";

L10n.load({
  "en-US": {
    spreadsheet: {
      Home: "Format",
      View: "Arrange",
    },
  },
});

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5WdkJjW39fdHRVQWla"
);
var token = "";
var propIds: Map<string, CellModel> = new Map<string, CellModel>();
var rangeAddress: string;
var rowIndex: number;
var colIndex: number;
var objTypeIdCol: number;
var objIdCol: number;
var objClassCol: number;
var editLinkCol: number;
var modifiedCol: number;
var updatedCol: number;
var updatedLink: string;
var removedCol: number;
var dialog: any;
var dialog1: any;
var issheetRepeated: boolean;
var dropdownData: any;
var multiSelectData: any;
var booleandropdownData: any[] = [];
var vaultObjects: any[] = [];
var vaultClasses: any[] = [];
var transformedSheet: any[] = [];
var query: any;
var dropDownListObject: any;
var multiSelectListObject: any;
var dateTimePickerObject: any;
var datePickerObject: any;
var dropDownListBooleanObject: any;
var dropDownListVaultObject: any;
var dropDownListVaultClass: any;
var dropDownColHeaderObject: any;
var dropDownListUniqueProperty: any;
var dropDownListVaultObject1: any;
var dropDownListVaultClass1: any;
var dropDownListVaultObject2: any;
var dropDownListVaultClass2: any;

var isRearranging: boolean;
var currentRow: any;
var currentCol: any;
var propDataType: any;
var uploadId: any;
var uploadSize: any;
var propEditable: any;
var propId: any;
var propName: any;
var objTypeId: any;
var clsId: any;
var currentValue: any;
var prevValue: any;
var modifiedValue: any;
var usedRowIdx: any;
var usedColIdx: any;
var dialogInstance: any;
var dialogInstance1: any;
var dialogInstance2: any;
var dialogInstance3: any;
var dialogInstance4: any;
var dialogInstance5: any;
var dialogInstance6: any;
var dialogInstance7: any;
var dialogInstance8: any;
var dialogInstance9: any;
var dialogInstance10: any;
var dialogInstance11: any;
var dialogInstance12: any;
var modifiedRows: number[] = [];
var newRows: number[] = [];
var newData: any[] = [];
var PropertyValue: any;
var PropertyHeaders: any[] = []; //properties of the object
var columnHeaders: any[] = []; //columns of the excel/spreadsheet
var reorderColumnHeaders: any[] = []; //columns of the excel/spreadsheet
var avladminColumnsSource: any[] = [];
var avladminColumns: any[] = [];
var position: PositionDataModel;
var headers: any[] = [];
var adminColumns: any[] = [];
var menuItems: any[] = [];
var headersValue: string;
var mulObj: any;
var mulObj1: any;
var listbox: any;
var existingpropIds: any[] = [];
var lookupIds: any[] = [];
var multiselectProperties: any[] = [];
var singleselectProperties: any[] = [];
var currentColumnOrder: any[] = [];
var ownerPDValue: any;
var ownerId: any;
var host: string;
var vaultId: string;
var docId: string;
var fileId: string;
var multiSelectDataItem: string;
var IsFileSave: boolean = false;
var IsLoaded: boolean = false;
var DisplayAdminCol: boolean = true;
var IsMultipleClass: boolean = false;
var vName: string;
var vaultId: string;
var version: string;
var mergeFileData: any;
var authType;
var inputOpenFileRef: any;
var inputOpenFileRef1: any;
var clsName: any;
var objTypeName: any;
let memoryData: any[] = [];
let tmpmemoryData: any[] = [];
let sheetMemoryData: any[] = [];
let adminColData: any[] = [];
var list1 = { text: "Name", value: "ID" };
var request: RequestInit = {
  method: "GET",
};
var g_obj_type_id: any;
var g_obj_type_name: any;
var g_cls_id: any;
var g_cls_name: any;

function BlankReport() {
  const { instance } = useMsal();
  inputOpenFileRef = React.createRef();
  inputOpenFileRef1 = React.createRef();
  sessionStorage.setItem("isHomeNavigate", "false");

  const navigate = useNavigate();
  const SSObj = React.useRef<SpreadsheetComponent>(null);
  authType = localStorage.getItem("authType");
  if (sessionStorage.getItem("vaultId") != null) {
    vaultId = sessionStorage.getItem("vaultId")!.toString();
  }
  if (sessionStorage.getItem("vaultName") != null) {
    vName = sessionStorage.getItem("vaultName")!.toString();
  }
  if (sessionStorage.getItem("appVersion") != null) {
    version = sessionStorage.getItem("appVersion")!.toString();
  }

  const handleConnectServer = () => {
    sessionStorage.setItem("server", "null");
    //sessionStorage.setItem("vaultId", "null");
    sessionStorage.setItem("vaultName", "null");
    sessionStorage.setItem("fileId", "null");
    sessionStorage.setItem("isServerConnect", "true");
    navigate("/");
  };

  const handleConnectMFiles = () => {
    sessionStorage.setItem("fileId", "null");
    sessionStorage.setItem("isVaultConnect", "true");
    navigate("/");
  };
  

  useEffect(() => {
    let spreadsheet = SSObj.current;
    spreadsheet?.addRibbonTabs([ 
      {
      header: { text: "M-Files" },
        content: [
          {
            text: "Connect To Server",
            tooltipText: "Connect To Server",
            prefixIcon: "e-save",
            click: () => {
              handleConnectServer();
            },
          }
          // {
          //   text: "Connect To Vault",
          //   tooltipText: "Connect To Vault",
          //   prefixIcon: "e-save",
          //   click: () => {
          //     handleConnectMFiles();
          //   },
          // }
         
        ],
      },  
      {
        header: { text: "Help" },
        content: [
          {
            text: "About View Maven",
            tooltipText: "About View Maven",
            prefixIcon: "e-paste",
            showAlwaysInPopup: true,
            click: () => {
              Help();
            },
          },
        ],
      },
    ]);
  
    spreadsheet?.hideToolbarItems("Arrange", [4, 5, 6]);
  }, []);

  let dateValue: Date = new Date();

  docId = sessionStorage.getItem("docId")!;
  fileId = sessionStorage.getItem("fileId")!;

  vaultId = sessionStorage.getItem("vaultId")!;

  host = sessionStorage.getItem("host")!;
  host = host + "/REST/";

  //pop-up dialog
  position = { X: "center", Y: "center" };
  const firstPosition = { X: "center", Y: "center" };
  let settings: any = { effect: "Zoom", duration: 400, delay: 0 };

  let alertDlgButtons: any = [
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        let spreadsheet = SSObj.current;
        let sheetName = spreadsheet?.getActiveSheet().name;
        currentValue = getCell(
          currentRow,
          currentCol,
          spreadsheet?.getActiveSheet()!
        )?.value;
        if (propDataType == 9) {
          if (currentValue != dropDownListObject.value) {
            spreadsheet?.updateCell(
              {
                value: dropDownListObject.text,
                index: dropDownListObject.value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 10) {
          if (currentValue != multiSelectListObject.value) {
            var value = multiSelectDataItem.substring(
              0,
              multiSelectDataItem.length - 1
            );
            spreadsheet?.updateCell(
              {
                value: value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 7) {
          if (currentValue != dateTimePickerObject.previousElementValue) {
            spreadsheet?.updateCell(
              {
                value: dateTimePickerObject.previousElementValue,
                index: dateTimePickerObject.previousElementValue,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 5) {
          if (currentValue != datePickerObject.previousElementValue) {
            spreadsheet?.updateCell(
              {
                value: datePickerObject.previousElementValue,
                index: datePickerObject.previousElementValue,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (propDataType == 8) {
          if (currentValue != dropDownListBooleanObject.value) {
            spreadsheet?.updateCell(
              {
                value: dropDownListBooleanObject.text,
                index: dropDownListBooleanObject.value,
                style: {
                  fontFamily: "Calibri",
                  fontSize: "9pt",
                  textAlign: "right",
                },
              },
              getCellAddress(currentRow, currentCol)
            ); //  to specify the cell value, range
          }
        }
        if (columnHeaders.indexOf("Modified") !== -1)
          spreadsheet?.updateCell(
            { value: "True" },
            getCellAddress(currentRow, columnHeaders.indexOf("Modified"))
          ); //  to specify the cell value, range

        //set Modified flag in In-Memory data
        memoryData = sheetMemoryData?.find(
          (x) => x.SheetName === sheetName
        )?.MemData;
        let item = memoryData && memoryData[currentRow - 1];
        if (item !== undefined) item["Modified"] = "True";

        dialogInstance.hide();
        dialogInstance1.hide();
        dialogInstance2.hide();
        dialogInstance11.hide();
        dialogInstance4.hide();
      },
    },
  ];

  let colHeaderButtons: any = [
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        let spreadsheet = SSObj.current;
        let sheetName = spreadsheet?.getActiveSheet().name;
        currentValue = getCell(
          currentRow,
          currentCol,
          spreadsheet?.getActiveSheet()!
        )?.value;
        if (currentValue !== dropDownColHeaderObject.value) {
          spreadsheet?.updateCell(
            {
              value: dropDownColHeaderObject.text,
              index: dropDownColHeaderObject.value,
            },
            getCellAddress(currentRow, currentCol)
          ); //  to specify the cell value, range
        }
        if (adminColData.find((x) => x.SheetName === sheetName) === undefined) {
          adminColData.push({
            Cls: dropDownListVaultClass1.text,
            ObjType: dropDownListVaultObject1.text,
            SheetName: spreadsheet?.getActiveSheet().name,
          });
        } else {
          var tmp = adminColData.find((x) => x.SheetName === sheetName);
          tmp.Cls = dropDownListVaultClass1.text;
          tmp.ObjType = dropDownListVaultObject1.text;
        }

        let newcolName = dropDownColHeaderObject.text;
        var tmpHeader = reorderColumnHeaders.find(
          (x) => x.SheetName === sheetName && x.Title === currentValue
        )!;

        var tmpHeader1 = reorderColumnHeaders.find(
          (x) => x.SheetName === sheetName && x.Title === newcolName
        )!;
        var tmpHeaderIndex1 = reorderColumnHeaders.findIndex(
          (x) => x.SheetName === sheetName && x.Title === newcolName
        )!;
        if (tmpHeader !== undefined) {
          tmpHeader.Title = newcolName;
        }
        if (tmpHeader1 !== undefined) {
          reorderColumnHeaders.splice(tmpHeaderIndex1, 1);
          //tmpHeader1.Title = currentValue;
        }
        var index = columnHeaders.indexOf(currentValue);

        if (index !== -1) {
          columnHeaders[index] = newcolName;
        }

        memoryData = sheetMemoryData.find(
          (x) => x.SheetName === sheetName
        )?.MemData;
        if (memoryData === undefined) {
          //sheet not yet transformed and class not yet defined
        }
        memoryData?.forEach(function (row, i) {
          row[newcolName] = row[currentValue];
          // console.log(row);
        });
        dialogInstance10.hide();
      },
    },
  ];

  async function onFileMenuItemSelect(args: any) {
    let spreadsheet = SSObj.current;
    if (args.item.text === "Load New File") {
      console.log('ssss');
      inputOpenFileRef.current.click();
    }
    if (args.item.text === "Upload File") {
      inputOpenFileRef1.current.click();
    }
    if (
      args.item.text !== "New" ||
      "Open" ||
      "Load New File" ||
      "Save As" ||
      "Merge Sheet" ||
      "File"
    ) {
      //merge sheet is clicked
      spreadsheet?.showSpinner();
      var sheetName: any;
      //console.log(mergeFileData);
      if (mergeFileData != null) {
        mergeFileData.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            return;
          }
          var data = JSON.parse(temp);
          if (sheetName === args.item.text) {
            let row = spreadsheet?.getActiveSheet().usedRange?.rowIndex! + 1;
            data.forEach(function (item, i) {
              for (let j = 0; j < columnHeaders.length; j++) {
                spreadsheet!.updateCell(
                  { value: item[columnHeaders[j]] },
                  getSheetName(spreadsheet!, 0) +
                    "!" +
                    getCellAddress(i + row - 1, j)
                );
              }
             // AddRow(row + i);
            });
          }
        });
      }
      spreadsheet?.hideSpinner();
    }
  }

 

  async function actionBegin(args: any) {
    let spreadsheet = SSObj.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    memoryData = sheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    if (args.action === "autofill") {
      let range: number[] = getRangeIndexes(args.args.eventArgs.fillRange);
      let startRow = range[0];
      let endRow = range[2];
      for (let i = startRow; i <= endRow; i++) {
        if (columnHeaders.indexOf("Modified") !== -1)
          spreadsheet?.updateCell(
            { value: "True" },
            getCellAddress(i, columnHeaders.indexOf("Modified"))
          );
        let item = memoryData && memoryData[i - 1];
        if (item !== undefined) item["Modified"] = "True";
      }
    }
    if (args.action === "beforeOpen") {
      spreadsheet?.enableToolbarItems("M-Files", [0], true);
      spreadsheet?.enableToolbarItems("M-Files", [2], false);
    }
    if (args.action === "gotoSheet") {
      listbox.dataSource = [];
      if (args.args.eventArgs.currentSheetIndex === 0) {
        //ViewReport
        spreadsheet?.enableToolbarItems("M-Files", [0], true);
        spreadsheet?.enableToolbarItems("M-Files", [1], true);
        spreadsheet?.enableToolbarItems("M-Files", [2], false);
      } else if (args.args.eventArgs.currentSheetIndex !== 0) {
        //ViewReport
        spreadsheet?.enableToolbarItems("M-Files", [0], false);
        spreadsheet?.enableToolbarItems("M-Files", [2], true);
      } else {
        spreadsheet?.enableToolbarItems("M-Files", [0], true);
        spreadsheet?.enableToolbarItems("M-Files", [2], false);
      }
      let sheetName =
        spreadsheet?.sheets[args.args.eventArgs.currentSheetIndex].name;
     // await GetObjectProperties(1, args.args.eventArgs.currentSheetIndex);
      if (transformedSheet.indexOf(sheetName) !== -1) {
        spreadsheet?.enableToolbarItems("M-Files", [2], false);
       // LoadMemoryDataFromSheet(args.args.eventArgs.currentSheetIndex, false);
        let item: any = memoryData && memoryData[0];
        g_obj_type_id = item && item["Object Type"];
        g_obj_type_name = item && item["Object Name"];
        g_cls_id = item && item["Object Class"];
        g_cls_name = item && item["Class Name"];
      }
      dropDownListVaultClass!.value = null;
      dropDownListVaultClass1!.value = null;
      dropDownListVaultObject!.value = null;
      dropDownListVaultObject1!.value = null;
    }
  }

  async function RunAPI(url: string, request: RequestInit) {
    var response;
    try {
      token = localStorage.getItem("token")!;
      if (authType === "MFiles") {
        if (url.includes("?")) {
          url = url + "&auth=" + token;
        } else {
          url = url + "?auth=" + token;
        }
        request.headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      } else {
        //console.log(token);
        sessionStorage.setItem("isAuthenticated", "true");
        request.headers = {
          Authorization: "Bearer " + token,
          "X-Vault": vaultId,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      }
      return response;
    } catch (error) {
      console.log("Error : " + error);
      return response;
    }
  }



  

  const onFileMenuBeforeOpen = (args: any) => {
    let spreadsheet = SSObj.current;
    if (args.items.length === 3) {
      spreadsheet?.addFileMenuItems(
        [{ text: "Load New File", iconCss: "e-open e-icons" }],
        "Open",
        false
      );
    }
    spreadsheet?.hideFileMenuItems(["Open"]);
  };

  //add context menu
  
  async function fileMerge() {
    issheetRepeated = false;
    menuItems = [];
    let spreadsheet = SSObj.current;
    let sheetCount = spreadsheet?.sheets.length!;
    //await LoadPropertyValues();
    var file = inputOpenFileRef.current.files[0];
    spreadsheet?.showSpinner();
    var formData = new FormData();
    formData.append("file", file);
    fetch(SERVER_URL + "provider/insertRecord", {
      method: "POST",
      body: formData,
    }).then((response) => {
      response.json().then((data) => {
        mergeFileData = data;
        var sheetName;
        var j = sheetCount;
        data.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            issheetRepeated = false;
            if (spreadsheet?.sheets.find((x) => x.name === sheetName)) {
              issheetRepeated = true;
            }
            menuItems.push({
              text: sheetName,
              iconCss: "e-xlsx e-icons",
              repeated: issheetRepeated,
            });
          }
        });
        if (menuItems.find((x) => x.repeated === true)) {
          var index = menuItems.find((x) => x.repeated === true);
          if (index !== undefined) {
            alert(
              "The application already contains sheet with the name " +
                index.text +
                ". Please rename and then insert again."
            );
            spreadsheet?.hideSpinner();
            return;
          }
        }
        data.forEach(function (temp, i) {
          if (i % 2 === 0) {
            sheetName = temp;
            return;
          }
          var data = JSON.parse(temp);
          spreadsheet?.insertSheet([
            {
              index: j,
              name: sheetName,
              ranges: [{ dataSource: data }],
            },
          ]);
          if (data.length > 0) {
            var rowIndex = data.length + 1;
            var keyCount = Object.keys(data[0]).length;
            let colHeaderText = getColumnHeaderText(keyCount);
            let columnRange: string =
              getColumnHeaderText(1) + ":" + colHeaderText;
            var tmp = sheetName + "!" + columnRange;
            spreadsheet?.setColumnsWidth(120, [tmp]);
            //set font for header
            let columnRange1: string =
              getColumnHeaderText(1) + "1:" + colHeaderText + "1";
            var tmp1 = sheetName + "!" + columnRange1;

            let columnRange2: string =
              getColumnHeaderText(1) + "2:" + colHeaderText + rowIndex;
            var tmp2 = sheetName + "!" + columnRange2;

            spreadsheet?.cellFormat(
              {
                fontSize: "10pt",
                fontFamily: "Calibri",
                textAlign: "center",
                fontWeight: "bold",
                backgroundColor: "#D3D3D3",
              },
              tmp1
            );
            spreadsheet?.cellFormat(
              { fontSize: "9pt", fontFamily: "Calibri" },
              tmp2
            );
            spreadsheet?.freezePanes(1, 0, j);
          }
          j++;
        });

        //spreadsheet?.addFileMenuItems(
        //    [{
        //        text: 'Exit', iconCss: 'e-save e-icons', items: menuItems
        //    }],
        //    'Save As', true);
        spreadsheet?.hideSpinner();
        // spreadsheet?.refresh();
      });
    });
  }  



  async function onOpenComplete(args: any) {
    
  }


  const Help = () => {
    dialogInstance5?.show();
  };

  let buttons = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        dialogInstance5?.hide();
        dialogInstance8?.hide();
      },
    },
  ];

  function dialogClose() {
    dialogInstance5?.hide();
  }

  return (
    <div className="App spinner-target" id="target">
      <DialogComponent
        ref={(dialog) => (dialogInstance5 = dialog)}
        position={firstPosition}
        width="400px"
        header={"About View Maven"}
        id="dialog5"
        animationSettings={settings}
        buttons={buttons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="helpdialog">
          Version : {version}
          <br />
          Vault : {vName}
          <br />
          Vault GUID : {vaultId}
          <br />
          Copyright 2023, Baystream Corporation.
        </div>
      </DialogComponent>    
      <input
        ref={inputOpenFileRef}
        type="file"
        onChange={fileMerge}
        style={{ display: "none" }}
      />

      <SpreadsheetComponent
        id="spreadsheet"
        className="spreadsheet-component"
        ref={SSObj}
        allowOpen={true}
        openUrl={SERVER_URL + "provider/open"}
        allowSave={true}
        saveUrl={SERVER_URL + "provider/save"}
        fileMenuBeforeOpen={onFileMenuBeforeOpen}
        fileMenuItemSelect={onFileMenuItemSelect}
        selectionSettings={{ mode: "Multiple" }}
        openComplete={onOpenComplete}
        allowDelete={false}
      ></SpreadsheetComponent>
    </div>
  );
}
export default BlankReport;
