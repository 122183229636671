/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import { useNavigate } from "react-router-dom";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import useVaults from "../useVaults";
import "../App.css";
import LoadingComponents from "../components/LoadingComponents";
import { toast } from "react-toastify";
import checked from "../Icons/check-circle-fill.svg"
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXhTcEVhW3dbcXNQTmg="
);

var serverText: any;
var vaultListObject: any;
var viewReportListObject: any;
var vaultListData: any;
var viewReportListData: any;
var classListObject: any;
var classListData: any;
var docId: any;
var fileId: any;
var vaultId: any;
var servertoken: any;

const ConnectServer = () => {
  const [server, setServer] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [Cls, setCls] = useState("");
  const [ClsId, setClsId] = useState("");
  var list = { text: "Name", value: "GUID" };
  var classlist = { text: "Name", value: "ID" };
  var list1 = { text: "Title", value: "ObjVer.ID" };
  const [isLoading, setIsloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  var token = localStorage.getItem("token") as string;
  var isAuthorized = sessionStorage.getItem("isAuthorized");
  var authType = localStorage.getItem("authType");
  var server1 = sessionStorage.getItem("host");
  vaultId = sessionStorage.getItem("vaultId");
  var clsName;
  var request: RequestInit = {
    method: "GET",
  };

  useEffect(() => {
    if (sessionStorage.getItem("host") !== "")
      setServer(sessionStorage.getItem("host") as string);
    if (sessionStorage.getItem("user") !== "")
      setUser(sessionStorage.getItem("user") as string);
    if (sessionStorage.getItem("vaultId") !== "") {
      vaultListObject!.value = sessionStorage.getItem("vaultId");
    }
  }, []);

  useEffect(() => {
    if (servertoken !== null && servertoken !== "null" && servertoken !== "" && server1 !== null && server1 !== "null") {
      fetchVaults("");
    }
  }, [token]);


  var host = server1 + "/REST/";
  if (server1 != null && server1 !== "null") {
    let temp;
    if (host.includes("http://")) {
      temp = host!.replace("http://", "");
    }
    if (host.includes("https://")) {
      temp = host!.replace("https://", "");
    }

    let index = temp?.indexOf(".");
    let server = temp?.substring(0, index);

    if (server1.includes("-")) {
      let idx = server1?.indexOf("-");
      server1 = server1?.substring(0, idx);
    }
    sessionStorage.setItem("server", server1);
    let isClientCall = sessionStorage.getItem("isClientCall");
    if (isClientCall === "true" && (vaultId !== localStorage.getItem('vaultId') || server1 !== localStorage.getItem('server'))){
     // localStorage.setItem("token", "null")
     }
  }

  const changeHandler = (event) => {
    setServer(event.value);
    setIsError(false);
    setErrorMsg("");
  };
  const changeHandler1 = (event) => {
    setUser(event.value);
    setIsError(false);
    setErrorMsg("");
  };

  const changeHandler2 = (event) => {
    setPassword(event.value);
    setIsError(false);
    setErrorMsg("");
  };

  const fetchVaults = (typeCheck) => {
    console.log("fetchVaults")
    token = localStorage.getItem("token") as string;
    servertoken = sessionStorage.getItem("servertoken") as string;
    host = sessionStorage.getItem("host") + "/REST/";
    if (servertoken != null && servertoken !== "null") {
      setIsloading(true);
      var url = host + "session/vaults";
      if (authType === "MFiles") {
        request.headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Authentication": servertoken,
        };
      } else {
        request.headers = {
          Authorization: "Bearer " + servertoken,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
      }

      fetch(url, request).then((response) => {
        response.json().then((data) => {
          if (data.Status === 403) {
            setIsloading(false);
            setIsError(true);
            setErrorMsg("Invalid user name or password");
            sessionStorage.setItem("isAuthorized", "false");
            sessionStorage.setItem("isServerConnect", "true");
            sessionStorage.setItem("server", "null");
           // navigate("/");
            return;
          }
          if (data?.Status !== undefined && data.Status !== 200) {
            setIsloading(false);
            sessionStorage.setItem("isServerConnect", "true");
            sessionStorage.setItem("server", "null");
            navigate("/");
            return;
          }

          if (data) {
            if (typeCheck === "connectToServer") {
              toast.success("Server is connected successfully!");
            }
            sessionStorage.setItem("isAuthorized", "true");
            if (vaultListObject != null) {
              vaultListObject!.fields = list;
              vaultListObject!.dataSource = data;
            }
            if (
              sessionStorage.getItem("vaultId") !== "null" &&
              vaultListObject != null
            ) {
              vaultListObject!.value = sessionStorage.getItem(
                "vaultId"
              ) as string;
              vaultListObject!.text = sessionStorage.getItem(
                "vaultName"
              ) as string;
            }
            setIsloading(false);
          }
        });
      });
    }
  };

  async function RunAPI(url: string, request: RequestInit) {
    setIsloading(true);
    var response;
    try {
      token = localStorage.getItem("token")!;
      if (authType === "MFiles") {
        if (url.includes("?")) {
          url = url + "&auth=" + token;
        } else {
          url = url + "?auth=" + token;
        }
        request.headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      } else {
        //console.log(token);
        sessionStorage.setItem("isAuthenticated", "true");
        request.headers = {
          Authorization: "Bearer " + token,
          "X-Vault": vaultId,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      }
      setIsloading(false);
      return response;
    } catch (error) {
      console.log("Error : " + error);
      setIsloading(false);
      return response;
    }
  }

  async function onVaultChange(args: any) {
    var clsExist: boolean = false;
    if (args.itemData != null) {
      // setIsloading(true);
      var vaultId = args.itemData!.GUID;
      if (localStorage.getItem("token") === null || localStorage.getItem("token") === "null" || (vaultId !== localStorage.getItem("vaultId") || server1 !== localStorage.getItem("server")) ) { //if selected vault is different from previous vault then get auth token for selected vault
         //Get auth token for selected vault to fetch data
      await getAuthToken(vaultId);
      }
     
      sessionStorage.setItem("vaultId", args.itemData.GUID);
      sessionStorage.setItem("vaultName", args.itemData.Name);

      setCls("");
      setClsId("");
      var url = host + "structure/classes?objtype=0";
      await RunAPI(url, request).then((response) => {
        response.json().then((data) => {
          classListData = data;
          // classListObject!.fields = classlist;
          // classListObject!.dataSource = data;
          if (data != null && data.length > 0) {
            data.forEach(function (item, i) {
              if (item.Name == "View Report") {
                clsExist = true;
                changeHandler3(item.ID, item.Name);
              }
            });
            if (!clsExist) {
              viewReportListData = [];
              alert("View Report class not found in selected vault");
            }
          }
          // setIsloading(false);
        });
      });
    }
  }

  const changeHandler3 = (clsId, clsName) => {
    setClsId(clsId);
    setCls(clsName);
    // setIsloading(true);

    var url = host + "objects/0?p100=" + clsId;
    RunAPI(url, request).then((response) => {
      response.json().then((data) => {
        viewReportListData = data.Items;
        if (viewReportListObject != null) {
          viewReportListObject!.fields = list1;
          viewReportListObject!.dataSource = data.Items;
        }
        if (sessionStorage.getItem("docId") !== "") {
          viewReportListObject!.value = sessionStorage.getItem("docId");
          viewReportListObject!.text = sessionStorage.getItem("fileName");
        }
        // setIsloading(false);
      });
    });
  };

  async function onClassChange(args: any) {
    // setIsloading(true);
    var clsId = args?.itemData?.ID;
    var clsName = args?.itemData?.Name;
    sessionStorage.setItem("selectedclsId", clsId);
    sessionStorage.setItem("selectedclsName", clsName);

    var url = host + "objects/0?p100=" + clsId;
    await RunAPI(url, request).then((response) => {
      response.json().then((data) => {
        viewReportListData = data.Items;
        viewReportListObject.fields = list1;
        viewReportListObject.dataSource = data.Items;
        if (sessionStorage.getItem("docId") !== "") {
          viewReportListObject!.value = sessionStorage.getItem("docId");
          viewReportListObject!.text = sessionStorage.getItem("fileName");
        }
        // setIsloading(false);
      });
    });
  }

  const onObjectChange = (args: any) => {
    docId = args?.itemData?.ObjVer.ID;
    fileId = args?.itemData?.Files[0]?.ID;
    sessionStorage.setItem("docId", docId);
    sessionStorage.setItem("fileId", fileId);
    sessionStorage.setItem("fileName", args?.itemData?.Title);
    // sessionStorage.setItem("isMFilesConnect", "false");
    // sessionStorage.setItem("isConnectVault", "false");
  };

  const OpenReport = () => {
    navigate("/");
  };

  async function getAuthToken(vaultId: any) {
    let user = sessionStorage.getItem("user")!;
    let pwd = sessionStorage.getItem("pwd")!;

    const url = server + "/REST/server/authenticationtokens";
    var authObject = {
      username: user,
      password: pwd,
      VaultGuid: vaultId,
    };
    const request: RequestInit = {
      method: "POST",
      body: JSON.stringify(authObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    var response, data;
    try {
      response = await fetch(url, request);
      data = await response.json();
    } catch (e: any) {
      console.log("Error");
      localStorage.setItem("isError", "true");
      localStorage.setItem("errorMsg", e);
      return;
    }
    localStorage.setItem("token", data.Value);
    localStorage.setItem("server", server);
    localStorage.setItem("vaultId", vaultId);
  }

  async function TestConnectionToServer() {
    const regex = /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidUrl = regex.test(server);
    if (!isValidUrl) {
      setIsError(true);
      setErrorMsg(
        "The server address is not valid, it should start with http or https."
      );
    }
    else {
      const url = server + "/REST/server/status";
      const request: RequestInit = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      try {
        let response = await fetch(url, request);
        let data = await response.json();
        if (data.Successful !== true) {
          setIsError(true);
          setErrorMsg("Connection failed. Please check the server address.");
          return;
        }
        else {
          setIsError(false);
          setErrorMsg("");
          toast.success("Connection successful!");
        }
      }
      catch (e: any) {
        setIsError(true);
        setErrorMsg("Connection failed. Please check the server address.");
      }
    }
  }

  async function ConnectToServer() {
    const regex = /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidUrl = regex.test(server);

    vaultListObject!.dataSource = [];
    if (server === "" || server === null || user === "" || password === "") {
      // alert("Please enter all the fields");
      setIsError(true);
      setErrorMsg("Please enter all the fields");
    } else if (!isValidUrl) {
      setIsError(true);
      setErrorMsg(
        "The server address is not valid, it should start with http or https."
      );
    } else {
      setIsError(false);
      setErrorMsg("");
      sessionStorage.setItem("host", server);
      sessionStorage.setItem("server", server);

      sessionStorage.setItem("user", user);
      sessionStorage.setItem("pwd", password);
      var vaultId = "";
      // if (sessionStorage.getItem("vaultId") !== null) {
      //   vaultId = sessionStorage.getItem("vaultId") as string;
      // }

      // if (server === "" || user === "" || password === "") {
      //   alert("Please enter all the fields");
      //   return;
      // }


      const url = server + "/REST/server/authenticationtokens";
      var authObject = {
        username: user,
        password: password,
        VaultGuid: vaultId,
      };
      const request: RequestInit = {
        method: "POST",
        body: JSON.stringify(authObject),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      var response, data;
      try {
        response = await fetch(url, request);
        data = await response.json();
      } catch (e: any) {
        // alert("There is an error => " + e.message);        
        if (e.message === "Failed to fetch") {
          setIsError(true);
          setErrorMsg("Error in connecting to the server.");
        } else {
          setIsError(true);
          setErrorMsg(e.message);
        }

        setIsloading(false);
        // localStorage.setItem("isError", "true");
        // localStorage.setItem("errorMsg", e);
       // sessionStorage.setItem("isAuthorized", "false");
        sessionStorage.setItem("isServerConnect", "true");
        sessionStorage.setItem("server", "null");
        navigate("/");
        return;
      }

      sessionStorage.setItem("isAuthorized", "true");
      localStorage.setItem("redirect", "false");
      sessionStorage.setItem("servertoken", data.Value);
      // if (sessionStorage.getItem("vaultId") !== null) {
      //   localStorage.setItem("token", data.Value);
      // }
      localStorage.setItem("loggedInUser", user!);
      // toast.success("Server is connected successfully!");
      fetchVaults("connectToServer");
      //navigate("/");
    }
  }

  // for validate server address
  const handleServerChange = () => {
    const regex = /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidUrl = regex.test(server);

    if (!isValidUrl) {
      setIsError(true);
      setErrorMsg(
        "The server address is not valid, it should start with http or https."
      );
    } else {
      setIsError(false);
      setErrorMsg("");
    }
  };

  return (
    // <div className="display-vault-main">
    //   <div className="display-vault-form">
    //     <h1 className="connect-to-m-file">M-Files</h1>
    //     <h6>Welcome To M-Files!</h6>
    //     <div className="connect-file-input mt-4">
    //       <TextBoxComponent
    //         value={server}
    //         onChange={(e) => changeHandler(e)}
    //         placeholder="Server Address"
    //         cssClass="e-outline"
    //         floatLabelType="Auto"
    //       />
    //     </div>
    //     <div className="connect-file-input">
    //       <TextBoxComponent
    //         value={user}
    //         onChange={(e) => changeHandler1(e)}
    //         placeholder="UserName"
    //         cssClass="e-outline"
    //         floatLabelType="Auto"
    //       />
    //     </div>
    //     <div className="connect-file-input mb-5">
    //       <TextBoxComponent
    //         type="password"
    //         value={password}
    //         onChange={(e) => changeHandler2(e)}
    //         placeholder="Password"
    //         cssClass="e-outline"
    //         floatLabelType="Auto"
    //       />
    //     </div>
    //     <input
    //       id="btnlogin"
    //       type="button"
    //       value="Connect"
    //       onClick={ConnectToServer}
    //       className="btn-open-document mb-4"
    //     />
    //   </div>
    //   <div id="loginFooter">
    //     <div id="info">
    //       {isAuthorized === "false" ? (
    //         <div id="msg">Un-Authorized User</div>
    //       ) : (
    //         <div id="msg"></div>
    //       )}
    //     </div>
    //   </div>
    // </div>
    <>
      <LoadingComponents loading={isLoading} />
      <div className="server-connection-main">
        <div className="form--user__icon">
          <div className="icon--img">
            <span className="glyphicon glyphicon-user">
              <h1 className="connect-to-m-file">M</h1>
            </span>
          </div>
        </div>
        <h4 className="text-center mt-4 mb-4 text-bold">
          Welcome To <span className="span-m-file">M-Files!</span>
        </h4>
        <div className="container-box">
          <div className="server-address-connect">
            <div className="form-group mb-2">
              <label htmlFor="server-address">Server address</label>
              <div className="test-connections-div">
              <TextBoxComponent
                value={server}
                onChange={(e) => changeHandler(e)}
                onBlur={() => handleServerChange()}
                placeholder="Server Address"
                className="e-input"
              />
              {/* <input
                id="btn-test-connection"
                type="button"
                value="Test Connection"
                onClick={TestConnectionToServer}
                className="btn-open-document"
              /> */}
              <TooltipComponent position="TopCenter" content="Test Connection" target="#btn-test-connection" height={"auto"} width={"auto"}>
                <img src={checked} id="btn-test-connection" onClick={TestConnectionToServer}/>
              </TooltipComponent>
              </div>
            </div>
            <div className="form-group mb-2">
              <label htmlFor="user-name">User name</label>
              <TextBoxComponent
                value={user}
                onChange={(e) => changeHandler1(e)}
                placeholder="UserName"
                className="e-input"
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="pwd">Password</label>
              <TextBoxComponent  
                type="password"
                value={password}
                onChange={(e) => changeHandler2(e)}
                placeholder="Password"
                className="e-input"
              />              
            </div>

            <input
              id="btnlogin"
              type="button"
              value="Connect"
              onClick={ConnectToServer}
              className="btn-open-document mt-4"
            />
          </div>

          <div className="vault-connect">
            <div className="form-group mb-2">
              <label htmlFor="select-vault">Vault</label>
              <DropDownListComponent
                id="ddlelement"
                change={onVaultChange}
                ref={(scope) => {
                  vaultListObject = scope;
                }}
                placeholder="Select Vault"
                fields={list}
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="document-class">Document Class</label>
              <TextBoxComponent
                value={Cls}
                //  onChange={(e) => changeHandler(e)}
                placeholder="Select Document Class"
              // cssClass="e-outline"
              // floatLabelType="Auto"
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="select-object">Object</label>
              <DropDownListComponent
                id="ddlelement1"
                change={onObjectChange}
                ref={(scope) => {
                  viewReportListObject = scope;
                }}
                dataSource={viewReportListData}
                placeholder="Select Object"
                fields={list1}
              />
            </div>
            <input
              type="button"
              value="Select"
              onClick={OpenReport}
              className="btn-open-document mt-4"
              disabled={isLoading}
            />
          </div>
        </div>
        {/* <div id="loginFooter">
        <div id="info">
          {isAuthorized === "false" ? (
            <div id="msg">Un-Authorized User</div>
          ) : (
            <div id="msg"></div>
          )}
        </div>
      </div> */}
        <div id="loginFooter" className="connect-login-footer mt-4">
          <div id="info">
            {isError === true  ? (
              <div id="msg">{errorMsg}</div>
            ) 
            // :
            //  isAuthorized === "false" ? (
            //   <div id="msg">Invalid user name or password</div>              
            // ) 
            : (
              <div id="msg"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectServer;
