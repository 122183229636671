/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { registerLicense } from '@syncfusion/ej2-base';
import { useNavigate } from 'react-router-dom';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent, MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import useVaults from "../useVaults";
import '../App.css';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF1cVWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjWn9acHVWQ2RaU0x0Vw==');

const MFilesLogin = () => {  
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem('user') !== '')
            setUser(sessionStorage.getItem('user') as string);
       
    }, [])


    const changeHandler = (event) => {
        setUser(event.value);
    };

    const changeHandler1 = (event) => {
        setPassword(event.value);
    };

    async function LoginToMFiles ()  {
        let host = sessionStorage.getItem('host')!;
        console.log(user);
        const url = host + "/REST/server/authenticationtokens";
        var authObject = {
            username: user,
            password: password,
            VaultGuid: ''
        };
        const request: RequestInit = {
            method: 'POST',
            body: JSON.stringify(authObject),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        };
        var response, data;
        try {
            response = await fetch(url, request);
            data = await response.json();
        } catch (e: any) {
            console.log('Error');
            localStorage.setItem('isError', 'true');
            localStorage.setItem('errorMsg', e);
            return;
        }
        localStorage.setItem('redirect', 'false');
        localStorage.setItem('token', data.Value);
        console.log(data.Value);

        localStorage.setItem('authType', 'MFiles');
        //localStorage.setItem('loggedInUser', user!);
        sessionStorage.setItem("isAuthenticated", "true");
        sessionStorage.setItem("isAuthorized", "true");
        sessionStorage.setItem('user', user);
        sessionStorage.setItem('pwd', password);
        navigate('/');
    }
       
    return (      
        <div className='display-vault-main'>
            <div style={{ cursor: 'auto' }} className='display-vault-form'>
                <h1 className='connect-to-file'>M-Files</h1>
                <h6>Welcome To M-Files!</h6>
                <br /><br /> 
                {/*<input className="e-input" type="text" value={user} onChange={(e) => changeHandler(e)} placeholder="UserName" />*/}
                <TextBoxComponent value={user} onChange={(e) => changeHandler(e)} placeholder="UserName" cssClass="e-outline" floatLabelType="Auto" />
                <br /><br />
                {/*<input className="e-input" type="password" value={password} onChange={(e) => changeHandler1(e)} placeholder="Password" />*/}
                <TextBoxComponent type="password"  value={password} onChange={(e) => changeHandler1(e)} placeholder="Password" cssClass="e-outline" floatLabelType="Auto" />
                <br /><br />
                
                <input id="btnlogin" type="button" value="Login" onClick={LoginToMFiles} className='btn-open-document' />
            </div>
            </div>
   
  );
}

export default MFilesLogin;