import { Nav, Navbar, Dropdown, DropdownButton, Button } from "react-bootstrap";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest, B2CRequest } from "../authConfig";
import { Route, Routes, useNavigate } from "react-router-dom";

export const NavigationBar = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  //  if (instance.getActiveAccount() != null || instance.getActiveAccount() != undefined) {
  //      if (localStorage.getItem('authType') === 'MFiles') {
  //         console.log('Frm Nav Bar');
  //              //const scope = "api://" + sessionStorage.getItem('apiClientId')! as string + "/access_as_user";
  //              //loginRequest.scopes.push(scope! as never);
  //              instance.acquireTokenSilent(loginRequest).then(result => {
  //                  if (localStorage.getItem('token') == null || localStorage.getItem('token') === undefined) {
  //                      localStorage.setItem('loggedInUser', result.account?.username!);
  //                     // localStorage.setItem('token', result.accessToken);
  //                      sessionStorage.setItem('isAuthorized', 'true');
  //                      navigate('/');

  //                  }
  //          });
  //      }
  //  }

  const handleConnectServer = () => {
    sessionStorage.setItem("server", "null");
    sessionStorage.setItem("vaultId", "null");
    sessionStorage.setItem("vaultName", "null");
    sessionStorage.setItem("fileId", "null");
    navigate("/");
  };

  const handleConnectMFiles = () => {
    sessionStorage.setItem("fileId", "null");
    navigate("/");
  };

  const handleLogoutRedirect = () => {
    console.log(localStorage.getItem("authType"));
    if (localStorage.getItem("authType") === "MFiles") {
      localStorage.clear();
      localStorage.removeItem("loggedInUser");
      sessionStorage.setItem("redirect", "true");
      sessionStorage.setItem("isAuthorized", "false");
      sessionStorage.setItem("isMFilesLogin", "false");
      sessionStorage.setItem("isAuthenticated", "false");
     // sessionStorage.setItem("server", "null");
      sessionStorage.clear();
      instance.logoutRedirect();
    } else {
      sessionStorage.setItem("isAuthorized", "false");
      localStorage.removeItem("loggedInUser");
      sessionStorage.setItem("isAuthenticated", "false");
      sessionStorage.clear();
      localStorage.removeItem("token");
      localStorage.clear();
      instance.logoutRedirect();
    }
  };

  var loggedInUser = localStorage.getItem("loggedInUser");
  var fileName = sessionStorage.getItem("fileName");
  var vaultName = sessionStorage.getItem("vaultName");
  var server = sessionStorage.getItem("server");
  var header = fileName + " (" + server + " : " + vaultName + ")";
  if (fileName == null) header = "";
  var fileId = sessionStorage.getItem("fileId");

  var token = localStorage.getItem("token");
  //console.log(loggedInUser);
  // console.log(token);

  const onNavHandleClick = () => {
    // sessionStorage.setItem("isMFilesConnect", "false");
    // sessionStorage.setItem("isConnectVault", "false");
    sessionStorage.setItem("isHomeNavigate", "true");
    navigate("/");
  };

  return (
    <>
      <Navbar className="navbar-style">
        <div id="baystreamLogo">
          <span className="navbar-brand" onClick={() => onNavHandleClick()}>
            View Maven
          </span>
        </div>
        {token != null && fileId !== "null" && server !== "null" ? (
          <div id="filename" className="nav-file-name">
            {header}
          </div>
        ) : fileId === "null" || fileId === null ? (
          <div id="filename"></div>
        ) : server === "null" || server === null ? (
          <div id="filename"></div>
        ) : (
          <div id="filename"></div>
        )}
        <AuthenticatedTemplate>
          {loggedInUser == null ? (
            <div className="collapse navbar-collapse justify-content-end"></div>
          ) : (
            <div className="collapse navbar-collapse justify-content-end">
              <Dropdown>
                <Dropdown.Toggle variant="warning" id="dropdown-basic">
                  {loggedInUser}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                    Sign out
                  </Dropdown.Item>
                  {/* <Dropdown.Item as="button" onClick={handleConnectServer}>Connect To Server</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={handleConnectMFiles}>Connect To Vault</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {/*{authType === 'MFiles' && redirect === 'false' && token != null ? (*/}
          {/* {token != null && loggedInUser != null ? ( */}
             {loggedInUser != null ? (
            <div className="collapse navbar-collapse justify-content-end">
              <Dropdown>
                <Dropdown.Toggle variant="warning" id="dropdown-basic">
                  {loggedInUser}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                    Sign out
                  </Dropdown.Item>
                                  </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <div className="collapse navbar-collapse justify-content-end"></div>
          )}
          {/* {authType != 'MFiles' ? (
                    <div className="collapse navbar-collapse justify-content-end">
                        <Button variant="secondary" onClick={handleLoginRedirect} className="ml-auto">
                            Sign In 
                        </Button>
                    </div>):(
                        <div className="collapse navbar-collapse justify-content-end">
                        </div>)
                } */}
        </UnauthenticatedTemplate>
      </Navbar>
    </>
  );
};
