import { useEffect, useState } from "react";
// 640
const useIsMobile = (breakpoint = 600) => {
  const checkForDevice = () => window.innerWidth < breakpoint;
  const checkForTabletDevice = () =>
    window.innerWidth >= 768 && window.innerWidth < 1024;
  const checkForLaptopDevice = () =>
    window.innerWidth >= 1024 && window.innerWidth <= 1440;

  const [isMobile, setIsMobile] = useState(checkForDevice());
  const [isTablet, setIsTablet] = useState(checkForTabletDevice());
  const [isIsLaptop, setIsLaptop] = useState(checkForLaptopDevice());

  useEffect(() => {
    const handlePageResized = () => {
      setIsMobile(checkForDevice());
      setIsTablet(checkForTabletDevice());
      setIsLaptop(checkForLaptopDevice());
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handlePageResized);
      window.addEventListener("orientationchange", handlePageResized);
      window.addEventListener("load", handlePageResized);
      window.addEventListener("reload", handlePageResized);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handlePageResized);
        window.removeEventListener("orientationchange", handlePageResized);
        window.removeEventListener("load", handlePageResized);
        window.removeEventListener("reload", handlePageResized);
      }
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isIsLaptop,
  };
};

export default useIsMobile;
